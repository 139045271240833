import useProtectedMutation from '../useProtectedMutation';
import { ContextValue } from './types';
import raydiantMenu, {
  CreateLocationRequest,
  Location,
  UpdateLocationRequest,
} from '../../clients/raydiantMenu';
import { useQueryClient } from 'react-query';

export default function useLocationModalForm(): ContextValue {
  const queryClient = useQueryClient();

  // Delete location

  // const deleteLocationFn = async (id: string) => {
  //   return await raydiantMenu.deleteLocation(id);
  // };

  // const {
  //   mutateAsync: deleteLocationMutation,
  //   status: deleteLocationStatus,
  // } = useProtectedMutation(deleteLocationFn, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries('locations');
  //   },
  // });

  // const deleteLocation = async (id: string) => {
  //   if (!id) return;

  //   try {
  //     await deleteLocationMutation(id);
  //   } catch (err) {
  //     if (err instanceof ForbiddenError) {
  //       console.error(err);
  //     }
  //   }
  // };

  // Update location

  const updateLocationFn = async (params: UpdateLocationRequest) => {
    return await raydiantMenu.updateLocation(params);
  };

  const {
    mutateAsync: updateLocationMutation,
    status: updateLocationStatus,
  } = useProtectedMutation(updateLocationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('locations');
    },
  });

  const updateLocation = async (params: UpdateLocationRequest) => {
    try {
      await updateLocationMutation(params);
    } catch (err) {
      console.error(err);
    }
  };

  // Create location

  const createLocationFn = async (params: CreateLocationRequest) => {
    return await raydiantMenu.createLocation(params);
  };

  const {
    mutateAsync: createLocationMutation,
    status: createLocationStatus,
  } = useProtectedMutation(createLocationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('locations');
    },
  });

  const createLocation = async (params: CreateLocationRequest) => {
    let data: Location | null = null;
    try {
      data = await createLocationMutation(params);
    } catch (err) {
      console.error(err);
    }
    return data;
  };

  const shouldDisableSave = [
    updateLocationStatus,
    createLocationStatus,
  ].includes('loading');

  return {
    createLocation,
    createLocationStatus,
    updateLocation,
    updateLocationStatus,
    shouldDisableSave,
    // deleteLocation,
    // deleteLocationStatus,
  };
}
