import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import MUIVpnKeyIcon from '@material-ui/icons/VpnKey';
import { FC } from 'react';
import ApiCardBase from './ApiCardBase';
import Text from 'raydiant-elements/core/Text';

interface ApiKeyCardProps {
  name: string;
  token: string;
  onClick?: () => void;
}

const ApiKeyCard: FC<ApiKeyCardProps> = ({ name, token, onClick }) => {
  const classes = useStyles();

  return (
    <ApiCardBase
      resourceIcon={<MUIVpnKeyIcon className={classes.icon} />}
      resourceName="API Key"
      sourceName={name}
      variant="secondary"
      onClick={onClick}
    >
      <Text xsmall>API Key:</Text>
      <Text className={classes.tokenText}>{token}</Text>
    </ApiCardBase>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main,
      height: '100%',
      width: '100%',
    },
    tokenText: {
      color: theme.palette.text.headingSecondary,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

export default ApiKeyCard;
