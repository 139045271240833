import { FC } from 'react';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

interface SectionBodyProps {
  maxWidth?: number;
}

const SectionBody: FC<SectionBodyProps> = ({ children, maxWidth }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ maxWidth }}>
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 4),
      paddingBottom: theme.spacing(6),
      width: '100%',
      // margin: '0 auto',

      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },

      '& + &': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

export default SectionBody;
