import { FC, useEffect, useState } from 'react';
import Heading from 'raydiant-elements/core/Heading';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Column from 'raydiant-elements/layout/Column';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';
import Select from 'raydiant-elements/core/Select';
import Text from 'raydiant-elements/core/Text';
import { PreviewOrientation, PreviewResolution } from '@raydiant/api-client-js';

interface ApplicationPreviewSettingsProps {}

const AplicationPreviewSettings: FC<ApplicationPreviewSettingsProps> = () => {
  const { version, isEditable, updateVersion } = useApplicationFormContext();

  const getOrientationLabelName = (
    orientation: string | undefined,
  ): string | undefined => {
    return orientation === 'horizontal'
      ? 'Landscape'
      : orientation === 'vertical'
      ? 'Portrait'
      : undefined;
  };

  const [previewOrientation, setPreviewOrientation] = useState<
    string | undefined
  >(version?.previewOrientation);

  const [previewResolution, setPreviewResolution] = useState<
    string | undefined
  >(version?.previewResolution);

  const handleOrientationOnBlur = () => {
    updateVersion({
      previewOrientation: previewOrientation as PreviewOrientation,
    });
  };

  const handleResolutionOnBlur = () => {
    updateVersion({
      previewResolution: previewResolution as PreviewResolution,
    });
  };

  // Callbacks

  useEffect(() => {
    setPreviewOrientation(version?.previewOrientation ?? '');
    setPreviewResolution(version?.previewResolution ?? '');
  }, [version]);

  return (
    <Column>
      <Heading size={5} overline>
        Display Settings
      </Heading>

      <div>
        <InputLabel>Default Screen Orientation</InputLabel>
        {isEditable && (
          <>
            <div style={{ maxWidth: 120 }}>
              <Select
                value={previewOrientation}
                onChange={setPreviewOrientation}
                onBlur={handleOrientationOnBlur}
              >
                <option value="horizontal">Landscape</option>
                <option value="vertical">Portrait</option>
              </Select>
            </div>
          </>
        )}
        {!isEditable && (
          <Text>{getOrientationLabelName(version?.previewOrientation)}</Text>
        )}
      </div>

      <div>
        <InputLabel>Default Display Resolution</InputLabel>
        {isEditable && (
          <>
            <div style={{ maxWidth: 120 }}>
              <Select
                value={previewResolution}
                onChange={setPreviewResolution}
                onBlur={handleResolutionOnBlur}
              >
                <option value="HD">720p</option>
                <option value="FullHD">1080p</option>
                <option value="UltraHD">4K</option>
                <option value="EightK">8K</option>
              </Select>
            </div>
          </>
        )}
        {!isEditable && <Text>{version?.previewResolution}</Text>}
      </div>
    </Column>
  );
};

export default AplicationPreviewSettings;
