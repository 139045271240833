import { CreateApplicationVersion } from '@raydiant/api-client-js';
import { State } from './types';
import defaultVersion from './defaultVersion';

const createInitialState = (isNewApp: boolean): State => {
  let newVersion: CreateApplicationVersion | null = null;

  if (isNewApp) {
    newVersion = {
      name: defaultVersion.name,
      majorVersion: defaultVersion.majorVersion,
      minorVersion: defaultVersion.minorVersion,
      patchVersion: defaultVersion.patchVersion,
      description: defaultVersion.description,
      embeddedUrlFormat: defaultVersion.embeddedUrlFormat ?? '',
      configurableDuration: defaultVersion.configurableDuration,
      defaultDuration: defaultVersion.defaultDuration,
      previewResolution: defaultVersion.previewResolution,
      previewOrientation: defaultVersion.previewOrientation,
      presentationProperties: defaultVersion.presentationProperties,
      strings: defaultVersion.strings,
      hasDynamicThumbnails: defaultVersion.dynamicThumbnails,
      helperLinkText: defaultVersion.helperLinkText,
      helperLinkUrl: defaultVersion.helperLinkUrl,
    };
  }

  const initialState: State = {
    isEditable: isNewApp,
    isSelectingVersion: false,
    updatedApplication: null,
    newApplication: null,
    newVersion,
    newVersionThumbnailUpload: null,
    newVersionIconUpload: null,
    selectedPropertyIndex: null,
    serverErrors: {},
  };

  return initialState;
};

export default createInitialState;
