import { useState, useEffect } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Input from 'raydiant-elements/core/Input';
import Text from 'raydiant-elements/core/Text';
import {
  getPropertyIconUrl,
  setPropertyIconUrl,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

const ApplicationInputIconUrl = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  // State

  const [iconUrl, setIconUrl] = useState(
    selectedProperty ? getPropertyIconUrl(selectedProperty) : '',
  );

  // Callbacks

  const handleBlur = () => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyIconUrl(selectedProperty, iconUrl),
      version.strings,
    );
  };

  // Effects

  // Reset icon url when property changes.
  useEffect(() => {
    setIconUrl(selectedProperty ? getPropertyIconUrl(selectedProperty) : '');
  }, [selectedProperty]);

  // Render

  return (
    <div>
      <InputLabel>Icon URL</InputLabel>

      {isEditable && (
        <Input value={iconUrl} onChange={setIconUrl} onBlur={handleBlur} />
      )}

      {!isEditable && selectedProperty && (
        <Text>{getPropertyIconUrl(selectedProperty) || <em>None</em>}</Text>
      )}
    </div>
  );
};

export default ApplicationInputIconUrl;
