import { FC } from 'react';
import Heading from 'raydiant-elements/core/Heading';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Column from 'raydiant-elements/layout/Column';
import Row from 'raydiant-elements/layout/Row';
import AssetInput from '../../components/AssetInput';
import Asset from '../../components/Asset';
import {
  useApplicationFormContext,
  maxFileSize,
} from '../../hooks/useApplicationForm';

interface ApplicationAssetsProps {}

const ApplicationAssets: FC<ApplicationAssetsProps> = () => {
  const {
    isEditable,
    thumbnailUrl,
    iconUrl,
    setThumbnailUpload,
    setIconUpload,
    getThumbnailError,
    getIconError,
  } = useApplicationFormContext();

  const thumbnailError = getThumbnailError();
  const iconError = getIconError();

  let errorMessage: string | null = null;

  if (thumbnailError === 'invalid' || iconError === 'invalid') {
    errorMessage = 'Oops! Please enter a valid thumbnail and icon';
  } else if (
    thumbnailError === 'fileTooLarge' ||
    iconError === 'fileTooLarge'
  ) {
    errorMessage = `Oops! Thumbnail and icon  must be ${
      maxFileSize / 1000
    }kb or under`;
  }

  return (
    <Column>
      <Heading size={5} overline>
        Assets
      </Heading>

      <div>
        <Row>
          <div>
            <InputLabel error={!!thumbnailError}>Thumbnail</InputLabel>
            {isEditable ? (
              <AssetInput
                width={192}
                height={108}
                url={thumbnailUrl}
                error={!!thumbnailError}
                onChange={setThumbnailUpload}
              />
            ) : (
              <Asset width={192} height={108} url={thumbnailUrl} />
            )}
          </div>

          <div>
            <InputLabel error={!!iconError}>Icon</InputLabel>
            {isEditable ? (
              <AssetInput
                width={48}
                height={48}
                url={iconUrl}
                error={!!iconError}
                onChange={setIconUpload}
              />
            ) : (
              <Asset width={48} height={48} url={iconUrl} />
            )}
          </div>
        </Row>

        {errorMessage && (
          <InputHelperText error>{errorMessage}</InputHelperText>
        )}
      </div>
    </Column>
  );
};

export default ApplicationAssets;
