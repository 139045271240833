import { FC } from 'react';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import Paper from 'raydiant-elements/core/Paper';
import { Theme } from 'raydiant-elements/theme';

interface SectionProps {}

const Section: FC<SectionProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} color="light">
      {children}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      paddingTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,

      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        borderRadius: 0,
      },
    },
  }),
);

export default Section;
