const raydiantDashUrl =
  process.env.REACT_APP_DASH_URL || 'https://dash.staging.raydiant.com';

const config = {
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  auth0DbConnection: process.env.REACT_APP_AUTH0_DB_CONNECTION || '',
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  raydiantApiUrl:
    process.env.REACT_APP_API_URL || 'https://api.staging.raydiant.com',
  raydiantDashUrl,
  raydiantMenuApiUrl:
    process.env.REACT_APP_MENU_API_URL ||
    'https://menu-api.staging.raydiant.com',
  raydiantDashAccountsPageUrl: `${raydiantDashUrl}/account`,
};

export default config;
