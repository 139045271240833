import * as querystring from 'querystring';
import createPath from './createPath';

export const home = createPath('/', () => '/');

// Login

export const login = createPath(
  '/login',
  (queryParams: { backTo?: string } = {}) =>
    `/login?${querystring.stringify({ backTo: queryParams.backTo })}`,
  { unprotected: true },
);

export const loginCallback = createPath(
  '/login-callback',
  () => `/login-callback`,
  { unprotected: true },
);

// Not Allowed

export const notAllowed = createPath('/not-allowed', () => `/not-allowed`, {
  unprotected: true,
});

// Applications

export const applications = createPath('/apps', () => '/apps');

export const editApplication = createPath(
  '/apps/:applicationName',
  (applicationName: string) => `/apps/${applicationName}`,
);

export const newApplication = createPath('/apps/new', () => '/apps/new');

// API keys
export const apiKeys = createPath('/api-keys', () => '/api-keys');
