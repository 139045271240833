import { makeStyles, createStyles } from 'raydiant-elements/styles';
import Row from 'raydiant-elements/layout/Row';
import { Theme } from 'raydiant-elements/theme';
import MUISettingsIcon from '@material-ui/icons/Settings';
import { FC } from 'react';
import Paper from 'raydiant-elements/core/Paper';
import { buttonReset } from 'raydiant-elements/mixins';
import cn from 'classnames';
import Text from 'raydiant-elements/core/Text';

interface ApiCardBaseProps {
  sourceName?: string;
  totalMenus?: number;
  disabled?: boolean;
  resourceIcon: React.ReactNode;
  resourceName: string;
  variant?: 'primary' | 'secondary';
  onClick?: () => void;
}

const ApiCardBase: FC<ApiCardBaseProps> = ({
  sourceName,
  disabled,
  children,
  resourceName,
  resourceIcon,
  variant = 'primary',
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Paper
        className={cn(
          classes.resource,
          disabled && classes.disabled,
          variant === 'primary' && classes.resourcePrimary,
          variant === 'secondary' && classes.resourceSecondary,
        )}
      >
        <div className={cn(classes.resourceInner)}>
          <div className={classes.resourceIcon}>{resourceIcon}</div>
          <div className={classes.resourceText}>{resourceName}</div>
        </div>
      </Paper>
      <Row className={classes.body} center>
        <div className={classes.name}>
          <button className={classes.nameButton} onClick={onClick}>
            <MUISettingsIcon className={classes.settingsIcon} />
            <Text bold small className={classes.nameText}>
              {sourceName}
            </Text>
          </button>
        </div>
        <div className={classes.details}>{children}</div>
      </Row>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const disabledColor = 'rgba(0, 0, 0, 0.2)';

  return createStyles({
    root: {
      width: '100%',
      display: 'flex',
      borderRadius: theme.borderRadius.sm,
      paddingBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(0.25),
      background: theme.palette.background.paper,
    },
    resource: {
      width: 54,
      height: 54,
      flexShrink: 0,
      padding: theme.spacing(0.5),
      marginRight: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(3),
      },
    },
    resourcePrimary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    resourceSecondary: {
      backgroundColor: theme.palette.background.paper,
    },
    resourceInner: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
      alignItems: 'center',
    },
    resourceIcon: {
      height: 24,
      width: 24,
      marginBottom: theme.spacing(0.25),
    },
    body: {
      display: 'flex',
      minWidth: 0,
    },
    name: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      borderRight: `1px solid ${theme.divider.secondary}`,
      height: '100%',
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        flex: 2,
      },
    },
    nameButton: {
      display: 'flex',
      alignItems: 'center',
      ...buttonReset(),
    },
    nameText: {
      textDecoration: 'underline',
      textDecorationColor: theme.divider.secondary,
      color: theme.palette.text.headingSecondary,
    },
    details: {
      overflow: 'hidden',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      opacity: 0.6,
      [theme.breakpoints.up('sm')]: {
        flex: 3,
      },
    },
    resourceText: {
      opacity: 0.6,
      fontSize: theme.fontSizes.xxs,
    },
    settingsIcon: {
      marginRight: theme.spacing(0.5),
    },
    disabled: {
      background: disabledColor,
      color: disabledColor,
    },
  });
});

export default ApiCardBase;
