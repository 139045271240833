import { FC, useCallback, ChangeEvent } from 'react';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import cn from 'classnames';
import Asset from './Asset';

interface AssetInputProps {
  width: number;
  height: number;
  url: string | null;
  error?: boolean;
  onChange: (file: File | null) => void;
}

const AssetInput: FC<AssetInputProps> = ({
  width,
  height,
  url,
  error,
  onChange,
}) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files && files[0]) {
        onChange(files[0]);
      }
    },
    [onChange],
  );

  return (
    <div
      className={cn(classes.root, error && classes.error)}
      style={{ width, height }}
    >
      <Asset width={width} height={height} url={url} />
      <input
        className={classes.input}
        type="file"
        accept="image/*"
        onChange={handleChange}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },

    error: {
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.error.main}`,
    },

    input: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      cursor: 'pointer',
    },
  }),
);

export default AssetInput;
