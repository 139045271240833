import { canEditResource } from '../utilities/permissions';
import useApplications from './useApplications';
import useProfile from './useProfile';

export default function useEditableApplications() {
  const { data: profile } = useProfile();
  const query = useApplications();

  return {
    ...query,
    data: query.data?.filter(
      (app) => profile && canEditResource(profile, app.resource),
    ),
  };
}
