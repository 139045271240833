import { ApiClientError } from '@raydiant/api-client-js';
import raydiantMenu, { GetApiKeyMenusResponse } from '../clients/raydiantMenu';
import useProtectedQuery from './useProtectedQuery';

export default function useApiKeyMenus(id?: string) {
  const query = useProtectedQuery<GetApiKeyMenusResponse, ApiClientError>(
    ['apiKeyMenus', id],
    async () => {
      const data = await raydiantMenu.getApiKeyMenus(
        // ID can be undefined, but react-query only executes
        // this call when the id is defined.
        // See here for more info: https://react-query.tanstack.com/guides/disabling-queries

        // Casting as string to prevent undefined id TS errors
        { id: id ?? '' },
      );

      data.sort((a, b) => {
        if (a.name === null) {
          return 1;
        }

        if (b.name === null) {
          return -1;
        }

        return a.name.localeCompare(b.name);
      });

      return data;
    },
    { enabled: !!id },
  );

  return query;
}
