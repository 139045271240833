import { ApiClientError } from '@raydiant/api-client-js';
import useProtectedMutation from './useProtectedMutation';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';

export default function useDeleteResourceACL() {
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<
    void,
    ApiClientError,
    {
      aclId: string;
    }
  >(async ({ aclId }) => await raydiant.deleteResourceACL(aclId), {
    onSuccess: () => {
      queryClient.invalidateQueries('resourceACLs');
    },
  });

  return mutation;
}
