import { FC, useState, useRef } from 'react';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import { PresentationProperty } from '@raydiant/api-client-js';
import {
  newTextProperty,
  newNumberProperty,
  newToggleProperty,
  newSelectProperty,
  newButtonGroupProperty,
  newOAuthProperty,
  newMultiSelectProperty,
  newFileUploadProperty,
  newProductCatalogProperty,
} from '../../utilities/properties';
import AddIcon from '../../components/AddIcon';
import useRoles from '../../hooks/useRoles';

interface ApplicationInputAddProps {
  onAdd: (property: PresentationProperty) => void;
}

const ApplicationInputAdd: FC<ApplicationInputAddProps> = ({ onAdd }) => {
  // State

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  // Queries

  const { data: roles } = useRoles();

  // Refs

  const buttonRef = useRef<HTMLButtonElement>(null);

  // Render

  const hasOAuthRole = roles?.includes('developer_internal');
  const hasFileUploadRole = roles?.includes('developer_internal');
  const hasProductCatalogRole = roles?.includes('developer_internal');

  let inputTypes = [
    {
      label: 'Text',
      description: 'Headings, paragraphs, and call to actions',
      onClick: () => onAdd(newTextProperty()),
    },
    {
      label: 'Number',
      description: 'Counts, quantities and durations',
      onClick: () => onAdd(newNumberProperty()),
    },
    {
      label: 'Toggle',
      description: 'On/off, yes/no',
      onClick: () => onAdd(newToggleProperty()),
    },
    {
      label: 'Select',
      description: 'Single value from a large list of options',
      onClick: () => onAdd(newSelectProperty()),
    },
    {
      label: 'Multi-select',
      description: 'Multiple values from a list of options',
      onClick: () => onAdd(newMultiSelectProperty()),
    },
    {
      label: 'Button Group',
      description: 'Small lists of 2-3 options',
      onClick: () => onAdd(newButtonGroupProperty()),
    },
  ];

  if (hasOAuthRole) {
    inputTypes.push({
      label: 'OAuth Button',
      description: 'Authenticate to protected APIs',
      onClick: () => onAdd(newOAuthProperty()),
    });
  }

  if (hasFileUploadRole) {
    inputTypes.push({
      label: 'File Upload',
      description: 'Upload an image, video, spreadsheet or PDF',
      onClick: () => onAdd(newFileUploadProperty()),
    });
  }

  if (hasProductCatalogRole) {
    inputTypes.push({
      label: 'Product Catalog',
      description: 'Products and categories for menus and kiosks',
      onClick: () => onAdd(newProductCatalogProperty()),
    });
  }

  return (
    <>
      <ActionBar.Action
        ref={buttonRef}
        icon={<AddIcon isOpen={isOpen} />}
        onClick={() => setIsOpen(!isOpen)}
      />
      <Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          root: classes.popover,
          paper: classes.popoverPaper,
        }}
      >
        {inputTypes
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((inputType, i) => (
            <ListItem
              key={i}
              button
              dense
              className={classes.item}
              onClick={() => {
                inputType.onClick();
                setIsOpen(false);
              }}
            >
              <div className={classes.label}>{inputType.label}</div>
              <div className={classes.description}>{inputType.description}</div>
            </ListItem>
          ))}
      </Popover>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.progress.contrastText,
      backgroundColor: theme.palette.progress.main,
      borderRadius: 100,
      boxShadow: theme.shadows[1],
      transition: 'transform 0.15s ease-in',
    },

    iconOpen: {
      transform: 'rotate(45deg)',
    },

    popover: {
      backgroundColor: theme.modal.overlayBackground,

      '& $popoverPaper': {
        marginTop: theme.spacing(0.5),
        padding: theme.spacing(1, 0),
        borderRadius: theme.borderRadius.md,
      },
    },

    popoverPaper: {},

    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: theme.spacing(0.75, 2),
    },

    label: {
      fontSize: theme.fontSizes.md,
      marginBottom: theme.spacing(0.25),
      fontWeight: 500,
    },

    description: {
      fontSize: theme.fontSizes.sm,
      color: theme.palette.text.secondary,
    },
  }),
);

export default ApplicationInputAdd;
