import { UnauthorizedError } from '@raydiant/api-client-js';
import { Location } from 'history';
import { matchPath } from 'react-router';
import * as routes from '../routes';
import auth0 from '../clients/auth0';

export default function globalErrorHandler<E>(
  error: E | null,
  { pathname, search, hash }: Location,
) {
  if (error && error instanceof UnauthorizedError) {
    // Check if current path is an unprotected path, meaning auth errors should not
    // trigger a redirect.
    const isPathUnprotectedPage = Object.values(routes).some(
      (path) =>
        path.meta.unprotected &&
        !!matchPath(pathname, { path: path.pattern, exact: true }),
    );

    // The current path is marked as a protected and should redirect to the login page
    // when an unauthorized error occurs.
    if (!isPathUnprotectedPage) {
      const backTo = [pathname, search, hash].join('');
      auth0.logout(backTo);
    }
  }
}
