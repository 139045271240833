import { FC, useState, useEffect } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Input from 'raydiant-elements/core/Input';
import Text from 'raydiant-elements/core/Text';
import {
  getPropertyHelperLink,
  setPropertyHelperLink,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationInputHelperLinkProps {}

const ApplicationInputHelperLink: FC<ApplicationInputHelperLinkProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    selectedPropertyIndex,
    updateSelectedProperty,
    getInputHelperLinkError,
  } = useApplicationFormContext();

  // State

  const [helperLink, setHelperLink] = useState(
    selectedProperty ? getPropertyHelperLink(selectedProperty) || '' : '',
  );

  // Callbacks

  const handleBlur = () => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyHelperLink(selectedProperty, helperLink),
      version.strings,
    );
  };

  // Effects

  // Set helperLink when selectedProperty changes.
  useEffect(() => {
    setHelperLink(
      selectedProperty ? getPropertyHelperLink(selectedProperty) || '' : '',
    );
  }, [selectedProperty]);

  // Render

  const helperLinkError =
    selectedPropertyIndex !== null
      ? getInputHelperLinkError(selectedPropertyIndex)
      : null;

  return (
    <div>
      <InputLabel error={!!helperLinkError}>Helper Link</InputLabel>

      {isEditable && (
        <>
          <Input
            type="text"
            value={helperLink}
            onChange={setHelperLink}
            onBlur={handleBlur}
            error={!!helperLinkError}
          />
          <InputHelperText error={!!helperLinkError}>
            {helperLinkError === 'invalid' &&
              'Oops! Please enter a valid helper link.'}
            {!helperLinkError &&
              'The URL of the helper text to open additional documentation.'}
          </InputHelperText>
        </>
      )}

      {!isEditable && <Text>{helperLink || <em>None</em>}</Text>}
    </div>
  );
};

export default ApplicationInputHelperLink;
