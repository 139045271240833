import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import MUIExitToAppIcon from '@material-ui/icons/ExitToApp';
import MUIStorefrontIcon from '@material-ui/icons/Storefront';
import MUIPlaceIcon from '@material-ui/icons/Place';
import MUIRestaurantIcon from '@material-ui/icons/Restaurant';
import { FC } from 'react';
import AlertIcon from 'raydiant-elements/core/AlertIcon';
import Link from 'raydiant-elements/core/Link';
import ApiCardBase from './ApiCardBase';
import Text from 'raydiant-elements/core/Text';

const learnMoreLink = '/docs/on-brand-menu-api/toast#create-a-toast-source';

interface LocationCardProps {
  sourceName?: string;
  restaurantName?: string;
  name?: string;
  totalMenus?: number;
  disabled?: boolean;
  onClick: () => void;
}

const LocationCard: FC<LocationCardProps> = ({
  sourceName,
  restaurantName,
  name,
  totalMenus,
  disabled,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <ApiCardBase
      resourceIcon={<MUIExitToAppIcon className={classes.icon} />}
      resourceName="API Source"
      sourceName={sourceName}
      disabled={disabled}
      onClick={onClick}
    >
      {disabled ? (
        <div className={classes.unlinked}>
          <AlertIcon color="warning" className={classes.warningIcon} />
          <Text xsmall>
            This Source is not yet linked to a Location in Toast.{' '}
            <Link href={learnMoreLink} target="_blank">
              Learn more
            </Link>
          </Text>
        </div>
      ) : (
        <>
          <div className={classes.detail}>
            <MUIStorefrontIcon className={classes.detailIcon} />
            <Text xsmall>{restaurantName}</Text>
          </div>
          <div className={classes.detail}>
            <MUIPlaceIcon className={classes.detailIcon} />
            <Text xsmall>{name}</Text>
          </div>
          <div className={classes.detail}>
            <MUIRestaurantIcon className={classes.detailIcon} />
            <Text xsmall>{totalMenus}</Text>
          </div>
        </>
      )}
    </ApiCardBase>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detail: {
      display: 'flex',
      alignItems: 'center',
    },
    detailIcon: {
      width: 16,
      height: 16,
      marginRight: theme.spacing(0.75),
    },
    resourceText: {
      opacity: 0.6,
      fontSize: theme.fontSizes.xxs,
    },
    icon: {
      height: '100%',
      width: '100%',
    },
    warningIcon: {
      width: 16,
      height: 16,
      marginRight: theme.spacing(0.5),
    },
    unlinked: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export default LocationCard;
