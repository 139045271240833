import { FC, useState, useEffect } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Heading from 'raydiant-elements/core/Heading';
import Input from 'raydiant-elements/core/Input';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Text from 'raydiant-elements/core/Text';
import Column from 'raydiant-elements/layout/Column';
import Row from 'raydiant-elements/layout/Row';
import Spacer from 'raydiant-elements/layout/Spacer';
import { PresentationProperty } from '@raydiant/api-client-js';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import DraggableList from '../../components/DraggableList';
import reorder from '../../utilities/reorder';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';
import ApplicationInputAdd from './ApplicationInputAdd';
import ApplicationInputRow from './ApplicationInputRow';
import ApplicationInputModal from './ApplicationInputModal';
import ApplicationInputRowActions from './ApplicationInputRowActions';

export interface ApplicationURLProps {}

const ApplicationURL: FC<ApplicationURLProps> = () => {
  const classes = useStyles();

  const {
    isEditable,
    version,
    updateVersion,
    selectedProperty,
    setSelectedProperty,
    getUrlTemplateError,
  } = useApplicationFormContext();

  // State

  const [embeddedUrlFormat, setEmbeddedUrlFormat] = useState(
    version?.embeddedUrlFormat ?? '',
  );

  const [
    actionsPopoverEl,
    setActionsPopoverEl,
  ] = useState<HTMLButtonElement | null>(null);

  const [actionsPropertyIndex, setActionsPropertyIndex] = useState<
    number | null
  >(null);

  // Callbacks

  const handleAddInput = (property: PresentationProperty) => {
    if (!version) return;

    updateVersion({
      presentationProperties: [...version.presentationProperties, property],
    });

    setSelectedProperty(version.presentationProperties.length);
  };

  const handleDeleteInput = () => {
    if (!version) return;

    updateVersion({
      presentationProperties: version.presentationProperties.filter(
        (_, index) => index !== actionsPropertyIndex,
      ),
    });

    setActionsPopoverEl(null);
    setActionsPropertyIndex(null);
  };

  const handlActionsOpen = (el: HTMLButtonElement, index: number) => {
    setActionsPopoverEl(el);
    setActionsPropertyIndex(index);
  };

  const handlActionsClose = () => {
    setActionsPopoverEl(null);
    setActionsPropertyIndex(null);
  };

  const handleReorderInputs = (
    sourceIndex: number,
    destinationIndex: number,
  ) => {
    if (!version) return;

    updateVersion({
      presentationProperties: [
        ...reorder(
          version.presentationProperties,
          sourceIndex,
          destinationIndex,
        ),
      ],
    });
  };

  // Reset state when application name changes.
  useEffect(() => {
    setEmbeddedUrlFormat(version?.embeddedUrlFormat ?? '');
  }, [version]);

  // Render

  const urlTemplateError = getUrlTemplateError();

  return (
    <Column doubleMargin>
      <Column>
        <Heading size={5} overline>
          App URL
        </Heading>

        <div>
          <InputLabel error={!!urlTemplateError}>URL Template</InputLabel>
          {isEditable && (
            <>
              <Input
                type="text"
                value={embeddedUrlFormat}
                error={!!urlTemplateError}
                onChange={setEmbeddedUrlFormat}
                onBlur={() => updateVersion({ embeddedUrlFormat })}
              />
              <InputHelperText error={!!urlTemplateError}>
                {urlTemplateError === 'invalid' &&
                  'Oops! Please provide a URL template.'}
              </InputHelperText>
              <InputHelperText>
                i.e. https://en.wikipedia.org/wiki/:
                <Text bold>input_id</Text>
              </InputHelperText>
            </>
          )}

          {!isEditable && (
            <pre className={classes.embeddedUrlFormat}>
              <code>{embeddedUrlFormat}</code>
            </pre>
          )}
        </div>
      </Column>

      <div>
        <Row center className={classes.inputsLabel}>
          <InputLabel>Inputs</InputLabel>
          <Spacer />
          {isEditable && <ApplicationInputAdd onAdd={handleAddInput} />}
        </Row>

        <DraggableList
          droppableId="inputs"
          items={version?.presentationProperties || []}
          editable={isEditable}
          onChange={handleReorderInputs}
          onDragStart={() => setSelectedProperty(null)}
        >
          {(dragHandleProps, property, index) => (
            <ApplicationInputRow
              key={index}
              property={property}
              propertyIndex={index}
              dragHandleProps={dragHandleProps}
              selected={selectedProperty === property}
              onMore={(el) => handlActionsOpen(el, index)}
            />
          )}
        </DraggableList>
      </div>

      <ApplicationInputRowActions
        anchorEl={actionsPopoverEl}
        onClose={handlActionsClose}
        onDelete={handleDeleteInput}
      />

      <ApplicationInputModal />
    </Column>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputsLabel: {
      marginBottom: theme.spacing(0.5),
    },
    embeddedUrlFormat: {
      borderRadius: '0.5rem',
      borderWidth: 1,
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      overflowX: 'auto',
    },
  }),
);

export default ApplicationURL;
