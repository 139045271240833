import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import REApp from 'raydiant-elements/core/App';
import ApplicationsPage from './pages/ApplicationsPage';
import * as routes from './routes';
import LoginPage from './pages/LoginPage';
import LoginCallbackPage from './pages/LoginCallbackPage';
import EditApplicationPage from './pages/EditApplicationPage';
import NewApplicationPage from './pages/NewApplicationPage';
import NotAllowedPage from './pages/NotAllowedPage';
import './global.css';
import ApiKeysPage from './pages/ApiKeysPage';

export interface AppProps {}

const App: FC<AppProps> = () => {
  return (
    <REApp color="grey">
      <Switch>
        <Route exact path={routes.login.pattern} component={LoginPage} />
        <Route
          exact
          path={routes.loginCallback.pattern}
          component={LoginCallbackPage}
        />
        <Redirect
          exact
          from={routes.home.pattern}
          to={routes.applications.pattern}
        />
        <Route
          exact
          path={routes.notAllowed.pattern}
          component={NotAllowedPage}
        />
        {/* Pages that require a developer role below this */}
        <Route
          exact
          path={routes.newApplication.pattern}
          component={NewApplicationPage}
        />
        <Route
          exact
          path={routes.editApplication.pattern}
          component={EditApplicationPage}
        />
        <Route
          exact
          path={routes.applications.pattern}
          component={ApplicationsPage}
        />
        <Route exact path={routes.apiKeys.pattern} component={ApiKeysPage} />
      </Switch>
    </REApp>
  );
};

export default App;
