import { FC, useCallback } from 'react';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import { useHistory, Redirect } from 'react-router-dom';
import { Application } from '@raydiant/api-client-js';
import * as routes from '../routes';
import Page from '../components/Page';
import ApplicationForm from '../components/ApplicationForm';
import useApplicationForm, {
  ApplicationFormProvider,
} from '../hooks/useApplicationForm';
import useRoles from '../hooks/useRoles';

interface NewApplicationPageProps {}

const NewApplicationPage: FC<NewApplicationPageProps> = () => {
  const history = useHistory();

  const { isLoading: isLoadingRoles, data: roles } = useRoles();

  // Form

  const form = useApplicationForm(true);

  // Callbacks

  const redirectToEditPage = useCallback(
    (_, application: Application | null) => {
      if (application) {
        history.push(routes.editApplication(application.name));
      }
    },
    [history],
  );

  const hasDeveloperRole = roles?.includes('developer');
  if (!isLoadingRoles && !hasDeveloperRole) {
    return <Redirect to={routes.notAllowed()} />;
  }

  return (
    <Page>
      <Scrollable>
        <ApplicationFormProvider value={form}>
          <ApplicationForm onSave={redirectToEditPage} />
        </ApplicationFormProvider>
      </Scrollable>
    </Page>
  );
};

export default NewApplicationPage;
