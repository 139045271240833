import useLocations from './useLocations';

export default function useLocation(id: string | null) {
  const query = useLocations();
  if (!id) return null;

  return {
    ...query,
    // replace data field with single location
    data: query.data?.find((x) => x.id === id),
  };
}
