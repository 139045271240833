import {
  useMutation,
  MutateFunction,
  UseMutationOptions,
  MutateOptions,
} from 'react-query';
import { useHistory } from 'react-router';
import globalErrorHandler from '../utilities/globalErrorHandler';

// A wrapper around useMutation to catch Unauthorized errors from the api client and
// redirect to the login page if the page is protected. Unprotected pages are allowed
// to error without redirecting.
export default function useProtectedMutation<
  TData,
  TError,
  TVariables = void,
  TContext = unknown
>(
  fn: MutateFunction<TData, TError, TVariables, TContext>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>,
) {
  const history = useHistory();

  const mutation = useMutation<TData, TError, TVariables, TContext>(
    fn,
    options,
  );

  globalErrorHandler(mutation.error, history.location);

  return {
    ...mutation,
    // Wrap mutateAsync error with globalErrorHandler
    mutateAsync: async (
      variables: TVariables,
      options?: MutateOptions<TData, TError, TVariables, TContext>,
    ) => {
      try {
        return mutation.mutateAsync(variables, options);
      } catch (err) {
        globalErrorHandler(err, history.location);
        throw err;
      }
    },
  } as typeof mutation;
}
