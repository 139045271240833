import { FC, useState, useEffect } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Input from 'raydiant-elements/core/Input';
import Text from 'raydiant-elements/core/Text';
import {
  getPropertyVerifyQsParam,
  setPropertyVerifyQsParam,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationVerifyQsParamProps {}

const ApplicationVerifyQsParam: FC<ApplicationVerifyQsParamProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  // State

  const [verifyQsParam, setVerifyQsParam] = useState('');

  // Callbacks

  const handleBlur = () => {
    if (!version) return;
    if (!selectedProperty) return;

    updateSelectedProperty(
      setPropertyVerifyQsParam(selectedProperty, verifyQsParam),
      version.strings,
    );
  };

  // Effects

  // Set "verify qs param" when selectedProperty changes.
  useEffect(() => {
    setVerifyQsParam(
      selectedProperty ? getPropertyVerifyQsParam(selectedProperty) || '' : '',
    );
  }, [selectedProperty]);

  // Render

  return (
    <div>
      {isEditable && (
        <>
          <InputLabel>Verify Query String Parameter</InputLabel>
          <Input
            type="text"
            value={verifyQsParam}
            onChange={setVerifyQsParam}
            onBlur={handleBlur}
          />
        </>
      )}

      {!isEditable && selectedProperty && (
        <>
          <InputLabel>Verify Query String Parameter</InputLabel>
          <Text>{getPropertyVerifyQsParam(selectedProperty)}</Text>
        </>
      )}
    </div>
  );
};

export default ApplicationVerifyQsParam;
