import {
  Application,
  ApiClientError,
  UpdateApplication,
} from '@raydiant/api-client-js';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import useProtectedMutation from './useProtectedMutation';

export default function useUpdateApplication() {
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<
    Application,
    ApiClientError,
    {
      appId: string;
      params: UpdateApplication;
    }
  >(({ appId, params }) => raydiant.updateApplication(appId, params), {
    onSuccess: (application) => {
      const allApplications =
        queryClient.getQueryData<Application[]>('applications') || [];

      // Update the query cache with the new application to improve avoid a flash of stale content
      // when updating an app's deployment id.
      queryClient.setQueryData(
        'applications',
        allApplications.map((app) =>
          app.id === application.id ? application : app,
        ),
      );
    },
  });

  return mutation;
}
