import Popover from '../../components/Popover';
import PopoverListItem from '../../components/PopoverListItem';

export interface ApplicationInputRowActionsProps {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  onDelete: () => void;
}

const ApplicationInputRowActions = ({
  anchorEl,
  onClose,
  onDelete,
}: ApplicationInputRowActionsProps) => {
  return (
    <>
      <Popover
        variant="list"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <PopoverListItem onClick={onDelete}>Delete</PopoverListItem>
      </Popover>
    </>
  );
};

export default ApplicationInputRowActions;
