import { FC, useState, useEffect } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Input from 'raydiant-elements/core/Input';
import Text from 'raydiant-elements/core/Text';
import {
  getPropertyVerifyUrl,
  setPropertyVerifyUrl,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationInputVerifyUrlProps {}

const ApplicationInputVerifyUrl: FC<ApplicationInputVerifyUrlProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  // State

  const [verifyUrl, setVerifyUrl] = useState('');

  // Callbacks

  const handleBlur = () => {
    if (!version) return;
    if (!selectedProperty) return;

    updateSelectedProperty(
      setPropertyVerifyUrl(selectedProperty, verifyUrl),
      version.strings,
    );
  };

  // Effects

  // Set verify URL when selectedProperty changes.
  useEffect(() => {
    setVerifyUrl(
      selectedProperty ? getPropertyVerifyUrl(selectedProperty) || '' : '',
    );
  }, [selectedProperty]);

  // Render

  return (
    <div>
      {isEditable && (
        <>
          <InputLabel>Verify URL</InputLabel>
          <Input
            type="text"
            value={verifyUrl}
            onChange={setVerifyUrl}
            onBlur={handleBlur}
          />
        </>
      )}

      {!isEditable && selectedProperty && (
        <>
          <InputLabel>Verify URL</InputLabel>
          <Text>{getPropertyVerifyUrl(selectedProperty)}</Text>
        </>
      )}
    </div>
  );
};

export default ApplicationInputVerifyUrl;
