import { FC } from 'react';
import Heading from 'raydiant-elements/core/Heading';
import Column from 'raydiant-elements/layout/Column';
import ApplicationInputID from './ApplicationInputID';
import ApplicationInputHelperText from './ApplicationInputHelperText';
import ApplicationInputHelperLink from './ApplicationInputHelperLink';
import ApplicationInputDefaultToggle from './ApplicationInputDefaultToggle';

interface ApplicationInputTypeToggleProps {}

const ApplicationInputTypeToggle: FC<ApplicationInputTypeToggleProps> = () => {
  return (
    <Column doubleMargin>
      <Column>
        <Heading overline size={5}>
          Properties
        </Heading>
        <ApplicationInputID />
        <ApplicationInputDefaultToggle />
      </Column>

      <Column>
        <Heading overline size={5}>
          Appearance
        </Heading>
        <ApplicationInputHelperText />
        <ApplicationInputHelperLink />
      </Column>
    </Column>
  );
};

export default ApplicationInputTypeToggle;
