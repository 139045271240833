import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export interface PopoverListItemProps {
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
}

const PopoverListItem = ({
  disabled,
  onClick,
  children,
  className,
}: PopoverListItemProps) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      dense
      classes={{ root: classes.root }}
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {children}
    </ListItem>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: theme.fontSizes.md,
      padding: theme.spacing(0.5, 1),
    },
  }),
);

export default PopoverListItem;
