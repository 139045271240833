import { FC, useRef } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { PresentationProperty } from '@raydiant/api-client-js';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import AlertIcon from 'raydiant-elements/core/AlertIcon';
import Row from 'raydiant-elements/layout/Row';
import Spacer from 'raydiant-elements/layout/Spacer';
import cn from 'classnames';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';
import { getPropertyName, getPropertyType } from '../../utilities/properties';

interface ApplicationInputRowProps {
  property: PresentationProperty;
  propertyIndex: number;
  selected?: boolean;
  onMore?: (el: HTMLButtonElement) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}

const ApplicationInputRow: FC<ApplicationInputRowProps> = ({
  property,
  propertyIndex,
  selected = false,
  onMore,
  dragHandleProps,
}) => {
  const classes = useStyles();

  const {
    isEditable,
    version,
    getInputError,
    setSelectedProperty,
  } = useApplicationFormContext();

  const moreActionsRef = useRef<HTMLButtonElement | null>(null);

  const inputError = getInputError(propertyIndex);

  return (
    <Row
      className={cn(
        classes.root,
        isEditable && classes.editable,
        selected && classes.selected,
      )}
      center
      onClick={() => setSelectedProperty(propertyIndex)}
    >
      <Row inline halfMargin center>
        {isEditable && (
          <div className={classes.dragHandle} {...dragHandleProps}>
            <DragIndicatorIcon className={classes.buttonIcon} />
          </div>
        )}
        <div className={classes.name}>
          {getPropertyName(property, version?.strings)}
        </div>
      </Row>

      <div className={classes.type}>{getPropertyType(property)}</div>

      <Spacer />

      {inputError && <AlertIcon />}

      {isEditable && (
        <IconButton
          ref={moreActionsRef}
          edge="end"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            if (onMore && moreActionsRef.current) {
              onMore(moreActionsRef.current);
            }
          }}
        >
          <MoreHorizIcon color="inherit" className={classes.buttonIcon} />
        </IconButton>
      )}
    </Row>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 44,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.borderRadius.sm,
      marginBottom: theme.spacing(0.25),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },

    editable: {
      paddingLeft: theme.spacing(1),
    },

    selected: {
      boxShadow: `inset 0px 0px 0px 2px rgba(0, 0, 0, 0.8)`,
      borderColor: `rgba(0, 0, 0, 0.8)`,
    },

    buttonIcon: {
      opacity: 0.5,
    },

    name: {
      fontWeight: 500,
    },

    type: {
      fontStyle: 'italic',
    },

    dragHandle: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export default ApplicationInputRow;
