import { FC } from 'react';
import Heading from 'raydiant-elements/core/Heading';
import Column from 'raydiant-elements/layout/Column';
import ApplicationInputID from './ApplicationInputID';
import ApplicationInputRequired from './ApplicationInputRequired';
import ApplicationInputHelperText from './ApplicationInputHelperText';
import ApplicationInputHelperLink from './ApplicationInputHelperLink';

interface ApplicationInputTypeProductCatalogProps {}

const ApplicationInputTypeProductCatalog: FC<ApplicationInputTypeProductCatalogProps> = () => {
  return (
    <Column doubleMargin>
      <Column>
        <Heading overline size={5}>
          Properties
        </Heading>
        <ApplicationInputID />
      </Column>

      <Column>
        <Heading overline size={5}>
          Validation
        </Heading>
        <ApplicationInputRequired />
      </Column>

      <Column>
        <Heading overline size={5}>
          Appearance
        </Heading>
        <ApplicationInputHelperText />
        <ApplicationInputHelperLink />
      </Column>
    </Column>
  );
};

export default ApplicationInputTypeProductCatalog;
