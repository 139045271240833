import {
  useQuery,
  QueryKey,
  QueryFunction,
  UseQueryOptions,
} from 'react-query';
import { useHistory } from 'react-router';
import globalErrorHandler from '../utilities/globalErrorHandler';

// A wrapper around useQuery to catch Unauthorized errors from the api client and
// redirect to the login page if the page is protected. Unprotected pages are allowed
// to error without redirecting.
export default function useProtectedQuery<TQueryFnData, TError>(
  key: QueryKey,
  fn: QueryFunction<TQueryFnData, QueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError>,
) {
  const history = useHistory();
  const query = useQuery<TQueryFnData, TError>(key, fn, options);

  globalErrorHandler(query.error, history.location);

  return query;
}
