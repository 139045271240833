import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import MUIVpnKeyIcon from '@material-ui/icons/VpnKey';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Dialog from '@material-ui/core/Dialog';
import { buttonReset } from 'raydiant-elements/mixins';
import { Theme } from 'raydiant-elements/theme';
import Heading from 'raydiant-elements/core/Heading';
import Button from 'raydiant-elements/core/Button';
import Form from 'raydiant-elements/core/Form';
import MultiSelectField from 'raydiant-elements/core/MultiSelectField';
import Row from 'raydiant-elements/layout/Row';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Text from 'raydiant-elements/core/Text';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Column from 'raydiant-elements/layout/Column';
import PaperModal from '../PaperModal';
import { useApiKeyModalFormContext } from '../../hooks/useApiKeyModalForm';
import TextField from 'raydiant-elements/core/TextField';
import SelectField from 'raydiant-elements/core/SelectField';
import useLocations from '../../hooks/useLocations';
import useLocationMenus from '../../hooks/useLocationMenus';
import useLocation from '../../hooks/useLocation';
import {
  ApiKey,
  GetApiKeyMenusResponse,
  Location,
  Menu,
} from '../../clients/raydiantMenu';
import { createStyles, makeStyles } from 'raydiant-elements/styles';
import Link from 'raydiant-elements/core/Link';
import useApiKeyMenus from '../../hooks/useApiKeyMenus';
import AlertIcon from 'raydiant-elements/core/AlertIcon';
import Spacer from 'raydiant-elements/layout/Spacer';

const learnMoreLink = '/docs/on-brand-menu-api/api-keys';

interface ApiKeyModalFormProps {
  open: boolean;
  onClose: () => void;
  apiKey?: ApiKey;
}

type SelectedMenusPerLocation = Record<string, Array<Menu>>;

const initialState = {
  name: '',
  nameError: '',
  notes: '',
  locationId: '',
  selectedMenusPerLocation: {},
  token: '',
  generateApiKeyError: '',
  id: '',
  deleteApiKeyDialogOpen: false,
  dialogInputValue: null,
};

const ApiKeyModalForm: FC<ApiKeyModalFormProps> = (props) => {
  const { open, onClose } = props;
  const classes = useStyles();

  // State

  // NOTE: Modal state is reset onClose. When adding additional state values
  // be sure to update the effect that resets state too.
  const [id, setId] = useState(initialState.id);
  const [name, setName] = useState(initialState.name);
  const [nameError, setNameError] = useState(initialState.nameError);
  const [notes, setNotes] = useState(initialState.notes);
  const [locationId, setLocationId] = useState(initialState.locationId);
  const [
    selectedMenusPerLocation,
    setSelectedMenusPerLocation,
  ] = useState<SelectedMenusPerLocation>(initialState.selectedMenusPerLocation);
  const [token, setToken] = useState(initialState.token);
  const [generateApiKeyError, setGenerateApiKeyError] = useState(
    initialState.generateApiKeyError,
  );
  const [deleteApiKeyDialogOpen, setDeleteApiKeyDialogOpen] = useState(
    initialState.deleteApiKeyDialogOpen,
  );
  const [dialogInputValue, setDialogInputValue] = useState<string | null>(
    initialState.dialogInputValue,
  );

  // Queries

  const locations = useLocations();
  const location = useLocation(locationId);
  const locationMenus = useLocationMenus(locationId);
  const apiKeyMenus = useApiKeyMenus(props.apiKey?.id);

  // Effects

  // Reset state and focus name field when modal is opened
  useEffect(() => {
    if (!prevOpenRef.current && open) {
      if (nameRef.current) {
        nameRef.current.focus();
      }

      resetState();
    }
    prevOpenRef.current = open;
  }, [open]);

  // Make sure API key menus and the ones stored in state are in sync
  useEffect(() => {
    if (prevApiKeyMenus.current !== apiKeyMenus.data && apiKeyMenus.data) {
      const updatedMenusPerLocation = apiKeyMenus.data.reduce((accum, curr) => {
        return {
          ...accum,
          [curr.locationId]: [...(accum[curr.locationId] || []), curr],
        };
      }, {} as SelectedMenusPerLocation);

      setSelectedMenusPerLocation(updatedMenusPerLocation);
    }

    prevApiKeyMenus.current = apiKeyMenus.data || null;
  }, [apiKeyMenus, open]);

  // Make sure props.apiKey and the one stored in state are in sync
  useEffect(() => {
    if (prevApiKey.current !== props.apiKey) {
      if (!props.apiKey) {
        resetState();

        if (nameRef.current) {
          nameRef.current.focus();
        }

        return;
      }
      setId(props.apiKey.id);
      setName(props.apiKey.name);
      setNotes(props.apiKey.description ?? initialState.notes);
      setToken(props.apiKey.token);
    }

    prevApiKey.current = props.apiKey || null;
  }, [apiKeyMenus.data, props.apiKey]);

  // Contexts

  const formContext = useApiKeyModalFormContext();

  // Refs

  const nameRef = useRef<HTMLInputElement | null>(null);
  const prevOpenRef = useRef<boolean | null>();
  const prevApiKey = useRef<ApiKey | null>(null);
  const prevApiKeyMenus = useRef<GetApiKeyMenusResponse | null>(null);

  // Callbacks

  const validateGenerateApiKeyForm = () => {
    resetValidation();
    let isValid = true;

    if (!name) {
      isValid = false;
      setNameError('invalid');
    }

    if (Object.keys(selectedMenusPerLocation).length === 0) {
      isValid = false;
      setGenerateApiKeyError('missing_menus');
    }

    return isValid;
  };

  const resetState = () => {
    setId(initialState.id);
    setName(initialState.name);
    setNameError(initialState.nameError);
    setNotes(initialState.notes);
    setLocationId(initialState.locationId);
    setSelectedMenusPerLocation(initialState.selectedMenusPerLocation);
    setToken(initialState.token);
    setGenerateApiKeyError(initialState.generateApiKeyError);
    setDialogInputValue(initialState.dialogInputValue);
    prevApiKey.current = null;
    prevApiKeyMenus.current = null;
  };

  const resetValidation = () => {
    setNameError(initialState.nameError);
    setGenerateApiKeyError(initialState.generateApiKeyError);
  };

  const handleNameChange = (value: string) => {
    resetValidation();
    setName(value);
  };

  const handleSelectedMenusChange = (values: string[]) => {
    resetValidation();

    if (values.length === 0) {
      setSelectedMenusPerLocation((prevState) => {
        const prevStateCopy = { ...prevState };
        delete prevStateCopy[locationId];
        return prevStateCopy;
      });
      return;
    }

    setSelectedMenusPerLocation((prevState) => ({
      ...prevState,
      [locationId]: values.map(getMenu) as Menu[],
    }));
  };

  const handleLocationIdChange = (value: string) => {
    resetValidation();

    setLocationId(value);
  };

  const handleSubmit = async () => {
    if (!validateGenerateApiKeyForm()) return;
    if (!token || selectedMenusDirty()) {
      setGenerateApiKeyError('missing_api_token');
      return;
    }

    if (id) {
      await formContext.saveApiKey({
        id,
        name,
        description: notes,
        menus: getAllSelectedMenuIds(),
      });
    }

    onClose();
  };

  const handleGenerateApiKey = async () => {
    if (!validateGenerateApiKeyForm()) return;

    const data = await formContext.saveApiKey({
      id,
      name,
      description: notes,
      menus: getAllSelectedMenuIds(),
    });

    if (data) {
      setId(data.id);
      setToken(data.token);
    }
  };

  const handleCancelDeleteApiKeyDialog = () => {
    setDeleteApiKeyDialogOpen(false);
  };

  const handleConfirmDeleteApiKeyDialog = async () => {
    await formContext.deleteApiKey({
      id,
    });

    setDeleteApiKeyDialogOpen(false);
    onClose();
  };

  const handleOpenDeleteApiKeyDialog = () => {
    setDeleteApiKeyDialogOpen(true);
  };

  const deleteSelectedMenu = (locationId: string, menuId: string) => {
    setSelectedMenusPerLocation((prevState) => {
      const updatedMenusForLocation = prevState[locationId].filter(
        (menu) => menuId !== menu.id,
      );

      if (updatedMenusForLocation.length === 0) {
        const prevStateCopy = { ...prevState };
        delete prevStateCopy[locationId];

        return prevStateCopy;
      }

      return {
        ...prevState,
        [locationId]: updatedMenusForLocation,
      };
    });
  };

  const getLocation = useCallback(
    (id: string) => locations.data?.find((l) => l.id === id),
    [locations.data],
  );

  const getMenu = useCallback(
    (id: string) => locationMenus.data?.find((m) => m.id === id),
    [locationMenus.data],
  );

  const getFormattedLocationName = useCallback((location?: Location) => {
    if (!location) return;

    return `${location.sourceName} ${
      location.name ? `(${location.name})` : ''
    }`;
  }, []);

  const getAllSelectedMenus = useCallback(
    () =>
      Object.values(selectedMenusPerLocation).reduce((accum, v) => {
        return [...accum, ...v];
      }, [] as Menu[]),
    [selectedMenusPerLocation],
  );

  const getAllSelectedMenuIds = useCallback(
    () => getAllSelectedMenus().map((m) => m.id),
    [getAllSelectedMenus],
  );

  const selectedMenusDirty = useCallback(() => {
    if (!apiKeyMenus.data) return false;

    return (
      apiKeyMenus.data.length !== getAllSelectedMenus().length ||
      getAllSelectedMenus().some(
        (a) => !apiKeyMenus.data?.find((b) => b.id === a.id),
      )
    );
  }, [getAllSelectedMenus, apiKeyMenus.data]);

  // Memoizers

  const menuIdsForSelectedLocation = useMemo(
    () => selectedMenusPerLocation[locationId]?.map((sM) => sM.id) || [],
    [locationId, selectedMenusPerLocation],
  );

  const shouldDisableGenerateApiKeyButton =
    getAllSelectedMenus().length === 0 ||
    formContext.isLoading ||
    (!!token && !selectedMenusDirty());

  const shouldDisableSubmitButton = formContext.isLoading;
  const shouldDisableConfirmDeleteApiKeyButton =
    formContext.isLoading ||
    (dialogInputValue ?? '').toLowerCase() !== 'delete';
  const shouldDisableDeleteApiKeyDialogButton = !token || formContext.isLoading;

  // Render

  if (locations.isLoading) {
    return null;
  }

  return (
    <PaperModal
      open={open}
      onClose={onClose}
      title="API Key"
      titleIcon={<MUIVpnKeyIcon />}
    >
      <PaperModal.Body>
        <Heading>
          <Text
            ref={nameRef}
            autoFocus
            editable={true}
            value={name}
            onChange={handleNameChange}
            error={!!nameError}
          />
        </Heading>
        {nameError === 'invalid' && name && (
          <InputHelperText error>Oops! Please provide a name.</InputHelperText>
        )}
        <InputHelperText>*Required</InputHelperText>
      </PaperModal.Body>

      <Scrollable>
        <PaperModal.Body>
          <Column doubleMargin>
            <TextField
              label="Notes"
              multiline
              placeholder="“This is for Store X”, or “Use this for API Key Y”"
              onChange={setNotes}
              value={notes}
              helperText="Optional. Can help describe API Key"
              disabled={!name}
            />

            <div>
              <SelectField
                label="API Source"
                disabled={!name}
                onChange={handleLocationIdChange}
                value={locationId || 'placeholder'}
                helperText={'Select the location you want menus from'}
              >
                <option disabled value="placeholder">
                  Select source
                </option>
                {locations.data?.map((l) => (
                  <option
                    key={l.id}
                    value={l.id}
                    disabled={!l.connectorLocationId}
                  >
                    {getFormattedLocationName(l)}
                  </option>
                ))}
              </SelectField>
            </div>

            {location?.data && (
              <div>
                <MultiSelectField
                  label={getFormattedLocationName(location.data)}
                  onChange={handleSelectedMenusChange}
                  value={menuIdsForSelectedLocation}
                  searchable
                  disabled={!name}
                >
                  {locationMenus.data?.map((m) => (
                    <MultiSelectField.Option
                      key={m.id}
                      value={m.id}
                      label={m.name}
                    />
                  ))}
                </MultiSelectField>
              </div>
            )}

            <div className={classes.apiIngredients}>
              <Heading
                overline
                size={5}
                className={cn(
                  !token && classes.disabled,
                  classes.apiIngredientsHeading,
                )}
              >
                API key ingredients
              </Heading>
              {Object.entries(selectedMenusPerLocation).map(
                ([locationId, menus]) => (
                  <div key={locationId} className={classes.location}>
                    <Text bold className={classes.locationName} small>
                      {getFormattedLocationName(getLocation(locationId))}
                    </Text>

                    {menus.map((m) => (
                      <Row halfMargin className={classes.menu} key={m.id}>
                        <div>{m.name}</div>
                        <button
                          className={classes.remove}
                          onClick={() => {
                            deleteSelectedMenu(locationId, m.id);
                          }}
                        >
                          <HighlightOffIcon />
                        </button>
                      </Row>
                    ))}
                  </div>
                ),
              )}
            </div>

            <div>
              <Button
                icon={<MUIVpnKeyIcon />}
                iconAlignment="end"
                color="progress"
                fullWidth
                onClick={handleGenerateApiKey}
                disabled={shouldDisableGenerateApiKeyButton}
              >
                Generate API Key
              </Button>
              {getAllSelectedMenus().length > 0 &&
                !generateApiKeyError &&
                !shouldDisableGenerateApiKeyButton && (
                  <InputHelperText>
                    Will generate API Key from the{' '}
                    {getAllSelectedMenus().length === 1 && '1 above menu'}
                    {getAllSelectedMenus().length > 1 &&
                      `${getAllSelectedMenus().length} above menus`}
                  </InputHelperText>
                )}
              {generateApiKeyError === 'missing_menus' && (
                <InputHelperText error>
                  Please select one menu or more before generating an API key.
                </InputHelperText>
              )}
              {generateApiKeyError === 'missing_api_token' && (
                <InputHelperText error>
                  Please click the 'Generate API Key' button before clicking
                  done.
                </InputHelperText>
              )}
            </div>

            <div className={classes.apiKey}>
              <div
                className={cn(
                  !token && classes.disabled,
                  classes.apiKeyDetails,
                )}
              >
                <Heading overline size={5}>
                  API Key
                </Heading>
                <Text>
                  {token ? token : 'XXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXX'}
                </Text>
                <InputHelperText>
                  Used for pulling data from above locations and menus.{' '}
                  <Link href={learnMoreLink} target="_blank">
                    Learn more
                  </Link>
                </InputHelperText>
              </div>

              <div>
                <Button
                  icon={<DeleteForeverIcon />}
                  iconAlignment="end"
                  color="destructive"
                  fullWidth
                  onClick={handleOpenDeleteApiKeyDialog}
                  disabled={shouldDisableDeleteApiKeyDialogButton}
                >
                  Delete API Key
                </Button>
                <InputHelperText>
                  Will permanently remove this API Key
                </InputHelperText>
              </div>
            </div>
          </Column>
        </PaperModal.Body>

        <PaperModal.Footer>
          <Row>
            <ActionBar.Action
              color="primaryText"
              icon={<CloseIcon />}
              label="Cancel"
              onClick={onClose}
              fullWidth
            />
            <Button
              fullWidth
              color="primary"
              onClick={handleSubmit}
              disabled={shouldDisableSubmitButton}
            >
              Done
            </Button>
          </Row>
        </PaperModal.Footer>
      </Scrollable>

      <Dialog open={deleteApiKeyDialogOpen} maxWidth="xs">
        <Form onSubmit={handleConfirmDeleteApiKeyDialog}>
          <div className={classes.dialogContent}>
            <Row halfMargin>
              <AlertIcon color="warning" />
              <Text>
                Removing an API Key cannot be undone. To proceed, type{' '}
                <strong>delete</strong> below:
              </Text>
            </Row>
            <TextField
              autoFocus
              label=""
              value={dialogInputValue ?? ''}
              onChange={setDialogInputValue}
            />
          </div>
          <Row className={classes.dialogActions}>
            <Spacer />
            <Button label="Cancel" onClick={handleCancelDeleteApiKeyDialog} />
            <Button
              type="submit"
              label="Delete"
              color="destructive"
              disabled={shouldDisableConfirmDeleteApiKeyButton}
            />
          </Row>
        </Form>
      </Dialog>
    </PaperModal>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    apiIngredients: {
      marginBottom: theme.spacing(8.25),
    },
    apiIngredientsHeading: {
      marginBottom: theme.spacing(2.5),
    },
    location: {
      marginBottom: theme.spacing(3.25),
    },
    locationName: {},
    menu: {
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
      justifyContent: 'space-between',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.divider.secondary,
    },
    remove: {
      ...buttonReset(),
      display: 'flex',
      color: theme.palette.text.secondary,
      opacity: 0.6,

      '&:hover': {
        opacity: 1,
      },

      '&:disabled': {
        opacity: 0,
        cursor: 'default',
      },
    },
    apiKey: {
      marginBottom: theme.spacing(2),
    },
    apiKeyDetails: {
      marginBottom: theme.spacing(7),
    },
    disabled: {
      opacity: 0.4,
    },
    dialogContent: {
      padding: theme.spacing(2),
    },
    dialogActions: {
      paddingTop: 0,
      padding: theme.spacing(2),
    },
  }),
);

export default ApiKeyModalForm;
