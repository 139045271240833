import { Application, ApiClientError } from '@raydiant/api-client-js';
import raydiant from '../clients/raydiant';
import useProtectedQuery from './useProtectedQuery';

export default function useApplications() {
  const query = useProtectedQuery<Application[], ApiClientError>(
    'applications',
    () => raydiant.getApplications(),
  );

  return query;
}
