import { ApplicationVersion } from '@raydiant/api-client-js';
import { getPropertyName, getPropertyId } from '../../utilities/properties';
import {
  isValidVersion,
  isHigherAppVersion,
  getLatestVersion,
  getVersionText,
} from '../../utilities/appVersion';
import { State, ErrorCode } from './types';
import keys from './stateKeys';

export const maxFileSize = 500000; // 500kb

export const createUpload = (file: File | null) => {
  if (!file) return null;
  return {
    file,
    previewUrl: URL.createObjectURL(file),
  };
};

export const isStateDirty = ({
  newApplication,
  newVersion,
  newVersionIconUpload,
  newVersionThumbnailUpload,
}: State) => {
  if (newApplication) return true;
  if (newVersion) return true;
  if (newVersionIconUpload) return true;
  if (newVersionThumbnailUpload) return true;
  return false;
};

export const getErrors = (
  {
    newApplication,
    newVersion,
    newVersionThumbnailUpload,
    newVersionIconUpload,
  }: State,
  allVersions: ApplicationVersion[],
) => {
  const errors: Record<string, ErrorCode> = {};

  if (newApplication) {
    if (!newApplication.name) {
      errors[keys.applicationName()] = 'invalid';
    }
    // Prevent setting 'new' as the app id to avoid conflicting with the Dev Portal's apps/new route.
    if (newApplication.name === 'new') {
      errors[keys.applicationName()] = 'invalid';
    }
  }

  if (newVersion) {
    if (!newVersion.name) {
      errors[keys.versionName()] = 'invalid';
    }

    if (!newVersion.embeddedUrlFormat) {
      errors[keys.embeddedUrlFormat()] = 'invalid';
    }

    const latestVersion = getLatestVersion(allVersions);

    const versionText = getVersionText(newVersion);
    if (!isValidVersion(versionText)) {
      errors[keys.versionText()] = 'invalid';
    } else if (
      latestVersion &&
      !isHigherAppVersion(versionText, latestVersion)
    ) {
      errors[keys.versionText()] = 'versionEqualOrLower';
    }

    if (
      newVersionThumbnailUpload &&
      newVersionThumbnailUpload.file.size > maxFileSize
    ) {
      errors[keys.thumbnailUpload()] = 'fileTooLarge';
    }

    if (newVersionIconUpload && newVersionIconUpload.file.size > maxFileSize) {
      errors[keys.iconUpload()] = 'fileTooLarge';
    }

    // If helper link url is set, helper link text must be set (and vise versa)
    const [helperLinkTextMissing, helperLinkUrlMissing] = helperLinkErrors(
      newVersion.helperLinkText,
      newVersion.helperLinkUrl,
    );

    if (helperLinkTextMissing) {
      errors[keys.helperLinkText()] = 'invalid';
    }

    if (helperLinkUrlMissing) {
      errors[keys.helperLinkUrl()] = 'invalid';
    }

    newVersion.presentationProperties.forEach((property, index) => {
      const inputKey = keys.input(index);

      const nameKey = keys.inputName(index);
      if (!getPropertyName(property, newVersion.strings)) {
        errors[nameKey] = 'invalid';
        errors[inputKey] = 'invalid';
      }

      const idKey = keys.inputId(index);
      if (!getPropertyId(property)) {
        errors[idKey] = 'invalid';
        errors[inputKey] = 'invalid';
      }
    });
  }

  return errors;
};

const helperLinkErrors = (
  helperLinkText: string | undefined,
  helperLinkUrl: string | undefined,
) => {
  return [
    missingStringXor(helperLinkUrl, helperLinkText),
    missingStringXor(helperLinkText, helperLinkUrl),
  ];
};

const missingStringXor = (a: string | undefined, b: string | undefined) => {
  return a && (!b || b.trim().length < 1);
};
