// TODO: This component is mostly copied from the Dashboard, consider adding it to elements.

import { useState, useEffect, useRef, ComponentProps } from 'react';
import cn from 'classnames';
import Button from 'raydiant-elements/core/Button';
import Text from 'raydiant-elements/core/Text';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Row from 'raydiant-elements/layout/Row';
import Spacer from 'raydiant-elements/layout/Spacer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export type LoadingStatus = 'idle' | 'loading' | 'success' | 'error';

export interface LoadingButtonProps {
  className?: string;
  color?: ComponentProps<typeof Button>['color'];
  status?: LoadingStatus;
  label: string;
  icon?: React.ReactNode;
  loadingLabel?: string;
  successLabel?: string;
  errorLabel?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const LoadingButton = ({
  className,
  color = 'default',
  status = 'idle',
  label,
  icon,
  loadingLabel,
  successLabel,
  errorLabel,
  fullWidth,
  disabled,
  onClick,
}: LoadingButtonProps) => {
  const classes = useStyles();

  // State

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>('idle');

  // Effects

  const prevStatus = useRef<LoadingStatus>(status);
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (status === 'loading') {
      setLoadingStatus('loading');
    } else if (status === 'success') {
      if (prevStatus.current === 'loading') {
        setLoadingStatus('success');
        timeout = setTimeout(() => {
          setLoadingStatus('idle');
        }, 5000); // Success label should remain visible for 5 seconds.
      }
    } else if (status === 'error') {
      if (prevStatus.current === 'loading') {
        setLoadingStatus('error');
        timeout = setTimeout(() => {
          setLoadingStatus('idle');
        }, 5000); // Error label should remain visible for 5 seconds.
      }
    }

    prevStatus.current = status;

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [status]);

  // Render

  if (loadingStatus === 'loading') {
    return (
      <div className={className}>
        <Row halfMargin center className={cn(classes.status, classes.primary)}>
          {fullWidth && <Spacer />}
          <Text xsmall>{loadingLabel}</Text>
          <CircularProgress color="inherit" size={20} />
        </Row>
      </div>
    );
  }

  if (loadingStatus === 'success') {
    return (
      <div className={className}>
        <Row halfMargin center className={cn(classes.status, classes.primary)}>
          {fullWidth && <Spacer />}
          <Text xsmall>{successLabel}</Text>
          <CheckCircleIcon className={classes.success} />
        </Row>
      </div>
    );
  }

  if (loadingStatus === 'error') {
    return (
      <div className={className}>
        <Row halfMargin center className={cn(classes.status, classes.error)}>
          {fullWidth && <Spacer />}
          <Text xsmall>{errorLabel}</Text>
          <ErrorIcon className={classes.error} />
        </Row>
      </div>
    );
  }

  return (
    <div className={className}>
      <Button
        color={color}
        label={label}
        icon={icon}
        onClick={onClick}
        fullWidth={fullWidth}
        disabled={disabled}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    status: {
      color: theme.palette.primary.main,
      // Copied from Button styles to match alignment.
      height: 40,

      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },

    primary: {
      color: theme.palette.primary.main,
    },

    muted: {
      color: theme.palette.text.secondary,
    },

    success: {
      color: theme.palette.progress.main,
    },

    error: {
      color: theme.palette.error.main,
    },
  });
});

export default LoadingButton;
