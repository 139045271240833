import ApiClient from '@raydiant/api-client-js';
import config from '../config';
import getAccessToken from '../utilities/getAccessToken';

const apiClient: ApiClient = new ApiClient({
  baseUrl: config.raydiantApiUrl,
  getAccessToken,
  fetcher: window.fetch.bind(window),
});

export default apiClient;
