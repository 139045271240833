import { useState, useEffect } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Input from 'raydiant-elements/core/Input';
import Text from 'raydiant-elements/core/Text';
import {
  getPropertyProvider,
  setPropertyProvider,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

const ApplicationInputOAuthProvider = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  // State

  const [provider, setProvider] = useState(
    selectedProperty ? getPropertyProvider(selectedProperty) : '',
  );

  // Callbacks

  const handleBlur = () => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyProvider(selectedProperty, provider),
      version.strings,
    );
  };

  // Effects

  // Reset provider when property changes.
  useEffect(() => {
    setProvider(selectedProperty ? getPropertyProvider(selectedProperty) : '');
  }, [selectedProperty]);

  // Render

  return (
    <div>
      <InputLabel>OAuth Provider</InputLabel>

      {isEditable && (
        <>
          <Input value={provider} onChange={setProvider} onBlur={handleBlur} />
          <InputHelperText>
            The name of the OAuth provider (ie. Google)
          </InputHelperText>
        </>
      )}

      {!isEditable && selectedProperty && (
        <Text>{getPropertyProvider(selectedProperty) || <em>None</em>}</Text>
      )}
    </div>
  );
};

export default ApplicationInputOAuthProvider;
