import { FC } from 'react';
import Heading from 'raydiant-elements/core/Heading';
import Column from 'raydiant-elements/layout/Column';
import ApplicationInputID from './ApplicationInputID';
import ApplicationInputRequired from './ApplicationInputRequired';
import ApplicationInputHelperText from './ApplicationInputHelperText';
import ApplicationInputHelperLink from './ApplicationInputHelperLink';
import ApplicationInputDefaultNumber from './ApplicationInputDefaultNumber';
import ApplicationInputMinNumber from './ApplicationInputMinNumber';
import ApplicationInputMaxNumber from './ApplicationInputMaxNumber';

interface ApplicationInputTypeTextProps {}

const ApplicationInputTypeText: FC<ApplicationInputTypeTextProps> = () => {
  return (
    <Column doubleMargin>
      <Column>
        <Heading overline size={5}>
          Properties
        </Heading>
        <ApplicationInputID />
        <ApplicationInputDefaultNumber />
      </Column>

      <Column>
        <Heading overline size={5}>
          Validation
        </Heading>
        <ApplicationInputRequired />
        <ApplicationInputMinNumber />
        <ApplicationInputMaxNumber />
      </Column>

      <Column>
        <Heading overline size={5}>
          Appearance
        </Heading>
        <ApplicationInputHelperText />
        <ApplicationInputHelperLink />
      </Column>
    </Column>
  );
};

export default ApplicationInputTypeText;
