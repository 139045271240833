import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import auth0 from '../clients/auth0';

interface LoginPageProps {}

const LoginPage: FC<LoginPageProps> = () => {
  const history = useHistory();

  const searchParams = new URLSearchParams(history.location.search);
  const backTo = searchParams.get('backTo');

  // Redirect to login page.
  useEffect(() => {
    (async () => {
      await auth0.loginWithRedirect({ backTo });
    })();
  }, [backTo]);

  return null;
};

export default LoginPage;
