import { Application } from '@raydiant/api-client-js';
import defaultVersion from './defaultVersion';

const defaultApplication: Application = {
  id: '',
  name: '',
  currentDeploymentId: '',
  currentAppVersion: defaultVersion,
  isDeprecated: false,
  isMarketplace: false,
  applicationCategoryId: null,
  applicationCategory: null,
  websiteUrl: null,
  resource: {
    id: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: null,
    parentFolderId: null,
    lastActivityAt: null,
    profile: {
      id: '',
      email: '',
      name: '',
      thumbnailUrl: '',
      domainId: '',
      domainRole: 'standard',
    },
    domain: {
      id: '',
      name: '',
      description: '',
      adminProfile: {
        id: '',
        email: '',
        name: '',
        thumbnailUrl: '',
        domainId: '',
        domainRole: 'standard',
      },
    },
    r: {
      resourceACLs: [],
      tags: [],
    },
  },
};

export default defaultApplication;
