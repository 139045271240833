import useApiKeys from './useApiKeys';

export default function useApiKey(id: string) {
  const query = useApiKeys();

  return {
    ...query,
    // replace data field with single API key
    data: query.data?.find((x) => x.id === id),
  };
}
