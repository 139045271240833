import { FC, ReactNode } from 'react';
import MUIPaperModal from 'raydiant-elements/core/PaperModal';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import PaperModalHeader from 'raydiant-elements/core/PaperModal/PaperModalHeader';
import PaperModalBody from 'raydiant-elements/core/PaperModal/PaperModalBody';
import withThemeSelector from 'raydiant-elements/core/withThemeSelector';
import PaperModalFooter from 'raydiant-elements/core/PaperModal/PaperModalFooter';

interface PaperModalProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  titleIcon?: ReactNode;
}

const PaperModal: FC<PaperModalProps> = ({
  children,
  open,
  onClose,
  title,
  titleIcon,
}) => {
  const classes = useStyles();
  return (
    <MUIPaperModal
      title={title}
      titleIcon={titleIcon}
      color="light"
      className={classes.root}
      open={open}
      onClose={onClose}
    >
      {children}
    </MUIPaperModal>
  );
};

const modalWidth = 380;
const modalTopOffset = 150;
const modalRightOffset = 24;
const modalBottomOffset = 16;
const modalGutter = 48;
const modalBreakpoint = modalWidth + modalRightOffset + modalGutter;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      top: modalTopOffset,
      right: modalRightOffset,
      width: modalWidth,
      height: `calc(100vh - ${modalTopOffset}px - ${modalBottomOffset}px)`,

      [`@media screen and (max-width: ${modalBreakpoint}px)`]: {
        left: 'auto',
        right: modalGutter,
      },
    },
  }),
);

export default Object.assign(withThemeSelector(PaperModal), {
  Header: PaperModalHeader,
  Body: PaperModalBody,
  Footer: PaperModalFooter,
});
