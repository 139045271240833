import { FC, useState, useEffect } from 'react';
import { PresentationProperty } from '@raydiant/api-client-js';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Input from 'raydiant-elements/core/Input';
import Text from 'raydiant-elements/core/Text';
import { getPropertyMax, setPropertyMax } from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationInputMaxNumberProps {}

const getMaxValueFromProperty = (property: PresentationProperty | null) => {
  if (!property) return '';
  if (typeof getPropertyMax(property) !== 'number') return '';
  return String(getPropertyMax(property));
};

const ApplicationInputMaxNumber: FC<ApplicationInputMaxNumberProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  // State

  const [max, setMax] = useState(getMaxValueFromProperty(selectedProperty));

  // Callbacks

  const handleBlur = () => {
    if (!selectedProperty) return;
    if (!version) return;

    const maxNumber = parseInt(max, 10);
    updateSelectedProperty(
      setPropertyMax(selectedProperty, isNaN(maxNumber) ? null : maxNumber),
      version.strings,
    );
  };

  // Effects

  // Reset max value when property changes.
  useEffect(() => {
    setMax(getMaxValueFromProperty(selectedProperty));
  }, [selectedProperty]);

  // Render

  return (
    <div>
      <InputLabel>Max Value</InputLabel>

      {isEditable && (
        <>
          <div style={{ maxWidth: 120 }}>
            <Input
              type="number"
              value={max}
              onChange={setMax}
              onBlur={handleBlur}
            />
          </div>
          <InputHelperText>
            The maximum number allowed for this input.
          </InputHelperText>
        </>
      )}

      {!isEditable && selectedProperty && (
        <Text>{getPropertyMax(selectedProperty) ?? <em>None</em>}</Text>
      )}
    </div>
  );
};

export default ApplicationInputMaxNumber;
