import { FC, useState, useEffect } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Input from 'raydiant-elements/core/Input';
import Text from 'raydiant-elements/core/Text';
import {
  getPropertyHelperText,
  setPropertyHelperText,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationInputHelperTextProps {}

const ApplicationInputHelperText: FC<ApplicationInputHelperTextProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    selectedPropertyIndex,
    updateSelectedProperty,
    getInputHelperTextError,
  } = useApplicationFormContext();

  // State

  const [helperText, setHelperText] = useState(
    selectedProperty ? getPropertyHelperText(selectedProperty) || '' : '',
  );

  // Callbacks

  const handleBlur = () => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyHelperText(selectedProperty, helperText),
      version.strings,
    );
  };

  // Effects

  // Set helperText when selectedProperty changes.
  useEffect(() => {
    setHelperText(
      selectedProperty ? getPropertyHelperText(selectedProperty) || '' : '',
    );
  }, [selectedProperty]);

  // Render

  const helperTextError =
    selectedPropertyIndex !== null
      ? getInputHelperTextError(selectedPropertyIndex)
      : null;

  return (
    <div>
      <InputLabel error={!!helperTextError}>Helper Text</InputLabel>

      {isEditable && (
        <>
          <Input
            type="text"
            value={helperText}
            onChange={setHelperText}
            onBlur={handleBlur}
            error={!!helperTextError}
          />
          <InputHelperText error={!!helperTextError}>
            {helperTextError === 'invalid' &&
              'Oops! Please enter a valid helper text.'}
            {!helperTextError &&
              'Helpful text to explain the input behavior to the operator.'}
          </InputHelperText>
        </>
      )}

      {!isEditable && <Text>{helperText || <em>None</em>}</Text>}
    </div>
  );
};

export default ApplicationInputHelperText;
