import { useState, useEffect } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import ColorField from 'raydiant-elements/core/ColorField';
import { useTheme } from 'raydiant-elements/styles';
import Text from 'raydiant-elements/core/Text';
import {
  getPropertyForegroundColor,
  setPropertyForegroundColor,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

const ApplicationInputForegroundColor = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  const theme = useTheme();

  // State

  const [foregroundColor, setForegroundColor] = useState(
    selectedProperty ? getPropertyForegroundColor(selectedProperty) : '',
  );

  // Callbacks

  const onClose = () => {
    if (!selectedProperty) return;
    if (!version) return;
    if (!foregroundColor) return;

    updateSelectedProperty(
      setPropertyForegroundColor(selectedProperty, foregroundColor),
      version.strings,
    );
  };

  // Effects

  // Set foreground color when property changes.
  useEffect(() => {
    setForegroundColor(
      selectedProperty ? getPropertyForegroundColor(selectedProperty) : '',
    );
  }, [selectedProperty]);

  // Render

  return (
    <div>
      {isEditable && (
        <ColorField
          label="Foreground Color"
          value={foregroundColor || theme.palette.primary.contrastText}
          onChange={setForegroundColor}
          onClose={onClose}
        />
      )}

      {!isEditable && selectedProperty && (
        <>
          <InputLabel>Foreground Color</InputLabel>
          <Text>
            {getPropertyForegroundColor(selectedProperty) || <em>None</em>}
          </Text>
        </>
      )}
    </div>
  );
};

export default ApplicationInputForegroundColor;
