import { FC, useEffect, useState } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Input from 'raydiant-elements/core/Input';
import Text from 'raydiant-elements/core/Text';
import { PresentationProperty } from '@raydiant/api-client-js';
import {
  getPropertyMaxLength,
  setPropertyMaxLength,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationInputMaxLengthProps {}

const getMaxLengthFromProperty = (property: PresentationProperty | null) => {
  return property ? String(getPropertyMaxLength(property) || '') : '';
};

const ApplicationInputMaxLength: FC<ApplicationInputMaxLengthProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    selectedPropertyIndex,
    updateSelectedProperty,
    getInputMaxLengthError,
  } = useApplicationFormContext();

  // State

  const [maxLength, setMaxLength] = useState(
    getMaxLengthFromProperty(selectedProperty),
  );

  // Callbacks

  const handleBlur = () => {
    if (!selectedProperty) return;
    if (!version) return;

    const maxLengthNum = parseInt(maxLength, 10);
    updateSelectedProperty(
      setPropertyMaxLength(
        selectedProperty,
        isNaN(maxLengthNum) ? null : maxLengthNum,
      ),
      version.strings,
    );
  };

  // Effects

  // Set maxLength when selectedProperty changes.
  useEffect(() => {
    setMaxLength(getMaxLengthFromProperty(selectedProperty));
  }, [selectedProperty]);

  // Render

  const maxLengthError =
    selectedPropertyIndex !== null
      ? getInputMaxLengthError(selectedPropertyIndex)
      : null;

  return (
    <div>
      <InputLabel error={!!maxLengthError}>Max Length</InputLabel>

      {isEditable && (
        <>
          <div style={{ maxWidth: 120 }}>
            <Input
              type="number"
              value={String(maxLength || '')}
              onChange={setMaxLength}
              onBlur={handleBlur}
              error={!!maxLengthError}
            />
          </div>
          <InputHelperText error={!!maxLengthError}>
            {maxLengthError === 'invalid' &&
              'Oops! Please enter a valid max length.'}
            {!maxLengthError && 'Maximum number of characters.'}
          </InputHelperText>
        </>
      )}

      {!isEditable && <Text>{maxLength || <em>None</em>}</Text>}
    </div>
  );
};

export default ApplicationInputMaxLength;
