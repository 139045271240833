import { FC, useEffect, useState, useMemo } from 'react';
import Heading from 'raydiant-elements/core/Heading';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Column from 'raydiant-elements/layout/Column';
import Input from 'raydiant-elements/core/Input';
import { useApplicationFormContext } from '../../../hooks/useApplicationForm';
import Row from 'raydiant-elements/layout/Row';
import Button from 'raydiant-elements/core/Button';
import ApplicationSharingAccessList from './ApplicationSharingAccessList';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import useResourceACLs from '../../../hooks/useResourceACLs';
import { Theme, createStyles, makeStyles } from 'raydiant-elements/styles';
import useCreateResourceACLs from '../../../hooks/useCreateResourceACL';

export interface ACLGrantEmail {
  id: string;
  email: string;
  name: string;
}

interface ApplicationSharingProps {}

const ApplicationSharing: FC<ApplicationSharingProps> = () => {
  const { application } = useApplicationFormContext();

  const resourceId = application?.resource.id ?? '';
  const resourceACLs = useResourceACLs(resourceId);
  const { mutateAsync: createResourceACL } = useCreateResourceACLs();

  // States

  const [email, setEmail] = useState('');
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [duplicateEmailError, setDuplicateEmailError] = useState(false);

  const handleSubmit = async () => {
    if (!email.length) {
      return;
    }

    // Clear errors
    setInvalidEmailError(false);

    await createResourceACL(
      {
        params: {
          resourceId: resourceId || '',
          grants: ['read'],
          grantProfileEmail: email,
        },
      },
      {
        onError: () => {
          setInvalidEmailError(true);
          return;
        },
      },
    );

    setEmail('');
  };

  const sharedEmails = useMemo(
    () => resourceACLs.data?.map((acl) => acl.r.grantProfile?.email) ?? [],
    [resourceACLs.data],
  );

  useEffect(() => {
    setInvalidEmailError(false);

    if (sharedEmails.includes(email)) {
      setDuplicateEmailError(true);
    } else {
      setDuplicateEmailError(false);
    }
  }, [email, sharedEmails]);

  const classes = useStyles();

  return (
    <Column>
      <Heading size={5} overline>
        Sharing
      </Heading>

      <InputLabel>Email</InputLabel>
      <Row>
        <div className={classes.fullWidth}>
          <Input
            type="text"
            value={email}
            error={!!invalidEmailError || !!duplicateEmailError}
            onChange={setEmail}
            className={classes.fullWidth}
          />
        </div>
        <div>
          <Button
            fullWidth
            label="Share"
            onClick={handleSubmit}
            disabled={!!duplicateEmailError}
          />
        </div>
      </Row>
      <InputHelperText error={!!invalidEmailError || !!duplicateEmailError}>
        {(invalidEmailError && 'Account does not exist') ||
          (duplicateEmailError && 'App is already shared with this email')}
      </InputHelperText>

      <Row>
        <div className={classes.fullWidth}>
          <InputLabel>Accounts with access</InputLabel>
          <ApplicationSharingAccessList
            resourceACLs={resourceACLs.data ?? []}
          />
        </div>
      </Row>
    </Column>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
  }),
);

export default ApplicationSharing;
