import { FC, useMemo, useState } from 'react';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { ResourceACL } from '@raydiant/api-client-js';
import { ACLGrantEmail } from './ApplicationSharing';
import ApplicationSharingRemovePopover from './ApplicationSharingRemovePopover';
import AlertIcon from 'raydiant-elements/core/AlertIcon';
import Column from 'raydiant-elements/layout/Column';
import Row from 'raydiant-elements/layout/Row';
import Text from 'raydiant-elements/core/Text';
import Spacer from 'raydiant-elements/layout/Spacer';
import Button from 'raydiant-elements/core/Button';
import { textTruncate, buttonReset } from 'raydiant-elements/mixins';
import { Theme, createStyles, makeStyles } from 'raydiant-elements/styles';
import useDeleteResourceACL from '../../../hooks/useDeleteResourceACL';

interface ApplicationSharingAccessListProps {
  resourceACLs: ResourceACL[];
}

const profileInitialState: ACLGrantEmail = {
  id: '',
  email: '',
  name: '',
};

const ApplicationSharingAccessList: FC<ApplicationSharingAccessListProps> = ({
  resourceACLs,
}) => {
  const { mutateAsync: deleteResouceACL } = useDeleteResourceACL();

  // States

  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [profileToRemove, setProfileToRemove] = useState(profileInitialState);
  const [confirmationEl, setConfirmationEl] = useState<Element | null>(null);

  const shares: ACLGrantEmail[] = useMemo(() => {
    const shareList: ACLGrantEmail[] = [];
    resourceACLs?.reduce((result, acl) => {
      const grantProfile = acl.r.grantProfile;
      const email = grantProfile?.email;
      const name = grantProfile?.name ?? '';
      if (email) {
        result.push({ id: acl.id, email, name });
      }
      return result;
    }, shareList);
    return shareList;
  }, [resourceACLs]);

  const openRemoveShareModal = (profile: ACLGrantEmail) => {
    // Close any open modals
    closeRemoveShareModal();

    setShowRemoveModal(true);
    setProfileToRemove(profile);
  };

  const closeRemoveShareModal = () => {
    setShowRemoveModal(false);
    setProfileToRemove(profileInitialState);
  };

  const handleSubmit = async () => {
    if (profileToRemove.email === '') {
      return;
    }

    await deleteResouceACL({ aclId: profileToRemove.id });

    closeRemoveShareModal();
  };

  const classes = useStyles();

  return (
    <Column className={classes.fullWidth}>
      {shares.map((profile, i) => (
        <Row key={i} halfMargin>
          <Tooltip title={profile.email}>
            <div className={classes.fullWidth}>
              <div className={classes.aclName}>{profile.name}</div>
              <div className={classes.aclEmail}>{profile.email}</div>
            </div>
          </Tooltip>
          <button
            className={classes.remove}
            disabled={!profile}
            onClick={(event) => {
              setConfirmationEl(event.currentTarget);
              openRemoveShareModal(profile);
            }}
          >
            <CancelOutlinedIcon />
          </button>
        </Row>
      ))}
      <ApplicationSharingRemovePopover
        variant="alert"
        open={showRemoveModal}
        anchorEl={confirmationEl}
        onClose={closeRemoveShareModal}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Column>
          <Row halfMargin>
            <AlertIcon color="warning" />
            <Text>
              Removing access will no longer allow{' '}
              <strong>{profileToRemove.email}</strong> to use this app.
            </Text>
          </Row>
          <Row>
            <Spacer />
            <Button label="Cancel" onClick={closeRemoveShareModal} fullWidth />
            <Button
              fullWidth
              color="destructive"
              label="Remove Access"
              onClick={handleSubmit}
            />
          </Row>
        </Column>
      </ApplicationSharingRemovePopover>
    </Column>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '100%',
    },

    acl: {
      alignItems: 'center',
    },

    aclName: {
      ...textTruncate(),
      flex: 1,
      fontSize: theme.fontSizes.lg,
      letterSpacing: 0.15,
    },

    aclEmail: {
      ...textTruncate(),
      flex: 1,
      fontSize: theme.fontSizes.md,
      color: 'rgba(0, 0, 0, 0.45)',
      fontWeight: 500,
      letterSpacing: 0.1,
    },

    remove: {
      ...buttonReset(),
      display: 'flex',
      color: theme.palette.text.secondary,
      opacity: 0.6,

      '&:hover': {
        opacity: 1,
      },

      '&:disabled': {
        opacity: 0,
        cursor: 'default',
      },
    },
  }),
);

export default ApplicationSharingAccessList;
