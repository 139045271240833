import { makeStyles, createStyles } from 'raydiant-elements/styles';
import Text from 'raydiant-elements/core/Text';
import Row from 'raydiant-elements/layout/Row';
import { Theme } from 'raydiant-elements/theme';
import { FC } from 'react';
import MarketplaceIndicator from './MarketplaceIndicator';
import Asset from './Asset';

interface AppCardProps {
  name: string;
  appVersion: string;
  thumbnailUrl: string;
  isMarketplace: boolean;
  onClick: () => void;
}

const AppCard: FC<AppCardProps> = ({
  name,
  appVersion,
  thumbnailUrl,
  isMarketplace,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Row className={classes.root} onClick={onClick} center>
      <Asset width={110} height={62} url={thumbnailUrl} />
      <div>
        <div className={classes.name}>{name}</div>
        <Row>
          <Text small>Version {appVersion}</Text>
          {isMarketplace && <MarketplaceIndicator />}
        </Row>
      </div>
    </Row>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      borderRadius: theme.borderRadius.sm,
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(0.25),
      background: theme.palette.background.default,
    },
    name: {
      fontSize: theme.fontSizes.lg,
      fontWeight: 500,
    },
  }),
);

export default AppCard;
