import * as React from 'react';
import { useHistory, Redirect } from 'react-router';
import AddIcon from '@material-ui/icons/Add';
import Paper from 'raydiant-elements/core/Paper';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Center from 'raydiant-elements/layout/Center';
import { makeStyles, createStyles, Theme } from 'raydiant-elements/styles';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import { getVersionText } from '../utilities/appVersion';
import * as routes from '../routes';
import useEditableApplications from '../hooks/useEditableApplications';
import useRoles from '../hooks/useRoles';
import AppCard from '../components/AppCard';
import Page from '../components/Page';

interface ApplicationsPageProps {}

const ApplicationsPage: React.FC<ApplicationsPageProps> = () => {
  const history = useHistory();
  const classes = useStyles();

  const { isLoading, error, data: applications } = useEditableApplications();
  const { isLoading: isLoadingRoles, data: roles } = useRoles();

  // Render

  if (isLoading) {
    return (
      <Page>
        <Center>
          <CircularProgress size={30} />
        </Center>
      </Page>
    );
  }

  if (error) {
    // TODO: Better error handling.
    return (
      <Page>
        <Center>Oops! Something went wrong.</Center>
      </Page>
    );
  }

  const hasDeveloperRole = roles?.includes('developer');
  if (!isLoadingRoles && !hasDeveloperRole) {
    return <Redirect to={routes.notAllowed()} />;
  }

  const renderActionBar = () => (
    <div className={classes.actions}>
      <Paper color="light" className={classes.section}>
        <ActionBar>
          <ActionBar.Action
            icon={<AddIcon className={classes.newApplicationIcon} />}
            label="Create App"
            onClick={() => {
              history.push(routes.newApplication());
            }}
          />
        </ActionBar>
      </Paper>
    </div>
  );

  if (applications?.length === 0) {
    return (
      <Page>
        <div className={classes.container}>
          {renderActionBar()}No applications
        </div>
      </Page>
    );
  }

  return (
    <Page>
      <div className={classes.container}>
        {renderActionBar()}
        {applications?.map((application) => {
          const currentAppVersion = application.currentAppVersion;

          return (
            <Paper color="light" key={currentAppVersion.id}>
              <AppCard
                key={application.id}
                onClick={() => {
                  history.push(routes.editApplication(application.name));
                }}
                name={currentAppVersion.name}
                appVersion={getVersionText(currentAppVersion)}
                thumbnailUrl={currentAppVersion.thumbnailUrl}
                isMarketplace={application.isMarketplace}
              />
            </Paper>
          );
        })}
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,

      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        borderRadius: 0,
      },
    },

    newApplicationIcon: {
      color: theme.palette.progress.contrastText,
      backgroundColor: theme.palette.progress.main,
      borderRadius: 100,
      boxShadow: theme.shadows[1],
    },

    actions: {
      marginBottom: theme.spacing(1),

      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },

    section: {
      padding: theme.spacing(0.5),
    },
  }),
);

export default ApplicationsPage;
