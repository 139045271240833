import { FC, useState, useEffect } from 'react';
import Heading1 from 'raydiant-elements/typography/Heading1';
import Heading2 from 'raydiant-elements/typography/Heading2';
import Link from 'raydiant-elements/core/Link';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Center from 'raydiant-elements/layout/Center';
import { useHistory } from 'react-router-dom';
import auth0 from '../clients/auth0';
import Page from '../components/Page';
import * as routes from '../routes';

interface LoginCallbackPageProps {}

const LoginCallbackPage: FC<LoginCallbackPageProps> = () => {
  const history = useHistory();

  // State

  const [didError, setDidError] = useState(false);

  // Effects

  // Get app state from auth0 redirect on load.
  useEffect(() => {
    (async () => {
      try {
        const { appState } = await auth0.handleRedirectCallback();
        const backTo = (appState && appState.backTo) || routes.home();
        history.replace(backTo);
      } catch (err: any) {
        setDidError(err);
        console.error(err);
      }
    })();
  }, [history]);

  // Render

  const searchParams = new URLSearchParams(history.location.search);
  const error = searchParams.get('error');

  return (
    <Page>
      <Center>
        {didError && (
          <>
            <Heading1>
              {error === 'unauthorized'
                ? 'Your account has been suspended.'
                : 'Oops! Something went wrong.'}
            </Heading1>
            <Heading2>
              Please contact{' '}
              <Link href="mailto:support@raydiant.com">
                support@raydiant.com
              </Link>
              .
            </Heading2>
          </>
        )}
        {!didError && <CircularProgress size={30} />}
      </Center>
    </Page>
  );
};

export default LoginCallbackPage;
