import { useCallback, ReactElement } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvidedDragHandleProps,
  DropResult,
} from 'react-beautiful-dnd';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

interface DraggableListProps<T> {
  droppableId: string;
  items: T[];
  editable?: boolean;
  children: (
    dragHandleProps: DraggableProvidedDragHandleProps | null,
    item: T,
    index: number,
  ) => ReactElement<any>;
  onChange: (sourceIndex: number, destinationIndex: number) => void;
  onDragStart?: () => void;
}

function DraggableList<T>({
  droppableId,
  items,
  editable,
  onChange,
  onDragStart,
  children,
}: DraggableListProps<T>) {
  const classes = useStyles();

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      onChange(result.source.index, result.destination.index);
    },
    [onChange],
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId={droppableId}>
        {({ innerRef, droppableProps, placeholder }) => (
          <>
            <div {...droppableProps} ref={innerRef} className={classes.root}>
              {items.map((item, index) => (
                <Draggable
                  key={index}
                  draggableId={`${index}`}
                  index={index}
                  isDragDisabled={!editable}
                >
                  {({ innerRef, dragHandleProps, draggableProps }) => {
                    return (
                      <div ref={innerRef} {...draggableProps}>
                        {children(dragHandleProps, item, index)}
                      </div>
                    );
                  }}
                </Draggable>
              ))}
            </div>
            {placeholder}
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  }),
);

export default DraggableList;
