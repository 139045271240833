import { useQuery } from 'react-query';
import auth0 from '../clients/auth0';

export default function useRoles() {
  const query = useQuery<string[]>(
    'roles',
    async () => {
      // Ensure an auth token exists before retrieving the id token. This fixes
      // an issue where logging out then in with another account has an undefined
      // idToken until a full page refresh is made.
      await auth0.getTokenSilently();
      const idToken = await auth0.getIdTokenClaims();
      return idToken?.['https://dash.raydiant.com/roles'] || [];
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return query;
}
