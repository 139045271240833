import { FC } from 'react';
import Text from 'raydiant-elements/core/Text';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

interface AssetProps {
  width: number;
  height: number;
  url: string | null;
}

const Asset: FC<AssetProps> = ({ width, height, url }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      style={{ width, height, backgroundImage: `url(${url})` }}
    >
      {!url && (
        <Text xsmall muted>
          {width}x{height}
        </Text>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.input.background,
      backgroundRepeat: 'no-repeact',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  }),
);

export default Asset;
