import { FC, useState, useEffect } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Input from 'raydiant-elements/core/Input';
import Text from 'raydiant-elements/core/Text';
import {
  getPropertyAuthUrl,
  setPropertyAuthUrl,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationInputAuthUrlProps {}

const ApplicationInputAuthUrl: FC<ApplicationInputAuthUrlProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  // State

  const [authUrl, setAuthUrl] = useState('');

  // Callbacks

  const handleBlur = () => {
    if (!version) return;
    if (!selectedProperty) return;

    updateSelectedProperty(
      setPropertyAuthUrl(selectedProperty, authUrl),
      version.strings,
    );
  };

  // Effects

  // Set auth URL when selectedProperty changes.
  useEffect(() => {
    setAuthUrl(
      selectedProperty ? getPropertyAuthUrl(selectedProperty) || '' : '',
    );
  }, [selectedProperty]);

  // Render

  // TODO: Validate auth URL.
  // const authUrlError =
  //   selectedPropertyIndex !== null
  //     ? getInputAuthUrlError(selectedPropertyIndex)
  //     : null;

  return (
    <div>
      {isEditable && (
        <>
          <InputLabel>Auth URL</InputLabel>
          <Input
            type="text"
            value={authUrl}
            onChange={setAuthUrl}
            onBlur={handleBlur}
          />
        </>
      )}

      {!isEditable && selectedProperty && (
        <>
          <InputLabel>Auth URL</InputLabel>
          <Text>{getPropertyAuthUrl(selectedProperty)}</Text>
        </>
      )}
    </div>
  );
};

export default ApplicationInputAuthUrl;
