import { produce } from 'immer';
import slugify from 'slugify';
import { State, Actions } from './types';
import { createUpload } from './utilities';
import keys from './stateKeys';

const formatAppName = (str: string) =>
  slugify(str, { lower: true, strict: true });

const formatDescription = (str: string) => str.replace(/[\n\r]+/g, '').trim();

const reducer = produce((state: State, action: Actions) => {
  switch (action.type) {
    case 'updateName': {
      if (state.newApplication === null) {
        state.newApplication = { name: '' };
      }

      // The formatAppName function can return the same value for different names. In order to ensure
      // the UI updates with the formatted name we need to create a new newApplication reference each time.
      // This seems hacky but can't think of a better way.
      state.newApplication = {
        ...state.newApplication,
        name: formatAppName(action.name),
      };

      return;
    }

    case 'updateDeploymentId': {
      if (state.updatedApplication === null) {
        state.updatedApplication = { currentDeploymentId: '' };
      }

      state.updatedApplication.currentDeploymentId = action.versionId;
      return;
    }

    case 'newVersion': {
      const { latestVersion } = action;

      state.newVersion = {
        name: latestVersion.name,
        majorVersion: latestVersion.majorVersion,
        minorVersion: latestVersion.minorVersion,
        patchVersion: latestVersion.patchVersion,
        description: latestVersion.description,
        iconUrl: latestVersion.iconUrl,
        thumbnailUrl: latestVersion.thumbnailUrl,
        embeddedUrlFormat: latestVersion.embeddedUrlFormat || '',
        previewResolution: latestVersion.previewResolution,
        previewOrientation: latestVersion.previewOrientation,
        configurableDuration: latestVersion.configurableDuration,
        defaultDuration: latestVersion.defaultDuration,
        presentationProperties: latestVersion.presentationProperties,
        strings: latestVersion.strings,
        // TODO: Return hasDynamicThumbnails from application.currentAppVersion API response.
        hasDynamicThumbnails: false,
        helperLinkText: latestVersion.helperLinkText,
        helperLinkUrl: latestVersion.helperLinkUrl,
        scopes: latestVersion.scopes,
      };

      state.isEditable = true;
      state.isSelectingVersion = false;

      return;
    }

    case 'resetVersion': {
      state.newVersion = null;
      state.newVersionIconUpload = null;
      state.newVersionThumbnailUpload = null;
      state.isEditable = false;

      return;
    }

    case 'updateVersion': {
      if (state.newVersion === null) return;

      const { params } = action;

      if (params.name !== undefined) {
        state.newVersion.name = params.name;
      }
      if (params.majorVersion !== undefined) {
        state.newVersion.majorVersion = params.majorVersion;
      }
      if (params.minorVersion !== undefined) {
        state.newVersion.minorVersion = params.minorVersion;
      }
      if (params.patchVersion !== undefined) {
        state.newVersion.patchVersion = params.patchVersion;
      }
      if (params.description !== undefined) {
        state.newVersion.description = formatDescription(params.description);
      }
      if (params.embeddedUrlFormat !== undefined) {
        state.newVersion.embeddedUrlFormat = params.embeddedUrlFormat;
      }
      if (params.configurableDuration !== undefined) {
        state.newVersion.configurableDuration = params.configurableDuration;
      }
      if (params.defaultDuration !== undefined) {
        state.newVersion.defaultDuration = params.defaultDuration;
      }
      if (params.presentationProperties !== undefined) {
        state.newVersion.presentationProperties = params.presentationProperties;
      }
      if (params.strings !== undefined) {
        state.newVersion.strings = params.strings;
      }
      if (params.previewOrientation !== undefined) {
        state.newVersion.previewOrientation = params.previewOrientation;
      }
      if (params.previewResolution !== undefined) {
        state.newVersion.previewResolution = params.previewResolution;
      }
      if (params.hasDynamicThumbnails !== undefined) {
        state.newVersion.hasDynamicThumbnails = params.hasDynamicThumbnails;
      }
      if (params.helperLinkText !== undefined) {
        state.newVersion.helperLinkText = params.helperLinkText;
      }
      if (params.helperLinkUrl !== undefined) {
        state.newVersion.helperLinkUrl = params.helperLinkUrl;
      }
      if (params.scopes !== undefined) {
        state.newVersion.scopes = params.scopes;
      }

      // Set the application name from the app version name if not set and is
      // a a new application.
      if (params.name && action.isNewApp && !state.newApplication?.name) {
        if (state.newApplication === null) {
          state.newApplication = { name: '' };
        }
        state.newApplication.name = formatAppName(params.name);
      }

      return;
    }

    case 'selectVersion': {
      state.isSelectingVersion = true;
      state.isEditable = false;
      return;
    }

    case 'setThumbnailUpload': {
      state.newVersionThumbnailUpload = createUpload(action.file);
      return;
    }

    case 'setIconUpload': {
      state.newVersionIconUpload = createUpload(action.file);
      return;
    }

    case 'setSelectedProperty': {
      state.selectedPropertyIndex = action.index;
      return;
    }

    case 'updateSelectedProperty': {
      if (state.newVersion === null) return;
      if (state.selectedPropertyIndex === null) return;

      state.newVersion.strings = action.strings;
      state.newVersion.presentationProperties[state.selectedPropertyIndex] =
        action.property;

      return;
    }

    case 'saveVersionSuccess': {
      state.isEditable = false;
      state.newVersion = null;
      state.newApplication = null;
      state.newVersionThumbnailUpload = null;
      state.newVersionIconUpload = null;

      // Marketplace apps are not updated to the latest version automatically. Instead select the
      // new version in the version's dropdown, showing the submit for review CTA.
      if (action.application.isMarketplace) {
        state.isSelectingVersion = true;

        if (state.updatedApplication === null) {
          state.updatedApplication = { currentDeploymentId: '' };
        }

        state.updatedApplication.currentDeploymentId = action.version.id;
      } else {
        state.isSelectingVersion = false;
      }

      return;
    }

    case 'saveVersionFailed': {
      if (action.reason === 'duplicateApplicationName') {
        state.serverErrors[keys.applicationName()] = 'duplicateApplicationName';
      }
      return;
    }

    case 'saveApplicationSuccess': {
      state.updatedApplication = null;
      state.isSelectingVersion = false;
      return;
    }
  }
});

export default reducer;
