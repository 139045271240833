import ReactDOM from 'react-dom';
import ThemeProvider from 'raydiant-elements/core/ThemeProvider';
import theme from 'raydiant-elements/theme';
import { StylesProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';
import App from './App';

const history = createBrowserHistory();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Don't refetch on focus by default.
      refetchOnWindowFocus: false,
      // Don't rerender when data becomes stale by default.
      notifyOnChangePropsExclusions: ['isStale'],
      // Disable retries.
      retry: false,
    },
  },
});

ReactDOM.render(
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <App />
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  </StylesProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
