import {
  ApiClientError,
  CreateResourceACL,
  ResourceACL,
} from '@raydiant/api-client-js';
import useProtectedMutation from './useProtectedMutation';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';

export default function useCreateResourceACL() {
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<
    ResourceACL,
    ApiClientError,
    {
      params: CreateResourceACL;
    }
  >(async ({ params }) => await raydiant.createResourceACL(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('resourceACLs');
    },
  });

  return mutation;
}
