const stateKeys = {
  applicationName: () => 'applicationName',
  versionName: () => 'name',
  versionText: () => 'versionText',
  description: () => 'description',
  helperLinkText: () => 'helperLinkText',
  helperLinkUrl: () => 'helperLinkUrl',
  defaultDuration: () => 'defaultDuration',
  thumbnailUpload: () => 'thumbnailUpload',
  iconUpload: () => 'iconUpload',
  embeddedUrlFormat: () => 'embeddedUrlFormat',
  inputs: () => `inputs`,
  input: (index: number) => `inputs.${index}`,
  inputName: (index: number) => `inputs.${index}.name`,
  inputId: (index: number) => `inputs.${index}.id`,
  inputRequired: (index: number) => `inputs.${index}.required`,
  inputMultiline: (index: number) => `inputs.${index}.multiline`,
  inputMaxLength: (index: number) => `inputs.${index}.maxLength`,
  inputHelperText: (index: number) => `inputs.${index}.helperText`,
  inputHelperLink: (index: number) => `inputs.${index}.helperLink`,
  inputDefault: (index: number) => `inputs.${index}.default`,
  inputOptions: (index: number) => `inputs.${index}.options`,
  inputMin: (index: number) => `inputs.${index}.min`,
  inputMax: (index: number) => `inputs.${index}.max`,
};

export default stateKeys;
