import { ApplicationVersion } from '@raydiant/api-client-js';
import semverGreaterThan from 'semver/functions/gt';

interface VersionParts {
  majorVersion: number;
  minorVersion: number;
  patchVersion: number;
}

export const getVersionText = (parts: VersionParts) => {
  return `${parts.majorVersion}.${parts.minorVersion}.${parts.patchVersion}`;
};

export const isValidVersion = (versionText: string) => {
  return /^[0-9]+\.[0-9]+\.[0-9]+$/.test(versionText);
};

export const isHigherAppVersion = (
  versionText: string,
  appVersion: ApplicationVersion,
) => {
  return semverGreaterThan(versionText, getVersionText(appVersion));
};

export const isNewApp = (appVersion: ApplicationVersion) => {
  return !appVersion.applicationId;
};

export const getLatestVersion = (
  allVersions: ApplicationVersion[],
): ApplicationVersion | null => {
  return (
    allVersions.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))[0] ??
    null
  );
};

export const getVersionParts = (versionText: string): VersionParts => {
  const [majorVersion, minorVersion, patchVersion] = versionText.split('.');
  return {
    majorVersion: Number(majorVersion),
    minorVersion: Number(minorVersion),
    patchVersion: Number(patchVersion),
  };
};

export const isVersionApproved = (version: ApplicationVersion) => {
  return version.reviewStatus === 'approved';
};
