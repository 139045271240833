import { FC, useState, useEffect } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Input from 'raydiant-elements/core/Input';
import Text from 'raydiant-elements/core/Text';
import {
  getPropertyLogoutUrl,
  setPropertyLogoutUrl,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationInputLogoutUrlProps {}

const ApplicationInputLogoutUrl: FC<ApplicationInputLogoutUrlProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  // State

  const [logoutUrl, setLogoutUrl] = useState('');

  // Callbacks

  const handleBlur = () => {
    if (!version) return;
    if (!selectedProperty) return;

    updateSelectedProperty(
      setPropertyLogoutUrl(selectedProperty, logoutUrl),
      version.strings,
    );
  };

  // Effects

  // Set logout URL when selectedProperty changes.
  useEffect(() => {
    setLogoutUrl(
      selectedProperty ? getPropertyLogoutUrl(selectedProperty) || '' : '',
    );
  }, [selectedProperty]);

  // Render

  return (
    <div>
      {isEditable && (
        <>
          <InputLabel>Logout URL</InputLabel>
          <Input
            type="text"
            value={logoutUrl}
            onChange={setLogoutUrl}
            onBlur={handleBlur}
          />
        </>
      )}

      {!isEditable && selectedProperty && (
        <>
          <InputLabel>Logout URL</InputLabel>
          <Text>{getPropertyLogoutUrl(selectedProperty)}</Text>
        </>
      )}
    </div>
  );
};

export default ApplicationInputLogoutUrl;
