import { useContext } from 'react';
import LocationModalFormContext from './LocationModalFormContext';

export default function useLocationModalFormContext() {
  const context = useContext(LocationModalFormContext);
  if (!context) {
    throw new Error(
      'LocationModalFormContext must be wrapped by LocationModalFormContext.Provider',
    );
  }
  return context;
}
