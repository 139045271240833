import { FC, useState, useEffect, useMemo } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Input from 'raydiant-elements/core/Input';
import Link from 'raydiant-elements/core/Link';
import Text from 'raydiant-elements/core/Text';
import { setPropertyId, getPropertyId } from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

const inputIdHelperLink = '/docs/core-concepts/inputs';

interface ApplicationInputIDProps {}

const ApplicationInputID: FC<ApplicationInputIDProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    selectedPropertyIndex,
    updateSelectedProperty,
    getInputIdError,
  } = useApplicationFormContext();

  // State

  const [inputId, setInputId] = useState('');

  // Memoizers

  // We are keeping track of duplicate ids here instead of the formState validation
  // because we can't update the input id in formState if it's a duplicate or it will
  // end up overriding strings of the other property. We should remove strings at some
  // point to fix.
  const isDuplicateId = useMemo(() => {
    if (!version) return false;
    const otherProperty = version.presentationProperties.find(
      (p) => getPropertyId(p) === inputId,
    );
    return otherProperty && otherProperty !== selectedProperty;
  }, [version, inputId, selectedProperty]);

  // Callbacks

  const handleBlur = () => {
    if (!version) return;
    if (!selectedProperty) return;

    if (!isDuplicateId) {
      updateSelectedProperty(
        ...setPropertyId(selectedProperty, version.strings, inputId),
      );
    }
  };

  // Effects

  // Set inputId when selectedProperty changes.
  useEffect(() => {
    setInputId(selectedProperty ? getPropertyId(selectedProperty) : '');
  }, [selectedProperty]);

  // Render

  let idError: string | null =
    selectedPropertyIndex !== null
      ? getInputIdError(selectedPropertyIndex)
      : null;

  if (isEditable && isDuplicateId) {
    idError = 'duplicate';
  }

  return (
    <div>
      {isEditable && (
        <>
          <InputLabel error={!!idError}>Input ID</InputLabel>
          <Input
            type="text"
            value={inputId}
            error={!!idError}
            onChange={setInputId}
            onBlur={handleBlur}
          />
          <InputHelperText error={!!idError}>
            {idError === 'invalid' && 'Oops! Please enter a valid input ID.'}
            {idError === 'duplicate' && 'Oops! Input ID is already in use.'}
            {!idError &&
              "The unique ID to receive the input's value in the URL."}
            <div>
              <Link href={inputIdHelperLink} target="_blank">
                Learn more
              </Link>
            </div>
          </InputHelperText>
        </>
      )}

      {!isEditable && selectedProperty && (
        <>
          <InputLabel>Input ID</InputLabel>
          <Text>{getPropertyId(selectedProperty)}</Text>
        </>
      )}
    </div>
  );
};

export default ApplicationInputID;
