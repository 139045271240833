import { FC, useMemo, useState, useEffect, useRef } from 'react';
import Heading from 'raydiant-elements/core/Heading';
import Text from 'raydiant-elements/core/Text';
import Input from 'raydiant-elements/core/Input';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Select from 'raydiant-elements/core/Select';
import Link from 'raydiant-elements/core/Link';
import Column from 'raydiant-elements/layout/Column';
import Row from 'raydiant-elements/layout/Row';
import {
  ApplicationVersion,
  Application,
  Profile,
} from '@raydiant/api-client-js';
import config from '../../config';
import useProfile from '../../hooks/useProfile';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';
import {
  getVersionText,
  isVersionApproved,
  getVersionParts,
} from '../../utilities/appVersion';

interface ApplicationDetailsProps {}

const getVersionLabel = (
  version: ApplicationVersion,
  application: Application | null,
) => {
  if (application?.isMarketplace) {
    return `${getVersionText(version)}${
      !isVersionApproved(version) ? ' (Unapproved)' : ''
    }`;
  }

  return getVersionText(version);
};

const getPresentationBuilderUrl = (version: ApplicationVersion) => {
  return `${config.raydiantDashUrl}/presentations/new?applicationId=${
    version.applicationId
  }&version=${getVersionText(
    version,
  )}&saveTo=%2Flibrary&backToLabel=Back%20to%20Library`;
};

const getReviewRequestMailTo = (
  version: ApplicationVersion,
  profile: Profile,
) => {
  const subject = encodeURIComponent(
    `App Approval Request from ${profile.name}`,
  );

  const body = encodeURIComponent(`

-------- [Do not edit below] --------

${profile.name} has submitted ${version.name} (${getVersionText(
    version,
  )}) for approval. 

Open presentation builder: 
${getPresentationBuilderUrl(version)}`);

  return `mailto:appreview@raydiant.com?subject=${subject}&body=${body}`;
};

const ApplicationDetails: FC<ApplicationDetailsProps> = () => {
  const {
    application,
    version,
    allVersions,
    isEditable,
    updateDeploymentId,
    updateVersion,
    updateName,
    isSelectingVersion,
    isNewApp,
    selectVersion,
    getApplicationNameError,
    getVersionNameError,
    getVersionError,
    getDescriptionError,
    getDefaultDurationError,
    saveApplication,
    saveApplicationStatus,
  } = useApplicationFormContext();

  // Queries

  const { data: profile } = useProfile();

  // State

  const [applicationName, setApplicationName] = useState(
    application?.name ?? '',
  );

  const [versionName, setVersionName] = useState(version?.name ?? '');

  const [versionText, setVersionText] = useState(
    version ? getVersionText(version) : '',
  );

  const [description, setDescription] = useState(version?.description ?? '');

  const [defaultDuration, setDefaultDuration] = useState(
    String(version?.defaultDuration ?? ''),
  );

  const [durationMode, setDurationMode] = useState(
    version?.configurableDuration ? 'fixed' : 'dynamic',
  );

  // Refs

  const nameRef = useRef<HTMLInputElement | null>(null);

  // Memoizers

  const versionsSorted = useMemo(() => {
    return allVersions.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  }, [allVersions]);

  // Callbacks
  const handleUpdateVersion = () => {
    updateVersion(getVersionParts(versionText));
  };

  const handleDurationMode = (value: string) => {
    setDurationMode(value);
    updateVersion({ configurableDuration: value === 'fixed' });
  };

  // Effects

  // Reset state when application name changes.
  useEffect(() => {
    setApplicationName(application?.name ?? '');
  }, [application]);

  // Reset state when version changes.
  useEffect(() => {
    setVersionName(version?.name ?? '');
    setVersionText(version ? getVersionText(version) : '');
    setDescription(version?.description ?? '');
    setDefaultDuration(String(version?.defaultDuration ?? ''));
  }, [version]);

  // Focus app name when creating a new app.
  useEffect(() => {
    if (nameRef.current && isNewApp) {
      nameRef.current.focus();
    }
  }, [isNewApp]);

  // Render

  const canSetVersion =
    !!application &&
    !!version &&
    (!application.isMarketplace || isVersionApproved(version));

  const applicationNameError = getApplicationNameError();
  const versionNameError = getVersionNameError();
  const versionError = getVersionError();
  const descriptionError = getDescriptionError();
  const defaultDurationError = getDefaultDurationError();

  const deploymentId = application?.currentDeploymentId ?? '';

  return (
    <Column doubleMargin>
      <Heading>
        <Text
          ref={nameRef}
          editable={isEditable}
          value={versionName}
          error={!!versionNameError}
          onChange={setVersionName}
          onBlur={() => updateVersion({ name: versionName })}
        />
        {versionNameError && (
          <InputHelperText error>
            Oops! Please enter a valid name.
          </InputHelperText>
        )}
      </Heading>

      {isNewApp && (
        <div>
          <InputLabel error={!!applicationNameError}>App ID</InputLabel>
          <div style={{ maxWidth: 240 }}>
            <Input
              type="text"
              value={applicationName}
              error={!!applicationNameError}
              onChange={setApplicationName}
              onBlur={() => updateName(applicationName)}
            />
          </div>
          {applicationNameError === 'invalid' && (
            <InputHelperText error>
              Oops! Please enter a valid app ID.
            </InputHelperText>
          )}
          {applicationNameError === 'duplicateApplicationName' && (
            <InputHelperText error>
              Oops! App ID is already taken.
            </InputHelperText>
          )}
        </div>
      )}

      <div>
        <InputLabel error={!!versionError}>Version</InputLabel>
        {isEditable && (
          <>
            <div style={{ maxWidth: 80 }}>
              <Input
                type="text"
                value={versionText}
                error={!!versionError}
                onChange={setVersionText}
                onBlur={handleUpdateVersion}
              />
            </div>
            {versionError === 'invalid' && (
              <InputHelperText error>
                Oops! Please enter a valid version (ie. 1.12.0).
              </InputHelperText>
            )}
            {versionError === 'versionEqualOrLower' && version && (
              <InputHelperText error>
                Oops! Please enter a version higher than{' '}
                {getVersionText(version)}.
              </InputHelperText>
            )}
          </>
        )}

        {!isEditable && version && (
          <Row halfMargin center>
            {isSelectingVersion && (
              <Row center>
                <div>
                  <Select value={deploymentId} onChange={updateDeploymentId}>
                    {versionsSorted.map((version) => (
                      <option value={version.id}>
                        {getVersionLabel(version, application)}
                      </option>
                    ))}
                  </Select>
                </div>
                <Text small>
                  {canSetVersion && (
                    <Link
                      disabled={
                        !canSetVersion || saveApplicationStatus === 'loading'
                      }
                      onClick={saveApplication}
                    >
                      Set version
                    </Link>
                  )}
                </Text>
              </Row>
            )}

            {!isSelectingVersion && (
              <Row center>
                <Text>{getVersionText(version)}</Text>
                {allVersions.length > 1 && (
                  <Text small>
                    <Link onClick={selectVersion}>Change version</Link>
                  </Text>
                )}
              </Row>
            )}
          </Row>
        )}

        {isSelectingVersion && !canSetVersion && (
          <InputHelperText>
            {version && application && profile && (
              <Link href={getReviewRequestMailTo(version, profile)}>
                Contact <strong>appreview@raydiant.com</strong> to get this
                version approved.
              </Link>
            )}
          </InputHelperText>
        )}
      </div>

      <div>
        <InputLabel error={!!descriptionError}>Description</InputLabel>
        {isEditable && (
          <>
            <Input
              type="text"
              multiline
              multilineHeight={80}
              value={description}
              error={!!descriptionError}
              onChange={setDescription}
              onBlur={() => updateVersion({ description })}
            />
            <InputHelperText error={!!descriptionError}>
              {descriptionError === 'invalid' &&
                'Oops! Please enter a valid description.'}
              {!descriptionError &&
                'Description will be displayed in lower left corner of the presentation preview area'}
            </InputHelperText>
          </>
        )}

        {!isEditable && <Text>{description || <em>None</em>}</Text>}
      </div>

      <div>
        <InputLabel>Duration Mode</InputLabel>
        {isEditable && (
          <>
            <div style={{ maxWidth: 120 }}>
              <Select value={durationMode} onChange={handleDurationMode}>
                <option value="fixed">Fixed</option>
                <option value="dynamic">Dynamic</option>
              </Select>
            </div>
            <InputHelperText>
              <Link href="/docs/core-concepts/duration-mode" target="_blank">
                Learn more
              </Link>
            </InputHelperText>
          </>
        )}

        {!isEditable && (
          <Text>{durationMode === 'dynamic' ? 'Dynamic' : 'Fixed'}</Text>
        )}
      </div>

      {durationMode === 'fixed' && (
        <div>
          <InputLabel error={!!defaultDurationError}>
            Default Duration
          </InputLabel>
          {isEditable && (
            <>
              <div style={{ maxWidth: 80 }}>
                <Input
                  type="number"
                  min={5}
                  value={defaultDuration}
                  error={!!defaultDurationError}
                  onChange={setDefaultDuration}
                  onBlur={() =>
                    updateVersion({ defaultDuration: Number(defaultDuration) })
                  }
                />
              </div>
              <InputHelperText error={!!defaultDurationError}>
                {defaultDurationError === 'invalid' &&
                  'Oops! Please enter a valid duration.'}
                {!defaultDurationError &&
                  'The default duration (in seconds) of the app in a playlist'}
              </InputHelperText>
            </>
          )}

          {!isEditable && (
            <Text>
              {defaultDuration ? `${defaultDuration} seconds` : <em>None</em>}
            </Text>
          )}
        </div>
      )}
    </Column>
  );
};

export default ApplicationDetails;
