import { FC } from 'react';
import Heading from 'raydiant-elements/core/Heading';
import MultiSelectField from 'raydiant-elements/core/MultiSelectField';
import ThemeSelector from 'raydiant-elements/core/ThemeSelector';
import Column from 'raydiant-elements/layout/Column';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationScopesProps {}

const availableScopes = [
  { value: 'catalog:read', label: 'catalog:read' },
  { value: 'catalog:order:create', label: 'catalog:order:create' },
  { value: 'catalog:order:update', label: 'catalog:order:update' },
];

const ApplicationScopes: FC<ApplicationScopesProps> = () => {
  const { isEditable, version, updateVersion } = useApplicationFormContext();

  return (
    <Column>
      <Heading size={5} overline>
        Scopes
      </Heading>

      <ThemeSelector color="lightGrey">
        <MultiSelectField
          disabled={!isEditable}
          value={version?.scopes ?? []}
          onChange={(value) => {
            updateVersion({ scopes: value });
          }}
        >
          {availableScopes.map((scope) => (
            <MultiSelectField.Option
              key={scope.value}
              value={scope.value}
              label={scope.label}
              disabled={!isEditable}
            />
          ))}
        </MultiSelectField>
      </ThemeSelector>
    </Column>
  );
};

export default ApplicationScopes;
