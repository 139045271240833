import { FC, useCallback, useState, useEffect } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Button from 'raydiant-elements/core/Button';
import Input from 'raydiant-elements/core/Input';
import Text from 'raydiant-elements/core/Text';
import Row from 'raydiant-elements/layout/Row';
import Column from 'raydiant-elements/layout/Column';
import cn from 'classnames';
import { PropertyOption } from '../../utilities/properties';

interface ApplicationInputOptionItemProps {
  option: PropertyOption;
  defaultOptionValue?: string;
  editable?: boolean;
  expanded?: boolean;
  onExpand: () => void;
  onCollapse: () => void;
  onChange?: (option: PropertyOption) => void;
  onBlur?: () => void;
  onDelete?: () => void;
  onSetDefault?: () => void;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  labelName?: string;
}

const ApplicationInputOptionItem: FC<ApplicationInputOptionItemProps> = ({
  option,
  defaultOptionValue,
  editable = false,
  expanded = false,
  onExpand,
  onCollapse,
  onChange,
  onBlur,
  onDelete,
  onSetDefault,
  dragHandleProps,
  labelName = 'label',
}) => {
  const classes = useStyles();

  // State

  const [label, setLabel] = useState('');
  const [value, setValue] = useState('');

  // Callbacks

  const handleBlur = useCallback(() => {
    if (onChange) {
      onChange({ label, value });
    }
  }, [label, value, onChange]);

  const toggleExpanded = useCallback(() => {
    if (expanded) {
      onCollapse();
    } else {
      onExpand();
    }
  }, [expanded, onExpand, onCollapse]);

  // Effects

  // Update label state when option label changes.
  useEffect(() => {
    setLabel(option.label);
  }, [option.label]);

  // Update value state when option value changes.
  useEffect(() => {
    setValue(option.value);
  }, [option.value]);

  // Render

  const isDefaultOption = defaultOptionValue === option.value;
  const draggable = !!dragHandleProps;

  return (
    <ExpansionPanel
      elevation={0}
      expanded={expanded}
      classes={{
        root: classes.root,
        expanded: classes.expanded,
      }}
    >
      <ExpansionPanelSummary
        expandIcon={<ArrowRightIcon />}
        classes={{
          root: cn(classes.summary, editable && classes.editable),
          expanded: classes.expanded,
          expandIcon: classes.expandedIcon,
          content: classes.content,
          focused: classes.focused,
        }}
        onClick={toggleExpanded}
      >
        {editable && draggable && (
          <div
            className={classes.dragHandle}
            {...dragHandleProps}
            onMouseDown={(event) => {
              // TODO: This is a hack to collapse the item before reordering it since it's difficult to
              // re-select the selected item after re-ordering because we use indexes to as draggable ids.
              onCollapse();
              dragHandleProps?.onMouseDown(event);
            }}
          >
            <DragIndicatorIcon className={classes.buttonIcon} />
          </div>
        )}

        <Row inline center>
          <div
            className={cn(
              classes.label,
              !draggable && classes.nonDraggableLabel,
            )}
          >
            {option.label}
          </div>
          {isDefaultOption && <div className={classes.default}>default</div>}
        </Row>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails className={classes.details}>
        <Column doubleMargin>
          <Column>
            <div>
              <InputLabel>{labelName}</InputLabel>
              {editable && (
                <Input
                  type="text"
                  value={label}
                  onChange={setLabel}
                  onBlur={handleBlur}
                />
              )}
              {!editable && <Text>{option.label || <em>None</em>}</Text>}
            </div>

            <div>
              <InputLabel>Value</InputLabel>
              {editable && (
                <Input
                  type="text"
                  value={value}
                  onChange={setValue}
                  onBlur={handleBlur}
                />
              )}
              {!editable && <Text>{option.value || <em>None</em>}</Text>}
            </div>
          </Column>
          {editable && (
            <Row>
              <Button
                fullWidth
                color="destructive"
                icon={<DeleteForeverIcon />}
                label="Delete"
                onClick={onDelete}
              />

              {onSetDefault && (
                <Button
                  fullWidth
                  color="primary"
                  label="Set Default"
                  disabled={isDefaultOption}
                  onClick={onSetDefault}
                />
              )}
            </Row>
          )}
        </Column>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.borderRadius.sm,
      marginBottom: theme.spacing(0.25),
      backgroundColor: theme.palette.background.paper,

      '&$expanded': {
        margin: 0,
        marginBottom: `${theme.spacing(0.25)}px !important`, // Can't figure out a better way to override margin-bottom from MUI.
      },

      '&$focused': {},

      '&::before': {
        opacity: 0,
      },
    },

    expanded: {
      '& $expandedIcon': {
        transform: 'rotate(90deg)',
      },
    },

    focused: {},

    expandedIcon: {
      padding: theme.spacing(0.5, 1.5),
    },

    summary: {
      minHeight: 36,
      padding: theme.spacing(0, 1),
      // paddingLeft: theme.spacing(2),
      color: theme.palette.text.primary,

      '&$expanded': {
        minHeight: 36,
      },
    },

    editable: {
      paddingLeft: theme.spacing(0.5),
    },

    content: {
      padding: theme.spacing(0.5, 0),
      margin: 0,

      '&$expanded': {
        margin: 0,
      },
    },

    buttonIcon: {
      opacity: 0.5,
    },

    details: {
      color: theme.palette.text.primary,
      padding: theme.spacing(2, 3),
      paddingBottom: theme.spacing(3),
    },

    label: {
      fontWeight: 500,
    },

    nonDraggableLabel: {
      marginLeft: theme.spacing(1),
    },

    default: {
      fontStyle: 'italic',
    },

    dragHandle: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export default ApplicationInputOptionItem;
