import { FC } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Select from 'raydiant-elements/core/Select';
import Row from 'raydiant-elements/layout/Row';
import { PresentationPropertyFileType } from '@raydiant/api-client-js';
import {
  setPropertyFileType,
  getPropertyFileType,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationInputFileTypeProps {}

const fileTypes: Record<string, string> = {
  image: 'Image',
  video: 'Video',
  spreadsheet: 'Spreadsheet',
  pdf: 'PDF',
};

const ApplicationInputFileType: FC<ApplicationInputFileTypeProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  // Callbacks

  const handleFileTypeChange = (fileType: string) => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyFileType(
        selectedProperty,
        fileType as PresentationPropertyFileType,
      ),
      version.strings,
    );
  };

  // Render

  const fileType = !!selectedProperty
    ? getPropertyFileType(selectedProperty)
    : undefined;

  return (
    <div>
      <InputLabel>File Type</InputLabel>
      {isEditable && (
        <Select value={fileType} onChange={handleFileTypeChange}>
          {Object.entries(fileTypes).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </Select>
      )}

      {!isEditable && (
        <div>
          <Row halfMargin center>
            {fileType ? fileTypes[fileType] : 'None'}
          </Row>
        </div>
      )}
    </div>
  );
};

export default ApplicationInputFileType;
