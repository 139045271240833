import { FC } from 'react';
import MUIAddIcon from '@material-ui/icons/Add';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import cn from 'classnames';

interface AddIconProps {
  isOpen?: boolean;
}

const AddIcon: FC<AddIconProps> = ({ isOpen = false }) => {
  const classes = useStyles();
  return (
    <MUIAddIcon className={cn(classes.icon, isOpen && classes.iconOpen)} />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.progress.contrastText,
      backgroundColor: theme.palette.progress.main,
      borderRadius: 100,
      boxShadow: theme.shadows[1],
      transition: 'transform 0.15s ease-in',
    },

    iconOpen: {
      transform: 'rotate(45deg)',
    },
  }),
);

export default AddIcon;
