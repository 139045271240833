import { FC, useEffect, useState } from 'react';
import { PresentationProperty, Strings } from '@raydiant/api-client-js';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Input from 'raydiant-elements/core/Input';
import Text from 'raydiant-elements/core/Text';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import ToggleButtonGroup from 'raydiant-elements/core/ToggleButtonGroup';
import Link from 'raydiant-elements/core/Link';
import Row from 'raydiant-elements/layout/Row';
import Spacer from 'raydiant-elements/layout/Spacer';
import Column from 'raydiant-elements/layout/Column';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import {
  getPropertyOptions,
  setPropertyOptions,
  getPropertyDefault,
  setPropertyDefault,
  PropertyOption,
  setPropertyOptionsUrl,
  getPropertyOptionsUrl,
  setPropertyOptionsUrlHttpHeaders,
  getPropertyOptionsUrlHttpHeaders,
} from '../../utilities/properties';
import reorder from '../../utilities/reorder';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';
import AddIcon from '../../components/AddIcon';
import DraggableList from '../../components/DraggableList';
import ApplicationInputOptionItem from './ApplicationInputOptionItem';

const selectRemoteOptionsDocsLink =
  '/docs/core-concepts/inputs/select#remote-options';
const multiSelectRemoteOptionsDocsLink =
  '/docs/core-concepts/inputs/multi-select#remote-options';

interface ApplicationInputOptionsProps {
  multiple?: boolean;
  showRemoteOptions?: boolean;
}
type OptionsType = 'remote' | 'manual';

const getOptionsValueFromProperty = (
  property: PresentationProperty | null,
  strings: Strings | null | undefined,
) => {
  if (!property) return [];
  if (!strings) return [];

  return getPropertyOptions(property, strings);
};

const getHttpHeadersValueFromProperty = (
  property: PresentationProperty | null,
  strings: Strings | null | undefined,
) => {
  if (!property) return [];
  if (!strings) return [];

  return getPropertyOptionsUrlHttpHeaders(property, strings).map((h) => ({
    label: h.key,
    value: h.value,
  }));
};

const ApplicationInputOptions: FC<ApplicationInputOptionsProps> = ({
  multiple,
  showRemoteOptions,
}) => {
  const classes = useStyles();

  const {
    isEditable,
    version,
    selectedProperty,
    selectedPropertyIndex,
    updateSelectedProperty,
    getInputOptionsError,
  } = useApplicationFormContext();

  const options = getOptionsValueFromProperty(
    selectedProperty,
    version?.strings,
  );

  const httpHeaders = getHttpHeadersValueFromProperty(
    selectedProperty,
    version?.strings,
  );

  const optionsUrl = getPropertyOptionsUrl(selectedProperty);
  const isRemoteOptionsFormDirty = !!optionsUrl || httpHeaders?.length > 0;

  // State

  const [selectOptionIndex, setSelectedOptionIndex] = useState<number | null>(
    null,
  );

  const [selectHttpHeaderIndex, setSelectedHttpHeaderIndex] = useState<
    number | null
  >(null);

  const [optionsType, setOptionsType] = useState<OptionsType>(
    showRemoteOptions && isRemoteOptionsFormDirty ? 'remote' : 'manual',
  );

  // Callbacks

  const handleChangeOptionsUrl = (value: string) => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyOptionsUrl(selectedProperty, value),
      version.strings,
    );
  };

  const handleUpdateOptionsType = (type: OptionsType) => {
    if (!type) return;

    setOptionsType(type);
  };

  const handleOptionChange = (updatedOption: PropertyOption) => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyOptions(
        selectedProperty,
        options.map((opt, i) =>
          i === selectOptionIndex ? updatedOption : opt,
        ),
      ),
      version.strings,
    );
  };

  const handleHttpHeaderChange = (updatedHttpHeader: PropertyOption) => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyOptionsUrlHttpHeaders(
        selectedProperty,
        httpHeaders.map((h, i) =>
          i === selectHttpHeaderIndex ? updatedHttpHeader : h,
        ),
      ),
      version.strings,
    );
  };

  const handleAddOption = () => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyOptions(selectedProperty, [
        ...options,
        { label: '', value: '' },
      ]),
      version.strings,
    );

    setSelectedOptionIndex(options.length);
  };

  const handleAddHttpHeader = () => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyOptionsUrlHttpHeaders(selectedProperty, [
        ...httpHeaders,
        { label: '', value: '' },
      ]),
      version.strings,
    );

    setSelectedHttpHeaderIndex(httpHeaders.length);
  };

  const getDefaultOptionValue = (selectedProperty: PresentationProperty) => {
    const propertyDefault = getPropertyDefault(selectedProperty);

    if (!propertyDefault) {
      return;
    }

    return multiple ? propertyDefault[0] : propertyDefault;
  };

  const handleDeleteOption = () => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyOptions(
        selectedProperty,
        options.filter((_, i) => i !== selectOptionIndex),
      ),
      version.strings,
    );

    setSelectedOptionIndex(null);
  };

  const handleDeleteHttpHeader = () => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyOptionsUrlHttpHeaders(
        selectedProperty,
        httpHeaders.filter((_, i) => i !== selectHttpHeaderIndex),
      ),
      version.strings,
    );

    setSelectedHttpHeaderIndex(null);
  };

  const handleSetDefaultOption = () => {
    if (!selectedProperty) return;
    if (!version) return;
    if (selectOptionIndex === null) return;

    const defaultValue = multiple
      ? [options[selectOptionIndex].value]
      : options[selectOptionIndex].value;

    updateSelectedProperty(
      setPropertyDefault(selectedProperty, defaultValue),
      version.strings,
    );
    setSelectedOptionIndex(null);
  };

  const handleReorderOptions = (
    sourceIndex: number,
    destinationIndex: number,
  ) => {
    if (!selectedProperty) return;
    if (!version) return;

    updateSelectedProperty(
      setPropertyOptions(
        selectedProperty,
        reorder(options, sourceIndex, destinationIndex),
      ),
      version.strings,
    );
  };

  // Effects

  // Reset option type ("remote" or "manual") when property changes.
  useEffect(() => {
    setOptionsType(isRemoteOptionsFormDirty ? 'remote' : 'manual');
  }, [isRemoteOptionsFormDirty, selectedProperty]);

  // Render

  const optionsError =
    selectedPropertyIndex !== null
      ? getInputOptionsError(selectedPropertyIndex)
      : null;

  const remoteOptionsLearnMoreLink = multiple
    ? multiSelectRemoteOptionsDocsLink
    : selectRemoteOptionsDocsLink;

  return (
    <Column>
      {showRemoteOptions && (
        <div>
          <InputLabel>Option Type</InputLabel>

          {isEditable && (
            <div className={classes.optionsType}>
              <ToggleButtonGroup
                value={optionsType}
                onChange={(value) =>
                  handleUpdateOptionsType(value as OptionsType)
                }
                exclusive
              >
                <ToggleButtonGroup.Button value="manual">
                  Manual
                </ToggleButtonGroup.Button>
                <ToggleButtonGroup.Button value="remote">
                  Remote
                </ToggleButtonGroup.Button>
              </ToggleButtonGroup>
            </div>
          )}

          {!isEditable && <Text>{optionsType || <em>None</em>}</Text>}
        </div>
      )}

      {optionsType === 'manual' && (
        <div>
          <Row center className={classes.optionsLabel}>
            <InputLabel error={!!optionsError}>Options</InputLabel>
            <Spacer />
            {isEditable && (
              <ActionBar.Action icon={<AddIcon />} onClick={handleAddOption} />
            )}
          </Row>
          {isEditable && selectedProperty && (
            <>
              <DraggableList
                droppableId="options"
                items={options}
                editable
                onChange={handleReorderOptions}
                onDragStart={() => setSelectedOptionIndex(null)}
              >
                {(dragHandleProps, option, index) => (
                  <ApplicationInputOptionItem
                    key={index}
                    option={option}
                    editable
                    defaultOptionValue={getDefaultOptionValue(selectedProperty)}
                    expanded={selectOptionIndex === index}
                    onExpand={() => setSelectedOptionIndex(index)}
                    onCollapse={() => setSelectedOptionIndex(null)}
                    onChange={handleOptionChange}
                    onDelete={handleDeleteOption}
                    onSetDefault={handleSetDefaultOption}
                    dragHandleProps={dragHandleProps}
                  />
                )}
              </DraggableList>

              <InputHelperText error={!!optionsError}>
                {optionsError === 'invalid' &&
                  'Oops! Please enter valid options.'}
              </InputHelperText>
            </>
          )}

          {!isEditable && selectedProperty && (
            <div>
              {options.map((option, index) => (
                <ApplicationInputOptionItem
                  key={index}
                  option={option}
                  defaultOptionValue={getDefaultOptionValue(selectedProperty)}
                  expanded={selectOptionIndex === index}
                  onExpand={() => setSelectedOptionIndex(index)}
                  onCollapse={() => setSelectedOptionIndex(null)}
                />
              ))}
            </div>
          )}
        </div>
      )}

      {optionsType === 'remote' && (
        <>
          <div>
            <InputLabel>Options URL</InputLabel>

            {isEditable && (
              <>
                <div>
                  <Input
                    type="text"
                    value={optionsUrl}
                    onChange={handleChangeOptionsUrl}
                  />
                  <InputHelperText>
                    Options URL must include the protocol (https or http).
                    <br />
                    <Link href={remoteOptionsLearnMoreLink} target="_blank">
                      Learn more
                    </Link>
                  </InputHelperText>
                </div>
              </>
            )}

            {!isEditable && <Text>{optionsUrl || <em>None</em>}</Text>}
          </div>

          <div>
            <Row center className={classes.httpHeadersLabel}>
              <InputLabel>HTTP Headers</InputLabel>
              <Spacer />
              {isEditable && (
                <ActionBar.Action
                  icon={<AddIcon />}
                  onClick={handleAddHttpHeader}
                />
              )}
            </Row>
            {isEditable &&
              selectedProperty &&
              httpHeaders.map((option, index) => (
                <ApplicationInputOptionItem
                  key={index}
                  option={option}
                  editable
                  expanded={selectHttpHeaderIndex === index}
                  onExpand={() => setSelectedHttpHeaderIndex(index)}
                  onCollapse={() => setSelectedHttpHeaderIndex(null)}
                  onChange={handleHttpHeaderChange}
                  onDelete={handleDeleteHttpHeader}
                  labelName="key"
                />
              ))}

            {!isEditable && selectedProperty && (
              <div>
                {httpHeaders.map((option, index) => (
                  <ApplicationInputOptionItem
                    key={index}
                    option={option}
                    expanded={selectHttpHeaderIndex === index}
                    onExpand={() => setSelectedHttpHeaderIndex(index)}
                    onCollapse={() => setSelectedHttpHeaderIndex(null)}
                    labelName="key"
                  />
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </Column>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionsLabel: {
      marginBottom: theme.spacing(1),
    },
    httpHeadersLabel: {
      marginBottom: theme.spacing(1),
    },
    optionsType: {
      marginTop: -1 * theme.spacing(1),
    },
  }),
);

export default ApplicationInputOptions;
