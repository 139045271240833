import { Profile, ApiClientError } from '@raydiant/api-client-js';
import raydiant from '../clients/raydiant';
import useProtectedQuery from './useProtectedQuery';

export default function useApplications() {
  const query = useProtectedQuery<Profile, ApiClientError>('profile', () =>
    raydiant.getProfile(),
  );

  return query;
}
