import { CreateApplicationVersion, Application } from '@raydiant/api-client-js';
import raydiant from '../../clients/raydiant';
import { Upload, CreateApplication } from './types';

export interface CreateApplicationVersionOptions {
  newVersion: CreateApplicationVersion;
  newVersionIconUpload: Upload | null;
  newVersionThumbnailUpload: Upload | null;
  newApplication: CreateApplication | null;
  savedApplication?: Application;
}

export default async function createApplicationVersion({
  newVersion,
  newVersionThumbnailUpload,
  newVersionIconUpload,
  newApplication,
  savedApplication,
}: CreateApplicationVersionOptions) {
  let application: Application | null = null;

  // Create the application if one doesn't exist.
  if (savedApplication) {
    application = savedApplication;
  } else {
    if (!newApplication) {
      throw new Error('createApplicationVersion is missing newApplication');
    }
    application = await raydiant.createApplication(newApplication);
  }

  const params: CreateApplicationVersion = { ...newVersion };
  // Set thumbnailUrl to undefined to tell the API to create a new upload URL.
  if (newVersionThumbnailUpload) {
    params.thumbnailUrl = undefined;
  }
  // Set iconUrl to undefined to tell the API to create a new upload URL.
  if (newVersionIconUpload) {
    params.iconUrl = undefined;
  }

  const version = await raydiant.createApplicationVersion(
    application.id,
    params,
  );
  const shouldUploadThumbnail =
    newVersionThumbnailUpload && version.thumbnailUrl;
  const shouldUploadIcon = newVersionIconUpload && version.iconUrl;

  // Set app deployment id to new application version if it's not a marketplace app.
  // Marketplace app versions must be approved before they can be set as the current
  // deployment id of the app.
  if (!application.isMarketplace) {
    await raydiant.updateApplication(application.id, {
      currentDeploymentId: version.id,
    });
  }

  if (shouldUploadIcon) {
    await uploadFile(version.iconUrl, newVersionIconUpload.file);
  }

  if (shouldUploadThumbnail) {
    await uploadFile(version.thumbnailUrl, newVersionThumbnailUpload.file);
  }

  // We need to refetch the application versions in order to get the final CloudFront URL of the icon
  // and thumbnail. The version returned by raydiant.createApplicationVersion has the S3 upload URLs, not
  // the CloudFront URLs.
  const allVersions = await raydiant.getApplicationVersions(application.id);
  const currentAppVersion = allVersions.find(
    (v) => v.id === version.id,
    allVersions,
  );

  if (!currentAppVersion) {
    throw new Error(
      'Failed to check icon and thumbnail, app version does not exist',
    );
  }

  if (shouldUploadIcon) {
    await waitForImageToExist(currentAppVersion.iconUrl);
  }

  if (shouldUploadThumbnail) {
    await waitForImageToExist(currentAppVersion.thumbnailUrl);
  }

  return { version: currentAppVersion, application };
}

const uploadFile = async (url: string, file: File) => {
  return await fetch(url, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  });
};

const waitForImageToExist = (imageUrl: string) =>
  new Promise<void>(async (resolve, reject) => {
    let attempts = 0;

    const checkImageExist = async () => {
      const imageExists = await doesImageExist(imageUrl);

      if (imageExists) {
        resolve();
      }

      attempts += 1;

      if (attempts < 10) {
        setTimeout(checkImageExist, attempts * 1000);
      } else {
        reject(
          new Error(
            `Failed to check ${imageUrl}, max number of attempts reached`,
          ),
        );
      }
    };

    setTimeout(checkImageExist, 500);
  });

const doesImageExist = (src: string) =>
  new Promise<boolean>((resolve) => {
    const image = new Image();
    image.onload = () => resolve(true);
    image.onerror = () => resolve(false);
    image.src = src;
  });
