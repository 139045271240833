import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Heading from 'raydiant-elements/core/Heading';
import Button from 'raydiant-elements/core/Button';
import Spacer from 'raydiant-elements/layout/Spacer';
import Row from 'raydiant-elements/layout/Row';
import Column from 'raydiant-elements/layout/Column';
import { ApplicationVersion, Application } from '@raydiant/api-client-js';
import Section from '../../components/Section';
import SectionBody from '../../components/SectionBody';
import SectionHeader from '../../components/SectionHeader';
import LoadingButton from '../../components/LoadingButton';
import MarketplaceIndicator from '../../components/MarketplaceIndicator';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';
import ApplicationDetails from './ApplicationDetails';
import ApplicationAssets from './ApplicationAssets';
import ApplicationScopes from './ApplicationScopes';
import ApplicationHelper from './ApplicationHelper';
import ApplicationSharing from './ApplicationSharing';
import ApplicationURL from './ApplicationURL';
import useRoles from '../../hooks/useRoles';
import useProfile from '../../hooks/useProfile';
import ApplicationPreviewSettings from './ApplicationPreviewSettings';

export interface ApplicationFormProps {
  onSave?: (
    version: ApplicationVersion | null,
    application: Application | null,
  ) => void;
}

const ApplicationForm: FC<ApplicationFormProps> = ({ onSave }) => {
  const { data: roles } = useRoles();
  const { data: profile } = useProfile();

  // Forms

  const {
    application,
    saveVersionStatus,
    isEditable,
    newVersion,
    resetVersion,
    isDirty,
    isNewApp,
    hasErrors,
    saveVersion,
  } = useApplicationFormContext();

  // Callbacks

  const handleSave = async () => {
    const [version, application] = await saveVersion();
    if (onSave) {
      onSave(version, application);
    }
  };

  // Render

  const shouldDisableSave = !isDirty || hasErrors;
  const shouldShowCancel = !isNewApp && saveVersionStatus !== 'loading';
  const hasDeveloperSharingRole = roles?.includes('developer_sharing');
  const isAuthor =
    profile && application?.resource
      ? profile.email === application.resource.profile.email
      : false;

  // Use the same role as the Product Catalog Input. Scopes are required when using the PC Input.
  // It might make sense to add a specific role later.
  const hasDeveloperScopesRole = roles?.includes('developer_internal');

  return (
    <Section>
      <SectionHeader>
        <Row>
          <Heading size={2} color="muted">
            App Details
          </Heading>
          {application?.isMarketplace && (
            <MarketplaceIndicator label="Live in the Marketplace" />
          )}
        </Row>
        <Spacer />
        <Row inline halfMargin>
          {isEditable && (
            <>
              {shouldShowCancel && (
                <Button onClick={resetVersion}>Cancel</Button>
              )}
              <LoadingButton
                key="loading"
                color="primary"
                label="Save"
                loadingLabel="Saving..."
                successLabel="Saved!"
                errorLabel="Failed to save"
                status={saveVersionStatus}
                disabled={shouldDisableSave}
                onClick={handleSave}
              />
            </>
          )}

          {/* Show the loading status of the button when we leave edit mode. This is
          a bit weird because we aren't rendering the button just the transition states. */}
          {!isEditable && saveVersionStatus !== 'idle' && (
            <LoadingButton
              key="loading"
              color="primary"
              label="Save"
              loadingLabel="Saving..."
              successLabel="Saved!"
              errorLabel="Failed to save"
              status={saveVersionStatus}
              disabled
              onClick={handleSave}
            />
          )}

          {!isEditable && saveVersionStatus === 'idle' && (
            <Button onClick={newVersion}>New Version</Button>
          )}
        </Row>
      </SectionHeader>
      <SectionBody maxWidth={1200}>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={4}>
            <Column doubleMargin>
              <ApplicationDetails />
              <ApplicationPreviewSettings />
              <ApplicationHelper />
              <ApplicationAssets />
              {!isEditable && hasDeveloperSharingRole && isAuthor && (
                <ApplicationSharing />
              )}
              {hasDeveloperScopesRole && <ApplicationScopes />}
            </Column>
          </Grid>
          <Grid item xs={12} sm={8}>
            <ApplicationURL />
          </Grid>
        </Grid>
      </SectionBody>
    </Section>
  );
};

export default ApplicationForm;
