import { useReducer, useCallback, useMemo } from 'react';
import {
  Application,
  ApplicationVersion,
  PresentationProperty,
  ForbiddenError,
} from '@raydiant/api-client-js';
import { useQueryClient } from 'react-query';
import { getLatestVersion } from '../../utilities/appVersion';
import useProtectedMutation from '../useProtectedMutation';
import useUpdateApplication from '../useUpdateApplication';
import { ContextValue } from './types';
import { isStateDirty, getErrors } from './utilities';
import reducer from './reducer';
import createApplicationVersion from './createApplicationVersion';
import createInitialState from './createInitialState';
import keys from './stateKeys';
import defaultVersion from './defaultVersion';
import defaultApplication from './defaultApplication';

export default function useApplicationForm(
  isNewApp: boolean,
  savedApplication?: Application,
  allVersions: ApplicationVersion[] = [],
): ContextValue {
  const queryClient = useQueryClient();

  // Reducers

  const [state, dispatch] = useReducer(reducer, createInitialState(isNewApp));

  // Mutations

  const {
    mutateAsync: saveVersionMutation,
    status: saveVersionStatus,
    reset: resetSaveVersionStatus,
  } = useProtectedMutation(createApplicationVersion, {
    onSuccess: () => {
      if (isNewApp) {
        // If we created a new app we need to reset instead of invalidate the
        // applications query. Reset will reset the status back to 'loading'
        // when fetched again whereas invalidate won't reset the status and remains
        // at 'success', causing empty state to show instead of a loading screen while
        // the new app is fetched.
        queryClient.resetQueries('applications');
      } else {
        queryClient.invalidateQueries('applications');
      }
    },
  });

  const {
    mutateAsync: saveApplicationMutation,
    status: saveApplicationStatus,
    reset: resetSaveApplicationStatus,
  } = useUpdateApplication();

  // Memoizers

  const application = useMemo<Application | null>(() => {
    const currentDeploymentId = state.updatedApplication?.currentDeploymentId;
    const currentAppVersion = allVersions.find(
      (v) => v.id === currentDeploymentId,
    );

    return {
      ...(savedApplication || defaultApplication),
      ...(isNewApp ? state.newApplication : state.updatedApplication),
      currentAppVersion:
        currentAppVersion ||
        savedApplication?.currentAppVersion ||
        defaultApplication.currentAppVersion,
    };
  }, [
    savedApplication,
    isNewApp,
    state.newApplication,
    state.updatedApplication,
    allVersions,
  ]);

  const version = useMemo<ApplicationVersion | null>(() => {
    const newVersion = state.newVersion;
    if (!newVersion) {
      if (application) {
        return application.currentAppVersion;
      } else {
        return null;
      }
    }

    return {
      ...defaultVersion,
      ...newVersion,
    };
  }, [application, state.newVersion]);

  const thumbnailUrl = useMemo<string | null>(() => {
    if (state.isEditable && state.newVersionThumbnailUpload) {
      return state.newVersionThumbnailUpload.previewUrl;
    }
    return version?.thumbnailUrl ?? null;
  }, [
    state.isEditable,
    state.newVersionThumbnailUpload,
    version?.thumbnailUrl,
  ]);

  const iconUrl = useMemo<string | null>(() => {
    if (state.isEditable && state.newVersionIconUpload) {
      return state.newVersionIconUpload.previewUrl;
    }
    return version?.iconUrl ?? null;
  }, [state.isEditable, state.newVersionIconUpload, version?.iconUrl]);

  const selectedProperty = useMemo<PresentationProperty | null>(() => {
    if (state.selectedPropertyIndex === null) {
      return null;
    }

    if (version === null) {
      return null;
    }

    return version.presentationProperties[state.selectedPropertyIndex] ?? null;
  }, [state.selectedPropertyIndex, version]);

  // Callbacks

  const updateName = useCallback<ContextValue['updateName']>((name) => {
    dispatch({ type: 'updateName', name });
  }, []);

  const updateDeploymentId = useCallback<ContextValue['updateDeploymentId']>(
    (versionId) => {
      dispatch({ type: 'updateDeploymentId', versionId });
    },
    [],
  );

  const newVersion = useCallback<ContextValue['newVersion']>(() => {
    if (!savedApplication) return;
    const latestVersion = getLatestVersion(allVersions);
    if (!latestVersion) return;
    dispatch({ type: 'newVersion', latestVersion });
  }, [savedApplication, allVersions]);

  const resetVersion = useCallback<ContextValue['resetVersion']>(() => {
    dispatch({ type: 'resetVersion' });
  }, []);

  const updateVersion = useCallback<ContextValue['updateVersion']>(
    (params) => {
      dispatch({ type: 'updateVersion', params, isNewApp });
    },
    [isNewApp],
  );

  const selectVersion = useCallback<ContextValue['selectVersion']>(() => {
    dispatch({ type: 'selectVersion' });
  }, []);

  const setThumbnailUpload = useCallback<ContextValue['setThumbnailUpload']>(
    (file) => {
      dispatch({ type: 'setThumbnailUpload', file });
    },
    [],
  );

  const setIconUpload = useCallback<ContextValue['setIconUpload']>((file) => {
    dispatch({ type: 'setIconUpload', file });
  }, []);

  const setSelectedProperty = useCallback<ContextValue['setSelectedProperty']>(
    (index) => {
      dispatch({ type: 'setSelectedProperty', index });
    },
    [],
  );

  const updateSelectedProperty = useCallback<
    ContextValue['updateSelectedProperty']
  >((property, strings) => {
    dispatch({ type: 'updateSelectedProperty', property, strings });
  }, []);

  // Errors

  const errors = getErrors(state, allVersions);
  const hasErrors = Object.keys(errors).length > 0;

  const getApplicationNameError: ContextValue['getApplicationNameError'] = () => {
    return (
      errors[keys.applicationName()] ??
      state.serverErrors[keys.applicationName()] ??
      null
    );
  };

  const getVersionNameError: ContextValue['getVersionNameError'] = () => {
    return errors[keys.versionName()] ?? null;
  };

  const getVersionError: ContextValue['getVersionError'] = () => {
    return errors[keys.versionText()] ?? null;
  };

  const getDescriptionError: ContextValue['getDescriptionError'] = () => {
    return errors[keys.description()] ?? null;
  };

  const getHelperLinkTextError: ContextValue['getHelperLinkTextError'] = () => {
    return errors[keys.helperLinkText()] ?? null;
  };

  const getHelperLinkUrlError: ContextValue['getHelperLinkUrlError'] = () => {
    return errors[keys.helperLinkUrl()] ?? null;
  };

  const getDefaultDurationError: ContextValue['getDefaultDurationError'] = () => {
    return errors[keys.defaultDuration()] ?? null;
  };

  const getThumbnailError: ContextValue['getThumbnailError'] = () => {
    return errors[keys.thumbnailUpload()] ?? null;
  };

  const getIconError: ContextValue['getIconError'] = () => {
    return errors[keys.iconUpload()] ?? null;
  };

  const getUrlTemplateError: ContextValue['getUrlTemplateError'] = () => {
    return errors[keys.embeddedUrlFormat()] ?? null;
  };

  const getInputError: ContextValue['getInputError'] = (index) => {
    return errors[keys.input(index)] ?? null;
  };

  const getInputNameError: ContextValue['getInputNameError'] = (index) => {
    return errors[keys.inputName(index)] ?? null;
  };

  const getInputIdError: ContextValue['getInputIdError'] = (index) => {
    return errors[keys.inputId(index)] ?? null;
  };

  const getInputMaxLengthError: ContextValue['getInputMaxLengthError'] = (
    index,
  ) => {
    return errors[keys.inputMaxLength(index)] ?? null;
  };

  const getInputHelperTextError: ContextValue['getInputHelperTextError'] = (
    index,
  ) => {
    return errors[keys.inputHelperText(index)] ?? null;
  };

  const getInputHelperLinkError: ContextValue['getInputHelperLinkError'] = (
    index,
  ) => {
    return errors[keys.inputHelperLink(index)] ?? null;
  };

  const getInputOptionsError: ContextValue['getInputOptionsError'] = (
    index,
  ) => {
    return errors[keys.inputOptions(index)] ?? null;
  };

  const saveVersion: ContextValue['saveVersion'] = async () => {
    let version: ApplicationVersion | null = null;
    let application: Application | null = null;

    if (!hasErrors && state.newVersion) {
      try {
        ({ version, application } = await saveVersionMutation({
          newVersion: state.newVersion,
          newVersionThumbnailUpload: state.newVersionThumbnailUpload,
          newVersionIconUpload: state.newVersionIconUpload,
          newApplication: state.newApplication,
          savedApplication,
        }));

        // TODO: I think we want to do this later, after the loading button has finished displaying "success" state.
        resetSaveVersionStatus();

        dispatch({ type: 'saveVersionSuccess', version, application });
      } catch (err) {
        // Assume a forbidden error means the application name already exists.
        if (err instanceof ForbiddenError) {
          dispatch({
            type: 'saveVersionFailed',
            reason: 'duplicateApplicationName',
          });
        }
        console.error(err);
      }
    }

    return [version, application];
  };

  const saveApplication: ContextValue['saveApplication'] = async () => {
    let application: Application | null = null;

    if (savedApplication && state.updatedApplication) {
      try {
        application = await saveApplicationMutation({
          appId: savedApplication.id,
          params: state.updatedApplication,
        });

        resetSaveApplicationStatus();

        dispatch({ type: 'saveApplicationSuccess', application });
      } catch (err) {
        console.error(err);
      }
    } else if (savedApplication) {
      // If there's no updatedApplication then that likely means the user tried to set the version
      // to the same version as the current deployment id. We don't want to make any API calls but
      // still want to reset the state.
      dispatch({
        type: 'saveApplicationSuccess',
        application: savedApplication,
      });
    }

    return application;
  };

  return {
    application,
    updateName,
    updateDeploymentId,
    saveApplication,
    saveApplicationStatus,
    version,
    newVersion,
    resetVersion,
    updateVersion,
    saveVersion,
    saveVersionStatus,
    thumbnailUrl,
    setThumbnailUpload,
    iconUrl,
    setIconUpload,
    allVersions,
    isNewApp,
    isEditable: state.isEditable,
    isSelectingVersion: state.isSelectingVersion,
    selectVersion,
    selectedPropertyIndex: state.selectedPropertyIndex,
    selectedProperty,
    setSelectedProperty,
    updateSelectedProperty,
    isDirty: isStateDirty(state),
    hasErrors,
    // Errors
    getApplicationNameError,
    getVersionNameError,
    getVersionError,
    getDescriptionError,
    getHelperLinkTextError,
    getHelperLinkUrlError,
    getDefaultDurationError,
    getThumbnailError,
    getIconError,
    getUrlTemplateError,
    getInputError,
    getInputNameError,
    getInputIdError,
    getInputMaxLengthError,
    getInputHelperTextError,
    getInputHelperLinkError,
    getInputOptionsError,
  };
}
