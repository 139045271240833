import { FC } from 'react';
import Heading from 'raydiant-elements/core/Heading';
import Column from 'raydiant-elements/layout/Column';
import ApplicationInputID from './ApplicationInputID';
import ApplicationInputRequired from './ApplicationInputRequired';
import ApplicationInputAuthUrl from './ApplicationInputAuthUrl';
import ApplicationInputVerifyUrl from './ApplicationInputVerifyUrl';
import ApplicationInputLogoutUrl from './ApplicationInputLogoutUrl';
import ApplicationInputVerifyQsParam from './ApplicationInputVerifyQsParam';
import ApplicationInputLogoutQsParam from './ApplicationInputLogoutQsParam';
import ApplicationInputHelperText from './ApplicationInputHelperText';
import ApplicationInputHelperLink from './ApplicationInputHelperLink';
import ApplicationInputBackgroundColor from './ApplicationInputBackgroundColor';
import ApplicationInputForegroundColor from './ApplicationInputForegroundColor';
import ApplicationInputIconUrl from './ApplicationInputIconUrl';
import ApplicationInputOAuthProvider from './ApplicationInputOAuthProvider';

interface ApplicationInputTypeOAuthProps {}

const ApplicationInputTypeOAuth: FC<ApplicationInputTypeOAuthProps> = () => {
  return (
    <Column doubleMargin>
      <Column>
        <Heading overline size={5}>
          Properties
        </Heading>
        <ApplicationInputID />
        <ApplicationInputOAuthProvider />
        <ApplicationInputAuthUrl />
        <ApplicationInputVerifyUrl />
        <ApplicationInputVerifyQsParam />
        <ApplicationInputLogoutUrl />
        <ApplicationInputLogoutQsParam />
      </Column>

      <Column>
        <Heading overline size={5}>
          Validation
        </Heading>
        <ApplicationInputRequired />
      </Column>

      <Column>
        <Heading overline size={5}>
          Appearance
        </Heading>
        <ApplicationInputBackgroundColor />
        <ApplicationInputForegroundColor />
        <ApplicationInputIconUrl />
        <ApplicationInputHelperText />
        <ApplicationInputHelperLink />
      </Column>
    </Column>
  );
};

export default ApplicationInputTypeOAuth;
