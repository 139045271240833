import { ApiClientError } from '@raydiant/api-client-js';
import raydiantMenu, {
  GetLocationMenusResponse,
} from '../clients/raydiantMenu';
import useProtectedQuery from './useProtectedQuery';

export default function useLocationMenus(id: string) {
  const query = useProtectedQuery<GetLocationMenusResponse, ApiClientError>(
    ['locationMenus', id],
    async () => {
      const data = await raydiantMenu.getLocationMenus({ id });

      data.sort((a, b) => {
        if (a.name === null) {
          return 1;
        }

        if (b.name === null) {
          return -1;
        }

        return a.name.localeCompare(b.name);
      });

      return data;
    },
    { enabled: !!id },
  );

  return query;
}
