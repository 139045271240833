import auth0 from '../clients/auth0';

// Allow setting access token with a query parameter. This is useful to bypass
// Auth0 in development.
let accessTokenOverride: string | null = null;
const urlParams = new URLSearchParams(window.location.search);
if (urlParams.get('token')) {
  accessTokenOverride = urlParams.get('token');
  urlParams.delete('token');
}

export const getAccessToken = async () => {
  if (accessTokenOverride) {
    return accessTokenOverride;
  }

  return await auth0.getTokenSilently();
};

export default getAccessToken;
