import { DomainRole, Profile, Resource } from '@raydiant/api-client-js';

const rolesByLevel: DomainRole[] = [
  'restricted',
  'standard',
  'admin',
  'superadmin',
];

const hasRoleOrHigher = (lowerBound: DomainRole, profile: Profile) => {
  const lowerBoundLevel = rolesByLevel.indexOf(lowerBound);
  const profileLevel = rolesByLevel.indexOf(profile.domainRole);
  return profileLevel !== -1 && profileLevel >= lowerBoundLevel;
};

export const canEditResource = (profile: Profile, resource: Resource) => {
  // Profile owns the resource
  if (resource.profile.id === profile.id) {
    return true;
  }
  // Profile is a domain admin and resource exists in domain.
  if (
    profile.domainId &&
    hasRoleOrHigher('admin', profile) &&
    profile.domainId === resource.profile.domainId
  ) {
    return true;
  }
  // Profile has been granted access.
  if (
    resource.r.resourceACLs.some(
      (acl) =>
        // Explicit access has been granted to this profile.
        (acl.grantProfileId === profile.id && acl.updateGrant) ||
        // Access has been granted to this profile via the grant_scope.
        (acl.grantProfileId === null && acl.updateGrant),
    )
  ) {
    return true;
  }
  // Profile does not have access.
  return false;
};
