import { FC, useRef, useState } from 'react';
import MUIExitToAppIcon from '@material-ui/icons/ExitToApp';
import MUIVpnKeyIcon from '@material-ui/icons/VpnKey';
import { Redirect } from 'react-router-dom';
import Center from 'raydiant-elements/layout/Center';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import { createStyles, makeStyles, Theme } from 'raydiant-elements/styles';
import Paper from 'raydiant-elements/core/Paper';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import AddIcon from '@material-ui/icons/Add';
import Page from '../components/Page';
import LocationCard from '../components/LocationCard';
import useLocations from '../hooks/useLocations';
import useRoles from '../hooks/useRoles';
import useApiKeys from '../hooks/useApiKeys';
import useApiKey from '../hooks/useApiKey';
import * as routes from '../routes';
import ApiKeyCard from '../components/ApiKeyCard';
import useLocationModalForm, {
  LocationModalFormProvider,
} from '../hooks/useLocationModalForm';
import LocationModalForm from '../components/LocationModalForm';
import Popover from '@material-ui/core/Popover';
import PopoverListItem from '../components/PopoverListItem';
import Text from 'raydiant-elements/core/Text';
import useApiKeyModalForm, {
  ApiKeyModalFormProvider,
} from '../hooks/useApiKeyModalForm';
import ApiKeyModalForm from '../components/ApiKeyModalForm';
import useLocation from '../hooks/useLocation';

interface ApiKeysPageProps {}

const ApiKeysPage: FC<ApiKeysPageProps> = () => {
  const classes = useStyles();

  // Refs

  const createNewButtonRef = useRef<HTMLElement | null>(null);

  // State

  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isApiKeyModalOpen, setIsApiKeyModalOpen] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [selectedApiKeyId, setSelectedApiKeyId] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  // Queries

  const locations = useLocations();
  const location = useLocation(selectedLocationId);
  const locationForm = useLocationModalForm();
  const apiKeyForm = useApiKeyModalForm();
  const apiKeys = useApiKeys();
  const apiKey = useApiKey(selectedApiKeyId);
  const { isLoading: isLoadingRoles, data: roles } = useRoles();

  // Callbacks

  const closeAllModals = () => {
    closeLocationModal();
    closeApiKeyModal();
  };

  const closeLocationModal = () => {
    setIsLocationModalOpen(false);
    setSelectedLocationId('');
  };

  const closeApiKeyModal = () => {
    setIsApiKeyModalOpen(false);
    setSelectedApiKeyId('');
  };

  const openLocationModal = (locationId: string) => {
    closeAllModals();

    setIsLocationModalOpen(true);
    setSelectedLocationId(locationId);
  };

  const openApiKeyModal = (apiKeyId: string) => {
    closeAllModals();

    setIsApiKeyModalOpen(true);
    setSelectedApiKeyId(apiKeyId);
  };

  // Render

  const isLoading = locations.isLoading || apiKeys.isLoading;
  const hasDeveloperRole = roles?.includes('developer');
  const hasApiKeysRole = roles?.includes('developer_api_keys');
  if (!isLoadingRoles && (!hasDeveloperRole || !hasApiKeysRole)) {
    return <Redirect to={routes.notAllowed()} />;
  }
  const shouldDisableAddApiKey = !locations.data || locations.data.length === 0;

  if (isLoading) {
    return (
      <Page>
        <Center>
          <CircularProgress size={30} />
        </Center>
      </Page>
    );
  }

  if (locations.error || locations.error) {
    // TODO: Better error handling.
    return (
      <Page>
        <Center>Oops! Something went wrong.</Center>
      </Page>
    );
  }

  const renderActionBar = () => (
    <div className={classes.actions}>
      <Paper color="light" className={classes.section}>
        <ActionBar>
          <ActionBar.Action
            icon={<AddIcon className={classes.newApplicationIcon} />}
            label="Create New..."
            onClick={() => {
              setIsPopoverOpen(true);
            }}
            ref={createNewButtonRef}
          />
        </ActionBar>
      </Paper>
      <Popover
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={createNewButtonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.paper,
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: 'left',
        }}
      >
        <PopoverListItem
          className={classes.popoverListItem}
          onClick={() => {
            openLocationModal('');
            setIsPopoverOpen(false);
          }}
        >
          <Text>API Source</Text>
          <MUIExitToAppIcon />
        </PopoverListItem>
        <PopoverListItem
          className={classes.popoverListItem}
          disabled={shouldDisableAddApiKey}
          onClick={() => {
            openApiKeyModal('');
            setIsPopoverOpen(false);
          }}
        >
          <Text>API Key</Text>
          <MUIVpnKeyIcon />
        </PopoverListItem>
      </Popover>
    </div>
  );

  let content = (
    <div className={classes.container}>
      {renderActionBar()}
      {locations.data?.map((l) => {
        return (
          <LocationCard
            key={l.id}
            sourceName={l.sourceName}
            disabled={!l.connectorLocationId}
            restaurantName={l.restaurantName}
            name={l.name}
            totalMenus={l.totalMenus}
            onClick={() => {
              openLocationModal(l.id);
            }}
          />
        );
      })}
      {apiKeys.data?.map((aK) => {
        return (
          <ApiKeyCard
            key={aK.id}
            name={aK.name}
            token={aK.token}
            onClick={() => {
              openApiKeyModal(aK.id);
            }}
          />
        );
      })}
    </div>
  );

  if (isLoading) {
    content = (
      <Center>
        <CircularProgress size={30} />
      </Center>
    );
  }

  return (
    <LocationModalFormProvider value={locationForm}>
      <ApiKeyModalFormProvider value={apiKeyForm}>
        <Page>{content}</Page>
        <LocationModalForm
          open={isLocationModalOpen}
          onClose={closeLocationModal}
          location={location?.data}
        />
        <ApiKeyModalForm
          open={isApiKeyModalOpen}
          onClose={closeApiKeyModal}
          apiKey={apiKey?.data}
        />
      </ApiKeyModalFormProvider>
    </LocationModalFormProvider>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,

      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        borderRadius: 0,
      },
    },

    paper: {
      top: 3,
      minWidth: 207,
    },

    popoverListItem: {
      padding: theme.spacing(0.75, 2),
      display: 'flex',
      justifyContent: 'space-between',
    },

    popoverIcon: {
      fontSize: 20,
      width: 20,
      flexShrink: 0,
      marginRight: theme.spacing(0.75),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    newApplicationIcon: {
      color: theme.palette.progress.contrastText,
      backgroundColor: theme.palette.progress.main,
      borderRadius: 100,
      boxShadow: theme.shadows[1],
    },

    actions: {
      marginBottom: theme.spacing(1),

      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },

    section: {
      padding: theme.spacing(0.5),
    },
  }),
);

export default ApiKeysPage;
