import { FC, useCallback, useEffect, useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import MUIExitToAppIcon from '@material-ui/icons/ExitToApp';
import Heading from 'raydiant-elements/core/Heading';
import Link from 'raydiant-elements/core/Link';
import Button from 'raydiant-elements/core/Button';
import Row from 'raydiant-elements/layout/Row';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Text from 'raydiant-elements/core/Text';
import TextField from 'raydiant-elements/core/TextField';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import SelectField from 'raydiant-elements/core/SelectField';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Column from 'raydiant-elements/layout/Column';
import PaperModal from '../PaperModal';
import useConnectors from '../../hooks/useConnectors';
import useLocation from '../../hooks/useLocation';
import { useLocationModalFormContext } from '../../hooks/useLocationModalForm';
import { Location } from '../../clients/raydiantMenu';

const learnMoreLink = '/docs/on-brand-menu-api/toast#create-a-toast-source';

interface LocationModalProps {
  open: boolean;
  onClose: () => void;
  location?: Location;
}

const initialState = {
  name: '',
  nameError: '',
  connectorId: '',
  connectorIdError: '',
  notes: '',
  locationId: '',
};

const LocationModalForm: FC<LocationModalProps> = (props) => {
  const { open, onClose } = props;
  // State

  // NOTE: Modal state is reset onClose. When adding additional state values
  // be sure to update the effect that resets state too.
  const [name, setName] = useState(initialState.name);
  const [nameError, setNameError] = useState(initialState.nameError);
  const [connectorId, setConnectorId] = useState(initialState.connectorId);
  const [connectorIdError, setConnectorIdError] = useState(
    initialState.connectorIdError,
  );
  const [notes, setNotes] = useState(initialState.notes);
  const [locationId, setLocationId] = useState(initialState.locationId);

  // Contexts

  const formContext = useLocationModalFormContext();

  // Queries

  const connectors = useConnectors();
  const location = useLocation(locationId);

  // Refs

  const prevOpenRef = useRef<boolean | null>();
  const nameRef = useRef<HTMLInputElement | null>(null);
  const prevLocation = useRef<Location | null>(null);

  // Callbacks

  const handleSubmit = async () => {
    if (!validate()) return;
    // Location Ids are created after the user populates both the name and connector fields
    // Prevent user from submitting a form without a valid locationId
    if (!locationId) return;

    await formContext.updateLocation({
      id: locationId,
      connectorId,
      sourceName: name,
      sourceDescription: notes,
    });
    onClose();
  };

  const resetValidation = () => {
    setNameError(initialState.nameError);
    setConnectorIdError(initialState.connectorIdError);
  };

  const handleNameChange = (value: string) => {
    resetValidation();
    setName(value);
  };

  const handleConnectorIdChange = async (value: string) => {
    resetValidation();
    setConnectorId(value);

    // Create location after the name and connector inputs are populated.
    // Locations are created before the form is submitted to
    // show the locationId in the modal.

    // Only create a location if a locationId does not exist.
    if (locationId) return;

    const data = await formContext.createLocation({
      connectorId: value,
      sourceName: name,
      sourceDescription: notes,
    });
    if (!data) return;

    setLocationId(data.id);
  };

  // const handleDeleteLocation = async () => {
  //   if (!locationId) return;

  //   await locationModalFormContext.deleteLocation(locationId);
  //   onClose();
  // };

  const validate = useCallback(() => {
    resetValidation();
    let isValid = true;

    if (!name) {
      isValid = false;
      setNameError('invalid');
    }

    if (!connectorId) {
      isValid = false;
      setConnectorIdError('invalid');
    }

    return isValid;
  }, [name, connectorId]);

  const resetState = () => {
    setName(initialState.name);
    setNameError(initialState.nameError);
    setConnectorId(initialState.connectorId);
    setConnectorIdError(initialState.connectorIdError);
    setNotes(initialState.notes);
    setLocationId(initialState.locationId);
    prevLocation.current = null;
  };

  // Effects

  // Reset state when modal is opened
  useEffect(() => {
    if (!prevOpenRef.current && open) {
      if (nameRef.current) {
        nameRef.current.focus();
      }

      resetState();
    }
    prevOpenRef.current = open;
  }, [open]);

  // Make sure pre-selected location and the one stored in state are in sync
  useEffect(() => {
    if (prevLocation.current !== props.location) {
      if (!props.location) {
        resetState();

        if (nameRef.current) {
          nameRef.current.focus();
        }

        return;
      }

      setName(props.location.sourceName);
      setConnectorId(props.location.connectorId);
      setNotes(props.location.sourceDescription ?? initialState.notes);
      setLocationId(props.location.id);
    }

    prevLocation.current = props.location || null;
  }, [props.location]);

  // Render

  const locationCreated = !!location?.data;

  // Disable connector input until a valid name has been inputted
  const shouldDisableConnectorInput = !name;
  const shouldDisableSave = formContext.shouldDisableSave;
  const shouldDisableNotesInput = !locationCreated;
  // const shouldDisableDeleteButton =
  //   !locationCreated ||
  //   locationModalFormContext.deleteLocationStatus === 'loading';

  if (connectors.isLoading) {
    return null;
  }

  return (
    <PaperModal
      open={open}
      onClose={onClose}
      title="API Source"
      titleIcon={<MUIExitToAppIcon />}
    >
      <PaperModal.Body>
        <Heading>
          <Text
            ref={nameRef}
            autoFocus
            editable={true}
            value={name}
            onChange={handleNameChange}
            error={!!nameError}
          />
        </Heading>
        {nameError === 'invalid' && (
          <InputHelperText error>Oops! Please provide a name.</InputHelperText>
        )}
        <InputHelperText>*Required</InputHelperText>
      </PaperModal.Body>

      <Scrollable>
        <PaperModal.Body>
          <Column doubleMargin>
            <div>
              <SelectField
                disabled={shouldDisableConnectorInput}
                label="Source"
                onChange={handleConnectorIdChange}
                value={connectorId || 'placeholder'}
                error={!!connectorIdError}
              >
                <option disabled value="placeholder">
                  Select source
                </option>
                {connectors.data?.map((c) => (
                  <option key={c.id} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </SelectField>
              {connectorIdError === 'invalid' &&
                !shouldDisableConnectorInput && (
                  <InputHelperText error>
                    Oops! Please provide a source.
                  </InputHelperText>
                )}
            </div>

            <div>
              <Heading overline size={5}>
                Location ID
              </Heading>
              {location?.data && (
                <>
                  <Text>{location.data.id}</Text>
                  {/* TODO: Add documentation for other connectors */}
                  <InputHelperText>
                    Copy to Toast{'>'}Raydiant integration.{' '}
                    <Link href={learnMoreLink} target="_blank">
                      Learn more
                    </Link>
                  </InputHelperText>
                </>
              )}
            </div>

            <TextField
              disabled={shouldDisableNotesInput}
              label="Notes"
              multiline
              placeholder="“This is for Store X”, or “Use this for API Key Y”"
              onChange={setNotes}
              value={notes}
              helperText="Optional. Can help describe Source"
            />
            {/* TODO: Uncomment for V2 release */}
            {/* <div className={classes.buttons}>
              <Button
                fullWidth
                color="destructive"
                disabled={shouldDisableDeleteButton}
                // For the moment, sources = locations
                onClick={handleDeleteLocation}
              >
                Delete API Source
              </Button>
              <InputHelperText>
                Will permanently remove this Source.
              </InputHelperText>
            </div> */}
          </Column>
        </PaperModal.Body>

        <PaperModal.Footer>
          <Row>
            <ActionBar.Action
              color="primaryText"
              icon={<CloseIcon />}
              label="Cancel"
              onClick={onClose}
              fullWidth
            />
            <Button
              fullWidth
              color="primary"
              onClick={handleSubmit}
              disabled={shouldDisableSave}
            >
              Done
            </Button>
          </Row>
        </PaperModal.Footer>
      </Scrollable>
    </PaperModal>
  );
};

export default LocationModalForm;
