import { FC } from 'react';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import { useParams, Redirect } from 'react-router';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Center from 'raydiant-elements/layout/Center';
import * as routes from '../routes';
import Page from '../components/Page';
import ApplicationForm from '../components/ApplicationForm';
import useApplicationForm, {
  ApplicationFormProvider,
} from '../hooks/useApplicationForm';
import useEditableApplicationByName from '../hooks/useEditableApplicationByName';
import useApplicationVersions from '../hooks/useApplicationVersions';
import useRoles from '../hooks/useRoles';

interface EditApplicationPageProps {}

const EditApplicationPage: FC<EditApplicationPageProps> = () => {
  const params = useParams<{ applicationName: string }>();

  // Queries

  const { status: appStatus, data: application } = useEditableApplicationByName(
    params.applicationName,
  );

  const { status: versionsStatus, data: versions } = useApplicationVersions(
    application?.id,
  );

  const { isLoading: isLoadingRoles, data: roles } = useRoles();

  const isLoading = versionsStatus === 'loading' || appStatus === 'loading';
  const didError = versionsStatus === 'error' || appStatus === 'error';

  // Form

  const form = useApplicationForm(false, application, versions);

  // Render

  if (isLoading) {
    return (
      <Page>
        <Center>
          <CircularProgress size={30} />
        </Center>
      </Page>
    );
  }

  if (didError) {
    // TODO: Better error handling.
    return (
      <Page>
        <Center>Oops! Something went wrong.</Center>
      </Page>
    );
  }

  const hasDeveloperRole = roles?.includes('developer');
  if (!isLoadingRoles && !hasDeveloperRole) {
    return <Redirect to={routes.notAllowed()} />;
  }

  return (
    <Page>
      <Scrollable>
        <ApplicationFormProvider value={form}>
          <ApplicationForm />
        </ApplicationFormProvider>
      </Scrollable>
    </Page>
  );
};

export default EditApplicationPage;
