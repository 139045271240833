import { FC } from 'react';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Switch from 'raydiant-elements/core/Switch';
import Text from 'raydiant-elements/core/Text';
import Row from 'raydiant-elements/layout/Row';
import {
  setPropertyRequired,
  isPropertyRequired,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationInputRequiredProps {}

const ApplicationInputRequired: FC<ApplicationInputRequiredProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  // Callbacks

  const handleIsRequiredChange = (isRequired: boolean) => {
    if (!selectedProperty) return;
    if (!version) return;
    updateSelectedProperty(
      setPropertyRequired(selectedProperty, isRequired),
      version.strings,
    );
  };

  // Render

  const helperText =
    !!selectedProperty && isPropertyRequired(selectedProperty)
      ? 'This input is required'
      : 'This input is optional';

  const isRequiredProperty =
    !!selectedProperty && isPropertyRequired(selectedProperty);

  return (
    <>
      {isEditable && (
        <Switch
          label="Required"
          checked={isRequiredProperty}
          helperText={helperText}
          onChange={handleIsRequiredChange}
        />
      )}

      {!isEditable && (
        <div>
          <InputLabel>Required</InputLabel>
          <Row halfMargin center>
            {isRequiredProperty ? <CheckSharpIcon /> : <CloseSharpIcon />}
            <Text small>{helperText}</Text>
          </Row>
        </div>
      )}
    </>
  );
};

export default ApplicationInputRequired;
