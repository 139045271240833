import { ApiClientError } from '@raydiant/api-client-js';
import raydiantMenu, { Connector } from '../clients/raydiantMenu';
import useProtectedQuery from './useProtectedQuery';

export default function useConnectors() {
  const query = useProtectedQuery<Connector[], ApiClientError>(
    'apiConnectors',
    () => raydiantMenu.getConnectors(),
  );

  return query;
}
