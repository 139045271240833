import { ApiClientError } from '@raydiant/api-client-js';
import raydiantMenu, { ApiKey } from '../clients/raydiantMenu';
import useProtectedQuery from './useProtectedQuery';

export default function useApiKey() {
  const query = useProtectedQuery<ApiKey[], ApiClientError>(
    'apiKeys',
    async () => {
      const data = await raydiantMenu.getApiKeys();

      data.sort((a, b) => {
        if (a.name === null) {
          return 1;
        }

        if (b.name === null) {
          return -1;
        }

        return a.name.localeCompare(b.name);
      });

      return data;
    },
  );

  return query;
}
