import { FC } from 'react';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

interface SectionHeader {}

const Section: FC<SectionHeader> = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      padding: theme.spacing(1, 4),
      marginBottom: theme.spacing(3),
      borderBottom: `1px solid ${theme.divider.primary}`,

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  }),
);

export default Section;
