import { useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import Button from 'raydiant-elements/core/Button';
import Menu from 'raydiant-elements/core/Menu';
import Hidden from 'raydiant-elements/layout/Hidden';
import Spacer from 'raydiant-elements/layout/Spacer';
import * as routes from '../routes';
import NavLink from './NavLink';
import auth0 from '../clients/auth0';
import config from '../config';
import useRoles from '../hooks/useRoles';

const docsLink = '/docs';

interface NavigationProps {
  title?: string;
}

const Navigation = (props: NavigationProps) => {
  const history = useHistory();
  const { isLoading: isLoadingRoles, data: roles } = useRoles();

  // State

  const [open, setOpen] = useState(false);

  // Callbacks

  const handleLogout = useCallback(() => {
    auth0.logout();
  }, []);

  const hasApiKeysRole = roles?.includes('developer_api_keys');

  const renderDesktopMenu = () => {
    return (
      <Menu>
        <Menu.Header>
          <Menu.Logo
            width={128}
            href={routes.home()}
            onClick={() => history.push(routes.home())}
          />
          <div style={{ flex: 1, maxWidth: 120 }} />
          <NavLink
            to={routes.applications()}
            path={routes.applications.pattern}
          >
            {(navLinkProps) => <Menu.Item {...navLinkProps} label="Apps" />}
          </NavLink>
          {hasApiKeysRole && (
            <NavLink to={routes.apiKeys()} path={routes.apiKeys.pattern}>
              {(navLinkProps) => (
                <Menu.Item {...navLinkProps} label="API Keys" />
              )}
            </NavLink>
          )}
          <Menu.Item
            href={config.raydiantDashAccountsPageUrl}
            target="_blank"
            label="Account"
          />
          <Spacer />

          <Menu.Item href={docsLink} label="Documentation" />
        </Menu.Header>
      </Menu>
    );
  };

  const renderMobileMenu = () => {
    const { title } = props;

    return (
      <Menu>
        <Menu.Header>
          <Menu.Logo
            square
            href={routes.home()}
            onClick={() => history.push(routes.home())}
          />

          <Spacer />
          {title && <Menu.Title label={title} />}
          <div style={{ marginRight: -16 }}>
            <Button icon="menu" hideBorder onClick={() => setOpen(!open)} />
          </div>
        </Menu.Header>
        <Menu.Drawer open={open}>
          <NavLink
            to={routes.applications()}
            path={routes.applications.pattern}
          >
            {(navLinkProps) => (
              <Menu.DrawerItem {...navLinkProps} label="Apps" />
            )}
          </NavLink>
          {hasApiKeysRole && (
            <NavLink to={routes.apiKeys()} path={routes.apiKeys.pattern}>
              {(navLinkProps) => (
                <Menu.DrawerItem {...navLinkProps} label="API Keys" />
              )}
            </NavLink>
          )}
          <Menu.DrawerItem
            href={config.raydiantDashAccountsPageUrl}
            target="_blank"
            label="Account"
          />
          <Menu.DrawerItem onClick={handleLogout} label="Sign out" />
          <Menu.DrawerItem href={docsLink} label="Documentation" />
        </Menu.Drawer>
      </Menu>
    );
  };

  // Render
  if (isLoadingRoles) {
    return null;
  }

  return (
    <>
      <Hidden xsDown>{renderDesktopMenu()}</Hidden>
      <Hidden smUp>{renderMobileMenu()}</Hidden>
    </>
  );
};

export default Navigation;
