import { ApiClientError, ResourceACL } from '@raydiant/api-client-js';
import raydiant from '../clients/raydiant';
import useProtectedQuery from './useProtectedQuery';

export default function useResourceACLs(resourceId: string) {
  const query = useProtectedQuery<ResourceACL[], ApiClientError>(
    'resourceACLs',
    () => raydiant.listResourceACLs(resourceId),
  );

  return query;
}
