import { ContextValue } from './types';
import { useQueryClient } from 'react-query';
import raydiantMenu, {
  ApiKey,
  CreateApiKeyRequest,
  DeleteApiKeyRequest,
  SaveApiKeyRequest,
  UpdateApiKeyRequest,
} from '../../clients/raydiantMenu';
import useProtectedMutation from '../useProtectedMutation';

export default function useApiKeyModalForm(): ContextValue {
  const queryClient = useQueryClient();

  const createApiKeyFn = async (params: CreateApiKeyRequest) => {
    return await raydiantMenu.createApiKey(params);
  };

  const updateApiKeyFn = async (params: UpdateApiKeyRequest) => {
    return await raydiantMenu.updateApiKey(params);
  };

  const deleteApiKeyFn = async (params: DeleteApiKeyRequest) => {
    return await raydiantMenu.deleteApiKey(params);
  };

  const onSaveSuccess = () => {
    queryClient.invalidateQueries('apiKeys');
    queryClient.invalidateQueries('apiKeyMenus');
  };

  const {
    mutateAsync: createApiKeyMutation,
    status: createApiKeyStatus,
  } = useProtectedMutation(createApiKeyFn, {
    onSuccess: onSaveSuccess,
  });

  const {
    mutateAsync: updateApiKeyMutation,
    status: updateApiKeyStatus,
  } = useProtectedMutation(updateApiKeyFn, {
    onSuccess: onSaveSuccess,
  });

  const {
    mutateAsync: deleteApiKeyMutation,
    status: deleteApiKeyStatus,
  } = useProtectedMutation(deleteApiKeyFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('apiKeys');
    },
  });

  const saveApiKey = async (params: SaveApiKeyRequest) => {
    let data: ApiKey | null = null;
    try {
      if (params.id) {
        data = await updateApiKeyMutation(params);
      } else {
        data = await createApiKeyMutation(params);
      }
    } catch (err) {
      console.error(err);
    }
    return data;
  };

  const deleteApiKey = async (params: DeleteApiKeyRequest) => {
    try {
      await deleteApiKeyMutation(params);
    } catch (err) {
      console.error(err);
    }
  };

  const isLoading = [
    createApiKeyStatus,
    updateApiKeyStatus,
    deleteApiKeyStatus,
  ].includes('loading');

  return {
    saveApiKey,
    isLoading,
    deleteApiKey,
  };
}
