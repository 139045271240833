import { FC } from 'react';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Switch from 'raydiant-elements/core/Switch';
import Text from 'raydiant-elements/core/Text';
import Row from 'raydiant-elements/layout/Row';
import {
  isPropertyMultilineText,
  setPropertyMultilineText,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationInputMultilineProps {}

const ApplicationInputMultiline: FC<ApplicationInputMultilineProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  // Callbacks

  const handleIsMultilineChange = (isMultiline: boolean) => {
    if (!selectedProperty) return;
    if (!version) return;
    updateSelectedProperty(
      setPropertyMultilineText(selectedProperty, isMultiline),
      version.strings,
    );
  };

  // Render

  return (
    <>
      {isEditable && selectedProperty && (
        <Switch
          label="Multiline"
          checked={isPropertyMultilineText(selectedProperty)}
          helperText="Allow multiple lines of text"
          onChange={handleIsMultilineChange}
        />
      )}

      {!isEditable &&
        selectedProperty &&
        isPropertyMultilineText(selectedProperty) && (
          <div>
            <InputLabel>Multiline</InputLabel>
            <Row halfMargin center>
              <CheckSharpIcon />
              <Text small>Allow multiple lines of text</Text>
            </Row>
          </div>
        )}
    </>
  );
};

export default ApplicationInputMultiline;
