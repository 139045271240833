import { ApiClientError } from '@raydiant/api-client-js';
import raydiantMenu, { GetLocationsResponse } from '../clients/raydiantMenu';
import useProtectedQuery from './useProtectedQuery';

const intervalMs = 10000;
export default function useLocations() {
  const query = useProtectedQuery<GetLocationsResponse, ApiClientError>(
    'locations',
    async () => {
      const data = await raydiantMenu.getLocations();

      data.sort((a, b) => {
        if (a.sourceName === null) {
          return 1;
        }

        if (b.sourceName === null) {
          return -1;
        }

        if (
          (a.connectorLocationId && b.connectorLocationId) ||
          (!a.connectorLocationId && !b.connectorLocationId)
        ) {
          return a.sourceName.localeCompare(b.sourceName);
        }

        return !a.connectorLocationId && b.connectorLocationId ? 1 : -1;
      });
      return data;
    },
    {
      refetchInterval: intervalMs,
    },
  );

  return query;
}
