import config from '../config';
import getAccessToken from '../utilities/getAccessToken';

export type ApiClientFetcher = (
  input: RequestInfo,
  init?: RequestInit | undefined,
) => Promise<Response>;

export interface MenuApiClientOptions {
  /** The base url for the api client. */
  baseUrl: string;
  /** The (optional) access token to use when making requests. */
  accessToken?: string;
  /** The (optional) access token getter. */
  getAccessToken: () => Promise<string>;
  /** A function to handle the underlying fetch request */
  fetcher: ApiClientFetcher;
}

export type MethodTypes = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export interface RequestOptions<RequestBody> {
  method: MethodTypes;
  url: string;
  body?: RequestBody;
}

export declare type ApiKey = {
  description: string | null;
  id: string;
  name: string;
  token: string;
  menus: string[];
};
export type GetApiKeysRequest = undefined;
export type GetApiKeysResponse = ApiKey[];
export type CreateApiKeyRequest = Partial<ApiKey>;
export type CreateApiKeyResponse = ApiKey;
export type UpdateApiKeyRequest = Partial<ApiKey>;
export type UpdateApiKeyResponse = ApiKey;
export type SaveApiKeyRequest = UpdateApiKeyRequest | CreateApiKeyRequest;
export type SaveApiKeyResponse = ApiKey;
export type DeleteApiKeyRequest = { id: string };
export type DeleteApiKeyResponse = void;

export declare type Location = {
  id: string;
  connectorId: string;
  connectorLocationId: string;
  sourceName: string;
  sourceDescription: string | null;
  name: string;
  restaurantName: string;
  totalMenus: number;
};
export type GetLocationsRequest = undefined;
export type GetLocationsResponse = Location[];
export type CreateLocationRequest = Partial<Location>;
export type CreateLocationResponse = Location;
export type UpdateLocationRequest = Partial<Location>;
export type UpdateLocationResponse = Location;

export declare type Connector = {
  id: string;
  name: string;
};
export type GetConnectorsRequest = undefined;
export type GetConnectorsResponse = Connector[];

export declare type Menu = {
  id: string;
  name: string;
};

export declare type ApiKeyMenu = Menu & {
  locationId: string;
};
export type GetLocationMenusRequest = { id: string };
export type GetLocationMenusResponse = Menu[];
export type GetApiKeyMenusRequest = { id: string };
export type GetApiKeyMenusResponse = ApiKeyMenu[];

export class MenuClient {
  protected baseUrl: string;
  protected accessToken?: string;
  protected getAccessToken: () => Promise<string>;
  protected fetcher: ApiClientFetcher;

  constructor(opts: MenuApiClientOptions) {
    this.baseUrl = opts.baseUrl;
    this.accessToken = opts.accessToken;
    this.getAccessToken = opts.getAccessToken;
    this.fetcher = opts.fetcher;
  }

  async request<RequestBody, ResponseBody>(
    opts: RequestOptions<RequestBody>,
  ): Promise<ResponseBody> {
    const token = await this.getAccessToken();
    const res = await fetch(`${this.baseUrl}${opts.url}`, {
      method: opts.method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...(opts.body ? { body: JSON.stringify(opts.body) } : {}),
    });

    if (res.headers.get('Content-Type')?.includes('application/json')) {
      return await res.json();
    }

    return (await res.text()) as any;
  }

  async getLocations() {
    return await this.request<GetLocationsRequest, GetLocationsResponse>({
      method: 'GET',
      url: '/v1/locations',
    });
  }

  async getApiKeys() {
    return await this.request<GetApiKeysRequest, GetApiKeysResponse>({
      method: 'GET',
      url: '/v1/keys',
    });
  }

  async getConnectors() {
    return await this.request<GetConnectorsRequest, GetConnectorsResponse>({
      method: 'GET',
      url: '/v1/connectors',
    });
  }

  async createLocation(params: CreateLocationRequest) {
    return await this.request<CreateLocationRequest, CreateLocationResponse>({
      method: 'POST',
      url: '/v1/locations',
      body: params,
    });
  }

  // async deleteLocation(id: string) {
  //   return await this.request<string, void>({
  //     method: 'DELETE',
  //     url: `/v1/locations/${id}`,
  //   });
  // }

  async updateLocation(params: UpdateLocationRequest) {
    const { id, ...rest } = params;
    return await this.request<UpdateLocationRequest, UpdateLocationResponse>({
      method: 'PATCH',
      url: `/v1/locations/${id}`,
      body: rest,
    });
  }

  async getLocationMenus(params: GetLocationMenusRequest) {
    const { id } = params;
    return await this.request<
      GetLocationMenusRequest,
      GetLocationMenusResponse
    >({
      method: 'GET',
      url: `/v1/locations/${id}/menus`,
    });
  }

  async getApiKeyMenus(params: GetApiKeyMenusRequest) {
    const { id } = params;
    return await this.request<GetApiKeyMenusRequest, GetApiKeyMenusResponse>({
      method: 'GET',
      url: `/v1/keys/${id}/menus`,
    });
  }

  async createApiKey(params: CreateApiKeyRequest) {
    return await this.request<CreateApiKeyRequest, CreateApiKeyResponse>({
      method: 'POST',
      url: `/v1/keys`,
      body: params,
    });
  }

  async deleteApiKey(params: DeleteApiKeyRequest) {
    return await this.request<DeleteApiKeyRequest, DeleteApiKeyResponse>({
      method: 'DELETE',
      url: `/v1/keys/${params.id}`,
    });
  }

  async updateApiKey(params: UpdateApiKeyRequest) {
    const { id, ...body } = params;
    return await this.request<UpdateApiKeyRequest, UpdateApiKeyResponse>({
      method: 'PATCH',
      url: `/v1/keys/${id}`,
      body,
    });
  }
}

const menuClient = new MenuClient({
  baseUrl: config.raydiantMenuApiUrl,
  getAccessToken,
  fetcher: window.fetch.bind(window),
});

export default menuClient;
