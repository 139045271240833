import { FC } from 'react';
import Heading from 'raydiant-elements/core/Heading';
import Link from 'raydiant-elements/core/Link';

import Center from 'raydiant-elements/layout/Center';
import Page from '../components/Page';

interface NotAllowedPageProps {}

const NotAllowedPage: FC<NotAllowedPageProps> = () => {
  return (
    <Page>
      <Center>
        <Heading size={3}>
          Please contact{' '}
          <Link href="mailto:support@raydaint.com">support@raydiant.com</Link>{' '}
          to enable the developer account.
        </Heading>
      </Center>
    </Page>
  );
};

export default NotAllowedPage;
