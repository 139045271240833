import * as React from 'react';
import { Route } from 'react-router';
import { useHistory } from 'react-router-dom';

interface NavLinkRenderProps {
  active: boolean;
  href: string;
  onClick: () => void;
}

interface NavLinkProps {
  to: string;
  exact?: boolean;
  children: (props: NavLinkRenderProps) => React.ReactNode;
  path?: string;
}

const NavLink = (props: NavLinkProps) => {
  const { to, exact, children, path, ...rest } = props;
  const history = useHistory();

  return (
    <Route
      path={path}
      exact={exact}
      children={({ match }: { match: boolean }) =>
        children({
          ...rest,
          active: match,
          href: to,
          onClick: () => history.push(to),
        })
      }
    />
  );
};

export default NavLink;
