import { ApplicationVersion } from '@raydiant/api-client-js';

const defaultVersion: ApplicationVersion = {
  id: '',
  applicationId: '',
  name: '',
  description: '',
  majorVersion: 0,
  minorVersion: 1,
  patchVersion: 0,
  iconUrl: '',
  thumbnailUrl: '',
  embeddedUrlFormat: '',
  sourceUrl: '',
  configurableDuration: true,
  dynamicThumbnails: false,
  defaultDuration: 60,
  previewOrientation: 'horizontal',
  previewResolution: 'FullHD',
  reviewStatus: 'draft',
  createdAt: '',
  updatedAt: '',
  presentationProperties: [],
  strings: { en: {} },
  helperLinkText: '',
  helperLinkUrl: '',
};

export default defaultVersion;
