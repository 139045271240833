import { FC, useState, useEffect } from 'react';
import { PresentationProperty } from '@raydiant/api-client-js';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Input from 'raydiant-elements/core/Input';
import Text from 'raydiant-elements/core/Text';
import {
  isPropertyMultilineText,
  getPropertyDefault,
  setPropertyDefault,
} from '../../utilities/properties';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationInputDefaultTextProps {}

const getDefaultValueFromProperty = (property: PresentationProperty | null) => {
  if (!property) return '';

  const defaultValue = getPropertyDefault(property);
  if (typeof defaultValue !== 'string') return '';

  return defaultValue;
};

const ApplicationInputDefaultText: FC<ApplicationInputDefaultTextProps> = () => {
  const {
    isEditable,
    version,
    selectedProperty,
    updateSelectedProperty,
  } = useApplicationFormContext();

  // State

  const [defaultValue, setDefaultValue] = useState(
    getDefaultValueFromProperty(selectedProperty),
  );

  // Callbacks

  const handleBlur = () => {
    if (!selectedProperty) return;
    if (!version) return;
    updateSelectedProperty(
      setPropertyDefault(selectedProperty, defaultValue),
      version.strings,
    );
  };

  // Effects

  // Reset default value when property changes.
  useEffect(() => {
    setDefaultValue(getDefaultValueFromProperty(selectedProperty));
  }, [selectedProperty]);

  // Render

  const isMultiline =
    !!selectedProperty && isPropertyMultilineText(selectedProperty);

  return (
    <div>
      <InputLabel>Initial Value</InputLabel>

      {isEditable && (
        <>
          <Input
            multiline={isMultiline}
            value={defaultValue}
            onChange={setDefaultValue}
            onBlur={handleBlur}
          />
          <InputHelperText>The intial value of the input</InputHelperText>
        </>
      )}

      {!isEditable && selectedProperty && (
        <Text>{getPropertyDefault(selectedProperty) || <em>None</em>}</Text>
      )}
    </div>
  );
};

export default ApplicationInputDefaultText;
