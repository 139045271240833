import { ApplicationVersion, ApiClientError } from '@raydiant/api-client-js';
import raydiant from '../clients/raydiant';
import useProtectedQuery from './useProtectedQuery';

export default function useApplicationVersions(appId?: string) {
  const query = useProtectedQuery<ApplicationVersion[], ApiClientError>(
    ['applications', appId, 'versions'],
    () => (appId ? raydiant.getApplicationVersions(appId) : []),
    {
      enabled: !!appId,
      refetchOnWindowFocus: false,
    },
  );

  return query;
}
