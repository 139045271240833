import { createStyles, makeStyles, Theme } from 'raydiant-elements/styles';
import React from 'react';
import Navigation from './Navigation';

interface PageProps {
  className?: string;
}

const Page: React.FC<PageProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Navigation />
      <main className={classes.main}>{children}</main>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: 'flex',
      height: '100%',
      paddingBottom: theme.spacing(6),
    },
  }),
);

export default Page;
