import { FC, useState, useEffect } from 'react';
import Heading from 'raydiant-elements/core/Heading';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Column from 'raydiant-elements/layout/Column';
import Text from 'raydiant-elements/core/Text';
import Input from 'raydiant-elements/core/Input';
import { useApplicationFormContext } from '../../hooks/useApplicationForm';

interface ApplicationHelperProps {}

const ApplicationHelper: FC<ApplicationHelperProps> = () => {
  const {
    isEditable,
    version,
    updateVersion,
    getHelperLinkTextError,
    getHelperLinkUrlError,
  } = useApplicationFormContext();

  const [helperLinkText, setHelperLinkText] = useState(
    version?.helperLinkText ?? '',
  );

  const [helperLinkUrl, setHelperLinkUrl] = useState(
    version?.helperLinkUrl ?? '',
  );

  const helperLinkTextError = getHelperLinkTextError();
  const helperLinkUrlError = getHelperLinkUrlError();

  // Reset state when version changes.
  useEffect(() => {
    setHelperLinkText(version?.helperLinkText ?? '');
    setHelperLinkUrl(version?.helperLinkUrl ?? '');
  }, [version]);

  return (
    <Column>
      <Heading size={5} overline>
        Helper Link
      </Heading>

      {(helperLinkTextError || helperLinkUrlError) && (
        <InputHelperText error>
          Oops! Please set both text and link values, or keep both empty.
        </InputHelperText>
      )}

      <div>
        <InputLabel>Helper Link</InputLabel>
        {isEditable && (
          <>
            <Input
              type="text"
              value={helperLinkUrl}
              error={!!helperLinkUrlError}
              onChange={setHelperLinkUrl}
              onBlur={() => updateVersion({ helperLinkUrl })}
            />
            <InputHelperText error={!!helperLinkUrlError}>
              {helperLinkUrlError === 'invalid' &&
                'Oops! Please enter a valid URL.'}
              {!helperLinkUrlError &&
                'The URL of the helper text to open additional documentation.'}
            </InputHelperText>
          </>
        )}

        {!isEditable && <Text>{helperLinkUrl || <em>None</em>}</Text>}
      </div>

      <div>
        <InputLabel>Helper Text</InputLabel>
        {isEditable && (
          <>
            <Input
              type="text"
              value={helperLinkText}
              error={!!helperLinkTextError}
              onChange={setHelperLinkText}
              onBlur={() => updateVersion({ helperLinkText })}
            />
            <InputHelperText error={!!helperLinkTextError}>
              {helperLinkTextError === 'invalid' &&
                'Oops! Please enter a valid link description.'}
              {!helperLinkTextError &&
                'Helpful text to explain the app behavior to the operator.'}
            </InputHelperText>
          </>
        )}

        {!isEditable && <Text>{helperLinkText || <em>None</em>}</Text>}
      </div>
    </Column>
  );
};

export default ApplicationHelper;
