import { FC } from 'react';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

interface MarketplaceIndicatorProps {
  label?: string;
}

const MarketplaceIndicator: FC<MarketplaceIndicatorProps> = ({
  label = 'Live',
}) => {
  const classes = useStyles();
  return (
    <div className={classes.live}>
      <div className={classes.liveIndicator} /> {label}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    live: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.fontSizes.sm,
      fontWeight: 500,
    },

    liveIndicator: {
      width: theme.spacing(0.5),
      height: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      backgroundColor: theme.palette.success.main,
      borderRadius: 100,
    },
  }),
);

export default MarketplaceIndicator;
